export const WHITELIST = ['cnclogin://'];

/**
 * @param {String} str StringUrl
 * @returns {Boolean}
 */
export default (str) => {
  if (typeof str !== 'string') return false;
  const isWhiteListed = WHITELIST.some((item) => str.includes(item));
  return isWhiteListed || /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/.test(str);
};
