<template>
  <FormulateInput
    type="group"
    name="phone"
    validation="phone"
    :validation-rules="{ phone: phoneRule }"
    :validation-messages="{ phone: phoneMessage }"
    error-behavior="submit"
  >
    <FormulateInput
      label="Telefon"
      type="text"
      name="code"
      :value="phone.code"
      :disabled="disabled"
    />
    <FormulateInput
      label="Telefon"
      type="text"
      name="number"
      :value="phone.number"
      :help="help"
      :disabled="disabled"
      ><slot></slot
    ></FormulateInput>
  </FormulateInput>
</template>

<script>
import { validationRules } from '@/utils';

export default {
  name: 'PhoneField',

  props: {
    value: {
      type: String,
    },
    help: {
      type: [String, Boolean],
    },
    disabled: {
      type: Boolean,
    },
  },

  computed: {
    phone() {
      const splitedNumber = this.value.split(' ');

      if (splitedNumber.length < 2) {
        return {
          code: '',
          number: splitedNumber[0],
        };
      }

      return {
        code: splitedNumber[0],
        number: splitedNumber[1],
      };
    },
  },

  methods: {
    phoneRule({ value }) {
      if (Array.isArray(value)) {
        const [phoneNumber] = value;
        const { code, number } = phoneNumber;

        return (
          validationRules.rules.phoneCode({ value: code }) &&
          validationRules.rules.phoneNumber({ value: number })
        );
      }
      return false;
    },

    phoneMessage({ value }) {
      if (Array.isArray(value)) {
        const [phoneNumber] = value;
        const { code, number } = phoneNumber;

        if (!validationRules.rules.phoneCode({ value: code })) {
          return 'Předvolba musí být ve formátu +XXX';
        }

        if (!validationRules.rules.phoneNumber({ value: number })) {
          return 'Telefon není ve správném formátu';
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

/deep/ .formulate-input-element--group {
  .formulate-input-group-repeatable {
    > div:first-child {
      width: rem(70);
      flex: none;
      margin-right: rem(25);
    }

    > div:last-child {
      flex: 1;
      max-width: none;

      .formulate-input-label {
        visibility: hidden;
      }
    }
  }
}
</style>
