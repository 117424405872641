<template>
  <FormulateInput
    label="Pohlaví"
    type="select"
    name="sex"
    :value="value"
    :options="{ M: 'Mužské', F: 'Ženské' }"
    :help="help"
    error-behavior="submit"
    :disabled="disabled"
  />
</template>

<script>
export default {
  name: 'SexField',

  props: {
    value: {
      type: String,
    },
    help: {
      type: [String, Boolean],
    },
    disabled: {
      type: Boolean,
    },
  },
};
</script>
