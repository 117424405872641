import { render, staticRenderFns } from "./DefaultLayout.vue?vue&type=template&id=7812c66e&"
var script = {}


/* normalize component */
import normalizer from "!../../.yarn/__virtual__/vue-loader-virtual-2be745a210/7/opt/data/cache/account-service/yarn_cache/vue-loader-npm-15.10.1-3a0b6c19d2-753a6b6da2.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports