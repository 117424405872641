<template>
  <div class="label-info">
    <label :for="id">
      {{ label }}
    </label>
    <div class="label-info__icon">
      <img :src="require('@/assets/svg/label-help.svg')" alt="Ikona info" /><span>{{ info }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LabelInfo',

  props: {
    id: {
      type: String,
    },

    label: {
      type: String,
    },

    info: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';
.label-info {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;

  label {
    flex: none !important;
  }

  &__icon {
    position: relative;
    display: inline-block;
    width: rem(18);
    height: rem(18);
    margin-left: rem(8);

    span {
      display: none;
      position: absolute;
      top: rem(-5);
      right: 0;
      min-width: rem(160);
      max-width: rem(250);
      padding: rem(8);
      font-size: rem(13);
      background-color: $background-color-gray;
      border: rem(1) solid $border-primary;
      transform: translateY(-100%);
      box-shadow: $box-shadow;
    }

    &:hover {
      span {
        display: block;
      }
    }
  }
}
</style>
