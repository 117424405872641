<template>
  <FormulateForm v-model="formValues" @submit="onSubmit" :form-errors="formErrors">
    <FormulateInput
      label="E-mail"
      type="email"
      name="email"
      validation="^required|email"
      :validation-messages="{
        required: 'Zadejte prosím e-mail',
        email: 'Vámi zadaný e-mail nemá správný formát',
      }"
      error-behavior="submit"
    />

    <FormulateInput type="submit" label="Odeslat" :disabled="isSubmiting"
      ><Spinner v-if="isSubmiting" :size="24" color="#ffffff"
    /></FormulateInput>
  </FormulateForm>
</template>

<script>
import { API } from '@/api';
import BaseFormMixin from '@/mixins/BaseFormMixin';

export default {
  name: 'ForgottenPasswordFrom',

  mixins: [BaseFormMixin],

  data() {
    return {
      formValues: {},
    };
  },

  methods: {
    onSubmit() {
      const { email } = this.formValues;
      this.handleSubmit(API.auth.resetPasswordEmail, {
        email,
        serviceId: this.serviceId,
        redirectUrl: this.redirectUrl,
      });
    },
  },
};
</script>
