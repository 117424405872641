<template>
  <div @click="onLogin">
    <svg
      v-if="!isSubmiting"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M19.55 14.56a.5.5 0 01-.5.44H17a.5.5 0 00-.5.5v8a.5.5 0 00.5.5h6a1 1 0 001-1V1a1 1 0 00-1-1H1a1 1 0 00-1 1v22a1 1 0 001 1h11a.5.5 0 00.5-.5v-8a.5.5 0 00-.5-.5h-2a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h2a.5.5 0 00.5-.5V9.19a5.69 5.69 0 015.69-5.69h1.31a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-1.31a1.69 1.69 0 00-1.69 1.69v1.31a.5.5 0 00.5.5h2.43a.5.5 0 01.5.56z"
      />
    </svg>
    <Spinner v-if="isSubmiting" :size="24" />
  </div>
</template>

<script>
import BaseFormMixin from '@/mixins/BaseFormMixin';
import { AUTH_LOGIN_TOKEN_REQUEST } from '@/store/actions/auth';
import { FACEBOOK_APP_ID, LOGIN_TYPES } from '@/config';

const SCRIPT_URL = 'https://connect.facebook.net/cs_CZ/sdk.js';

export default {
  name: 'SocialConnectFacebook',

  mixins: [BaseFormMixin],

  async mounted() {
    if (!window.FB) this.initScript();
  },

  methods: {
    initScript() {
      return new Promise((resolve) => {
        window.fbAsyncInit = () => {
          window.FB.init({
            appId: FACEBOOK_APP_ID,
            version: 'v12.0',
            status: true,
            cookie: true,
          });
          resolve();
        };

        const script = document.createElement('script');
        script.id = 'facebook-jssdk';
        script.src = SCRIPT_URL;
        document.head.appendChild(script);
      });
    },

    onLogin() {
      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push(function () {
        window.gtmService.push({
          event: 'user_login_attempt',
        });
      });

      this.isSubmiting = true;
      window.FB.login(this.loginHandler, { scope: 'public_profile,email' });
    },

    loginHandler({ authResponse }) {
      try {
        if (authResponse) {
          window.FB.api('/me', { fields: 'email' }, async ({ email }) => {
            if (email) {
              this.handleSubmit(AUTH_LOGIN_TOKEN_REQUEST, {
                type: LOGIN_TYPES.facebook,
                token: authResponse.accessToken,
                serviceId: this.serviceId,
              });
            } else {
              this.isSubmiting = false;
              this.$emit('connectError', 'Propojení se nezdařilo. Zkuste to prosím znovu.');
            }
          });
        } else {
          this.isSubmiting = false;
          this.$emit('connectError', 'Propojení se nezdařilo. Zkuste to prosím znovu.');
        }
      } catch {
        this.isSubmiting = false;
        this.$emit('connectError', 'Propojení se nezdařilo. Zkuste to prosím znovu.');
      }
    },
  },
};
</script>
