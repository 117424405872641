import { isEmpty } from '@/helpers';

const phoneHandler = ({ code, number }) => {
  return isEmpty(number) ? '' : `${isEmpty(code) ? '+420' : code.trim()} ${number.trim()}`;
};

export default (values) => {
  const formValues = { ...values };

  for (const value in formValues) {
    if (value === 'phone' && Array.isArray(formValues[value])) {
      formValues[value] = phoneHandler(formValues[value][0]);
    }

    if (typeof formValues[value] === 'string') {
      const val = formValues[value].trim();
      formValues[value] = val === '' ? null : val;
    }
  }

  return formValues;
};
