var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormulateForm',{attrs:{"form-errors":_vm.formErrors,"novalidate":""},on:{"submit":_vm.onSubmit},model:{value:(_vm.formValues),callback:function ($$v) {_vm.formValues=$$v},expression:"formValues"}},[_c('FormulateErrors'),_c('FormulateInput',{attrs:{"label":"E-mail","type":"email","name":"email","validation":"^required|email","validation-messages":{
      required: 'Zadejte prosím email',
      email: 'Vámi zadaný e-mail nemá správný formát',
    },"error-behavior":"submit"}}),_c('FormulateInput',{attrs:{"label":"Heslo","type":_vm.isPassword ? 'password' : 'text',"name":"password","validation":"^required|min:8","validation-messages":{
      required: 'Zadejte prosím heslo',
      min: 'Heslo musí obsahovat minimálně 8 znaků',
    },"error-behavior":"submit"},scopedSlots:_vm._u([{key:"label",fn:function({ label, id }){return [_c('label',{attrs:{"for":id}},[_vm._v(" "+_vm._s(label)+" "),_c('span',{class:['password-icon', { isvisible: !_vm.isPassword }],on:{"click":function($event){_vm.isPassword = !_vm.isPassword}}})])]}}])}),_c('div',{staticClass:"page-form__conditions"},[_c('FormulateInput',{attrs:{"name":"approvalTerms","type":"checkbox","validation":"accepted","validation-messages":{
        accepted: 'Potvrzení souhlasu s podmínkami je povinné',
      },"error-behavior":"submit"},scopedSlots:_vm._u([{key:"label",fn:function({ id }){return [_c('label',{attrs:{"for":id}},[_c('span',{staticClass:"required"},[_vm._v("*")]),_vm._v(" Souhlasím s: "),_c('a',{attrs:{"href":"https://www.cncenter.cz/podminky-uzivani","target":"_blank"}},[_vm._v("Podmínkami pro užívání služby informační společnosti")]),_vm._v(" a seznámil/a jsem se "),_c('a',{attrs:{"href":"https://www.cncenter.cz/informace-o-zpracovani-obecna","target":"_blank"}},[_vm._v("Informací o zpracování osobních údajů")])])]}}])}),_c('FormulateInput',{attrs:{"name":"approvalCommercial","type":"checkbox","error-behavior":"submit"},scopedSlots:_vm._u([{key:"label",fn:function({ id }){return [_c('label',{attrs:{"for":id}},[_vm._v(" Souhlasím se: "),_c('a',{attrs:{"href":"https://www.cncenter.cz/souhlas-se-zasilanim-obchodnich-sdeleni","target":"_blank"}},[_vm._v("Zpracováním osobních údajů pro zasílání obchodních sdělení.")])])]}}])})],1),_c('FormulateInput',{attrs:{"type":"submit","label":"Registrovat se","disabled":_vm.isSubmiting}},[(_vm.isSubmiting)?_c('Spinner',{attrs:{"size":24,"color":"#ffffff"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }