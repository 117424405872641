<template>
  <div>
    <PageWrapperCover
      v-if="!success && !requiredConditions"
      :header="false"
      :footer="true"
      class="page-form--registration"
    >
      <h1 class="page-form__title">Registrace</h1>
      <div class="page-form__social-connect">
        <SocialConnect
          @submitSuccess="onSocialSubmitSuccess"
          @confirmationConditions="onConfirmationConditions"
        />
      </div>
      <div class="page-form__divider">Nebo se registrujte e-mailem</div>
      <RegistrationForm @submitSuccess="onSubmitSuccess" />
      <div class="page-form__incentive-link">
        <span>Už máte účet? <router-link :to="{ name: 'Login' }">Přihlaste se</router-link></span>
      </div>
      <div class="page-form__trouble-link">
        <span
          >Pokud máte problémy s přihlášením napište na
          <a href="mailto:podpora@cncenter.cz">podpora@cncenter.cz</a></span
        >
      </div>
    </PageWrapperCover>

    <PageWrapperCover
      v-else-if="requiredConditions"
      :header="false"
      :footer="true"
      class="page-form--login-terms"
    >
      <div class="page-form__icon">
        <img :src="require('@/assets/svg/success.svg')" alt="Úspěch" />
      </div>
      <h1 class="page-form__title">Téměř hotovo</h1>
      <div class="page-form__description">
        Nyní už stačí jen potvrdit níže uvedené souhlasy.
      </div>
      <ConditionsForm :requestInfo="requiredConditions" @submitSuccess="onSocialSubmitSuccess" />
    </PageWrapperCover>

    <PageWrapperCover v-else :header="false" class="page-form--registration-success">
      <div class="page-form__icon">
        <img :src="require('@/assets/svg/success.svg')" alt="Úspěch" />
      </div>
      <h1 class="page-form__title">Úspěšná registrace</h1>
      <div class="page-form__description">
        Na Váš e-mail <span class="page-form__description-highlight">{{ email }}</span> jsme poslali
        odkaz pro ověření registrace.
      </div>
      <div class="page-form__incentive-link">
        <router-link :to="{ name: 'Login' }">Zpět na přihlášení</router-link>
      </div>
    </PageWrapperCover>
  </div>
</template>

<script>
import PageWrapperCover from '@/pages/wrappers/PageWrapperCover';
import RegistrationForm from '@/components/forms/RegistrationForm';
import SocialConnect from '@/components/ui/social-connect/SocialConnect';
import ConditionsForm from '@/components/forms/ConditionsForm';

export default {
  name: 'RegistrationPage',

  components: {
    PageWrapperCover,
    RegistrationForm,
    SocialConnect,
    ConditionsForm,
  },

  data() {
    return {
      email: '',
      success: false,
      requiredConditions: null,
    };
  },

  mounted() {
    this.requiredConditions = this.$route.params?.requiredConditions;
  },

  methods: {
    onSubmitSuccess({ email }) {
      this.email = email;
      this.success = true;
    },

    onSocialSubmitSuccess() {
      this.$router.push({ name: 'SetCookies' });
    },

    onConfirmationConditions(payload) {
      this.requiredConditions = payload;
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/abstracts/abstracts';
.page-form--login-terms,
.page-form--registration-success {
  .page-form {
    &__icon {
      max-width: rem(200);
    }

    &__title {
      text-align: center;
    }

    &__description {
      text-align: center;
    }

    &__incentive-link {
      text-align: center;
    }
  }
}

.page-form--registration-success {
  .auth-layout__inner {
    margin-bottom: rem(60);

    @include until($breakpoint-medium) {
      margin-bottom: 0;
    }
  }
}
</style>
