var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormulateForm',{attrs:{"form-errors":_vm.formErrors},on:{"submit":_vm.onSubmit},model:{value:(_vm.formValues),callback:function ($$v) {_vm.formValues=$$v},expression:"formValues"}},[_c('FormulateInput',{attrs:{"label":"Nové heslo","type":_vm.isPassword ? 'password' : 'text',"name":"password","validation":"^required|min:8","validation-messages":{
      required: 'Zadejte prosím heslo',
      min: 'Heslo musí obsahovat minimálně 8 znaků',
    },"error-behavior":"submit"},scopedSlots:_vm._u([{key:"label",fn:function({ label, id }){return [_c('label',{attrs:{"for":id}},[_vm._v(" "+_vm._s(label)+" "),_c('span',{class:['password-icon', { isvisible: !_vm.isPassword }],on:{"click":function($event){_vm.isPassword = !_vm.isPassword}}})])]}}])}),_c('FormulateInput',{attrs:{"label":"Nové heslo znovu","type":"password","name":"password_confirm","validation":"^required|confirm","validation-name":"Confirmation","validation-messages":{
      required: 'Zadejte prosím heslo',
      confirm: 'Zadané heslo se neshoduje',
    },"error-behavior":"submit"}}),_c('FormulateInput',{attrs:{"type":"submit","label":"Změnit heslo","disabled":_vm.isSubmiting}},[(_vm.isSubmiting)?_c('Spinner',{attrs:{"size":24,"color":"#ffffff"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }