// AUTH
export const AUTH_LOGIN = '/api/auth/login';
export const AUTH_LOGIN_TOKEN = '/api/auth/loginToken';
export const AUTH_REGISTER = '/api/auth/register';
export const AUTH_LOGOUT = '/api/auth/logout';
export const AUTH_ACTIVATE = '/api/userToken/activate';
export const AUTH_RESET_PASSWORD = '/api/userToken/resetPassword';
export const AUTH_SEND_RESET_PASSWORD_EMAIL = '/api/userToken/send/resetPasswordEmail';
export const AUTH_SEND_ACTIVATION_EMAIL = '/api/userToken/send/activationEmail';
export const AUTH_CHECK = '/api/auth/check';
export const AUTH_SEND_CANCEL_EMAIL = '/api/userToken/send/userCancelEmail';

// USER
export const USER_GET = '/api/user/get';
export const USER_UPDATE = '/api/user/update';
export const USER_CHANGE_PASSWORD = '/api/user/changePassword';
export const USER_CHANGE_AVATAR = '/api/user/changeAvatar';
export const USER_REMOVE_AVATAR = '/api/user/removeAvatar';
export const USER_LOGIN_TYPES = '/api/user/loginTypes';
export const USER_SERVICE_PARAM_GET_ALL = '/api/user/serviceParam/getAll';
export const USER_SERVICE_PARAM_CHANGE_AVATAR = '/api/user/serviceParam/changeAvatar';
export const USER_SERVICE_PARAM_REMOVE_AVATAR = '/api/user/serviceParam/removeAvatar';
export const USER_SERVICE_PARAM_GET_REQUIRED_PARAMS = '/api/user/serviceParam/getRequiredParams';
export const USER_SERVICE_PARAM_SET_PARAMS = '/api/user/serviceParam/setParams';
export const USER_CANCEL_PROFILE = '/api/userToken/userCancel';

// SERVICE
export const SERVICE_LIST_SSO = '/api/service/list/sso';
export const SERVICE_LIST = '/api/service/list';
export const SERVICE_GET_ID = '/api/service/get';

// SESSION
export const SESSION_LIST = '/api/session/list';
export const SESSION_REMOVE = '/api/session/remove';

// UNI
export const API_UNI_ERROR = 'Vyskytla se neočekávaná chyba.';
