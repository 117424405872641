<template>
  <FormulateInput
    label="Datum narození"
    type="date"
    name="birthDate"
    :value="value"
    :help="help"
    :max="getMaxDate()"
    :min="getMinDate()"
    validation="date"
    :validation-rules="{ date: dateRule }"
    :validation-messages="{ date: 'Minimální věk je 15 let' }"
    error-behavior="submit"
    :disabled="disabled"
  />
</template>

<script>
export default {
  name: 'BirthDateField',

  props: {
    value: {
      type: String,
    },
    help: {
      type: [String, Boolean],
    },
    disabled: {
      type: Boolean,
    },
  },

  methods: {
    getMaxDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 15);
      return date.toISOString().slice(0, 10);
    },

    getMinDate() {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 111);
      return date.toISOString().slice(0, 10);
    },

    dateRule({ value }) {
      if (value.length === 0) return true;
      const current = new Date(value).getTime();
      const from = new Date(this.getMinDate()).getTime();
      const to = new Date(this.getMaxDate()).getTime();
      return current >= from && current <= to;
    },
  },
};
</script>
