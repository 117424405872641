<template>
  <div class="web-and-apps">
    <h1 class="page-title">Weby a aplikace</h1>

    <FormulateForm class="web-and-apps__search" v-model="formValues" novalidate autocomplete="off">
      <FormulateInput type="text" name="search" />
    </FormulateForm>

    <div class="web-and-apps__filter">
      <FormulateInput v-model="filter.websites" type="checkbox" label="Webové stránky" />
      <FormulateInput v-model="filter.applications" type="checkbox" label="Mobilní aplikace" />
    </div>

    <div class="web-and-apps__list">
      <Card v-for="{ id, attributes } in services" :key="id" class="web-and-apps__item">
        <router-link :to="{ path: id }" append class="web-and-apps__item-inner">
          <div class="web-and-apps__meta">
            <div class="web-and-apps__name">
              {{ attributes.name }}
            </div>
          </div>
          <div class="web-and-apps__logo">
            <img :src="attributes.logoUrl" alt="logo" />
          </div>
        </router-link>
      </Card>

      <div v-if="noServicesFound" class="web-and-apps__no-services">
        <img :src="require('@/assets/svg/no-results.svg')" alt="žádný výsledek" />
        <p>Hledanému termínu neodpovídá žádný výsledek</p>
      </div>
    </div>

    <Pagination :total="filteredServices.length" :perPage="4" :currentPage="page" />
  </div>
</template>

<script>
import { SERVICE_GET_LIST } from '@/store/actions/service';
import { isEmpty, removeDiacritics } from '@/helpers';
import { mapGetters } from 'vuex';
import Card from '@/components/ui/Card';
import Pagination from '@/components/ui/Pagination';
import { SERVICE_TYPES } from '@/config';

const LIST_ITEMS_LENGTH = 4;

export default {
  name: 'WebApplicationsPage',

  components: {
    Card,
    Pagination,
  },

  data() {
    return {
      page: 1,
      formValues: {
        search: '',
      },
      filter: {
        websites: true,
        applications: true,
      },
    };
  },

  computed: {
    ...mapGetters(['serviceList']),

    filteredServices() {
      let services = this.serviceList;

      if (!this.filter.websites) {
        services = services.filter(({ attributes }) => attributes.type !== SERVICE_TYPES.website);
      }

      if (!this.filter.applications) {
        services = services.filter(
          ({ attributes }) => attributes.type !== SERVICE_TYPES.mobile_app
        );
      }

      return services.filter((service) =>
        service.attributes.hostname.includes(removeDiacritics(this.formValues.search).toLowerCase())
      );
    },

    services() {
      const fromPosition = this.getFromPosition();
      return this.filteredServices.slice(fromPosition, fromPosition + LIST_ITEMS_LENGTH);
    },

    noServicesFound() {
      return (
        this.services.length === 0 &&
        (this.formValues.search.length !== 0 || !this.filter.websites || !this.filter.applications)
      );
    },
  },

  watch: {
    services() {
      if (
        this.filteredServices.length > 0 &&
        !(this.getFromPosition() < this.filteredServices.length)
      ) {
        this.page = 1;
        this.$router.push({ query: { ...this.$route.query, page: this.page } });
      }
    },

    '$route.query'() {
      this.setPageFromRoute();
    },
  },

  async created() {
    if (isEmpty(this.serviceList)) {
      this.$store.dispatch(SERVICE_GET_LIST);
    }

    this.setPageFromRoute();
  },

  methods: {
    setPageFromRoute() {
      const { page } = this.$route.query;
      if (parseInt(page, 10)) this.page = parseInt(Math.abs(page), 10);
    },

    getFromPosition() {
      return (this.page - 1) * LIST_ITEMS_LENGTH;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.web-and-apps {
  position: relative;

  h1 {
    text-align: center;
    margin-bottom: rem(36);
  }

  &__search {
    max-width: rem(620);
    margin: 0 auto;

    /deep/ .formulate-input-element input {
      background-color: $background-color-light;
      padding-left: rem(50);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.839' height='21.83' viewBox='0 0 21.839 21.83'%3E%3Cdefs%3E%3Cstyle%3E .cls-1%7Bfill:none;stroke:%23c2c9d7;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px%7D %3C/style%3E%3C/defs%3E%3Cg id='Interface-Essential_Search_search' transform='translate(-497.56 -3441.56)'%3E%3Cg id='Group_342'%3E%3Cg id='search'%3E%3Cpath id='Oval_210' d='M509.545 3457.807a8.056 8.056 0 1 0-10.565-4.263 8.055 8.055 0 0 0 10.565 4.263z' class='cls-1'/%3E%3Cpath id='Shape_1557' d='M512.09 3456.08l6.248 6.249' class='cls-1'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: rem(15) center;
    }
  }

  &__filter {
    display: flex;
    max-width: rem(836);
    margin: rem(48) auto 0;
    line-height: rem(16);

    > div:first-child {
      margin-right: rem(36);
    }

    /deep/ .formulate-input-element-decorator {
      background-color: $background-color-light;
    }
  }

  &__list {
    max-width: rem(836);
    margin: rem(16) auto rem(48);
  }

  &__no-services {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: rem(36);
    font-size: rem(16);
    font-weight: 600;

    img {
      width: rem(75);
      height: rem(75);
    }

    p {
      padding: rem(24) rem(12) 0;
      font-size: rem(14);
      font-weight: 600;
      color: $color-text-gray;
      text-align: center;
    }
  }

  &__item {
    margin-bottom: rem(16);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(28) rem(48) rem(36);
    text-decoration: none;
    cursor: pointer;

    @include until($breakpoint-small) {
      padding: rem(28) rem(20) rem(36);
    }
  }

  &__name {
    font-size: rem(16);
    font-weight: 600;
    color: $color-text-dark;
    text-transform: capitalize;
  }

  &__logo {
    height: rem(31.5);

    img {
      max-height: 100%;
    }
  }

  .pagination {
    margin-top: rem(48);
  }
}
</style>
