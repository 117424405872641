<template>
  <div class="dropdown">
    <div class="dropdown__label" @click="toggle" :class="{ active: visibility }">
      <slot name="label"></slot>
    </div>

    <div class="dropdown__content" v-if="visibility">
      <slot name="content" class="dropdown__content"></slot>
    </div>
  </div>
</template>

<script>
const CLASS_DROPDOWN = 'dropdown__content';

export default {
  name: 'Dropdown',

  data() {
    return {
      visibility: false,
    };
  },

  methods: {
    toggle() {
      if (!this.visibility) {
        this.visibility = true;
        setTimeout(() => document.addEventListener('click', this.hide), 1);
        this.$emit('dropdown:toggle', this.visibility);
      } else {
        this.hide();
        this.$emit('dropdown:toggle', this.visibility);
      }
    },

    hide(event) {
      if (event && event.target.classList.contains(CLASS_DROPDOWN)) return;

      this.visibility = false;
      document.removeEventListener('click', this.hide);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;

  &__label {
    width: 100%;
    height: 100%;
  }

  &__content {
    position: absolute;
    width: 100%;
    bottom: 0;
    transform: translateY(100%);
  }
}
</style>
