<template>
  <FormulateForm v-model="formValues" @submit="onSubmit" :form-errors="formErrors">
    <FormulateInput
      label="Nové heslo"
      :type="isPassword ? 'password' : 'text'"
      name="password"
      validation="^required|min:8"
      :validation-messages="{
        required: 'Zadejte prosím heslo',
        min: 'Heslo musí obsahovat minimálně 8 znaků',
      }"
      error-behavior="submit"
    >
      <template #label="{ label, id }">
        <label :for="id">
          {{ label }}
          <span
            :class="['password-icon', { isvisible: !isPassword }]"
            @click="isPassword = !isPassword"
          />
        </label>
      </template>
    </FormulateInput>

    <FormulateInput
      label="Nové heslo znovu"
      type="password"
      name="password_confirm"
      validation="^required|confirm"
      validation-name="Confirmation"
      :validation-messages="{
        required: 'Zadejte prosím heslo',
        confirm: 'Zadané heslo se neshoduje',
      }"
      error-behavior="submit"
    />

    <FormulateInput type="submit" label="Změnit heslo" :disabled="isSubmiting"
      ><Spinner v-if="isSubmiting" :size="24" color="#ffffff"
    /></FormulateInput>
  </FormulateForm>
</template>

<script>
import { API } from '@/api';
import BaseFormMixin from '@/mixins/BaseFormMixin';

export default {
  name: 'ResetPasswordFrom',

  mixins: [BaseFormMixin],

  data() {
    return {
      formValues: {},
    };
  },

  methods: {
    onSubmit() {
      const { password } = this.formValues;
      this.handleSubmit(API.auth.resetPassword, { token: this.$route.params.token, password });
    },
  },
};
</script>
