<template>
  <div>
    <PageWrapper v-if="!isResponse" class="page-form--activation">
      <div class="page-form__loading">
        <CircleLoadingBar :size="160" />
      </div>
      <h1 class="page-form__title">Vyčkejte...</h1>
    </PageWrapper>

    <PageWrapper v-else-if="success" :header="false" class="page-form--activation-success">
      <div class="page-form__icon">
        <img :src="require('@/assets/svg/success.svg')" alt="Chyba" />
      </div>
      <h1 class="page-form__title">Registrace dokončena</h1>
      <div class="page-form__description">
        <span v-if="loginUrl">
          Pokud se chcete přihlásit, <a :href="loginUrl">klikněte zde</a>
        </span>

        <span v-else>
          Budete přesměrováni na přihlášení, pokud automaticky neproběhne
          <router-link  :to="{ name: 'Login' }">klikněte zde</router-link>
        </span>
      </div>
    </PageWrapper>

    <PageWrapper v-else-if="limit" :header="false" class="page-form--activation-limit">
      <div class="page-form__icon">
        <img :src="require('@/assets/svg/alert.svg')" alt="Chyba" />
      </div>
      <h1 class="page-form__title">Překročení limitu</h1>
      <div class="page-form__description">
        Překročil jste limit 3x zasláním odkazu na e-mail k zaslání aktivačního odkazu.
      </div>
      <div class="page-form__description">
        Kontaktujte prosím podporu na e-mailu
        <a href="mailto:podpora@cncenter.cz" class="link">podpora@cncenter.cz</a>
      </div>
    </PageWrapper>

    <PageWrapper v-else :header="false" class="page-form--activation-fail">
      <div class="page-form__icon">
        <img :src="require('@/assets/svg/expired.svg')" alt="Chyba" />
      </div>
      <h1 class="page-form__title">Odkaz je neplatný</h1>
      <div class="page-form__description">
        Bohužel platnost odkazu pro dokončení Vaší registrace nelze ověřit.
      </div>
      <ActivationEmailForm @submitLimitExceeded="onSubmitLimitExceeded" />
      <div class="page-form__incentive-link">
        <router-link :to="{ name: 'Registration' }">Přejít na registraci</router-link>
      </div>
    </PageWrapper>
  </div>
</template>

<script>
import PageWrapper from '@/pages/wrappers/PageWrapper';
import ActivationEmailForm from '@/components/forms/ActivationEmailForm';
import CircleLoadingBar from '@/components/ui/CircleLoadingBar';
import { API } from '@/api';
import { REDIRECT_DELAY } from '@/config';
import { AUTH_SET_SERVICE_ID, AUTH_SET_REDIRECT_URL } from '@/store/actions/auth';

export default {
  name: 'ActivationPage',

  components: { PageWrapper, ActivationEmailForm, CircleLoadingBar },

  data() {
    return {
      isResponse: false,
      success: false,
      limit: false,
      loginUrl: null,
    };
  },

  async mounted() {
    const response = await API.auth.activate({ token: this.$route.params.token });

    this.isResponse = true;

    if (response.status === 200) {
      const { meta } = response;

      if (meta?.loginUrl) {
        this.loginUrl = meta?.loginUrl;
      }

      const serviceId = meta?.serviceId;
      const redirectUrl = meta?.redirectUrl;

      if (serviceId && redirectUrl) {
        this.$store.dispatch(AUTH_SET_SERVICE_ID, serviceId);
        this.$store.dispatch(AUTH_SET_REDIRECT_URL, redirectUrl);
      }

      if (!this.$store.getters.isAuthenticated) {
        this.success = true;

        if(this.loginUrl === null) {
          setTimeout(() => {
              this.$router.push({ name: 'Login' });
          }, REDIRECT_DELAY);
        }
      } else {
        this.success = true;
      }
    } else {
      this.success = false;
    }
  },

  methods: {
    onSubmitLimitExceeded() {
      this.limit = true;
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/abstracts/abstracts';

.page-form--activation {
  .page-form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(404);
    height: rem(404);

    @include until($breakpoint-small) {
      width: auto;
      height: auto;
    }

    &__loading {
      margin-bottom: rem(48);

      svg {
        width: 160px;
      }
    }

    &__title {
      margin-bottom: 0;
    }
  }
}

.page-form--activation-fail {
  .page-form {
    &__icon {
      max-width: rem(69);
    }

    &__description {
      padding-bottom: rem(24);
    }
  }
}

.page-form--activation-success {
  .page-form {
    &__icon {
      max-width: rem(200);
    }
  }
}

.page-form--activation-limit {
  .page-form {
    &__icon {
      max-width: rem(99);
    }

    &__description {
      padding-bottom: rem(40);

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}
</style>
