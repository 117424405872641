<template>
  <div class="user-data-list">
    <div class="user-data-list__row">
      <div class="user-data-list__label">E-mail</div>
      <div class="user-data-list__value">{{ email }}</div>
    </div>
    <div class="user-data-list__row" v-if="firstName">
      <div class="user-data-list__label">Jméno</div>
      <div class="user-data-list__value">
        <span v-if="firstName">{{ firstName }}</span>
        <router-link v-else :to="{ name: 'MyProfile' }">vyplnit</router-link>
      </div>
    </div>
    <div class="user-data-list__row">
      <div class="user-data-list__label">Příjmení</div>
      <div class="user-data-list__value">
        <span v-if="lastName">{{ lastName }}</span>
        <router-link v-else :to="{ name: 'MyProfile' }">vyplnit</router-link>
      </div>
    </div>
    <div class="user-data-list__row">
      <div class="user-data-list__label">Pohlaví</div>
      <div class="user-data-list__value">
        <span v-if="sex">{{ sex }}</span>
        <router-link v-else :to="{ name: 'MyProfile' }">vyplnit</router-link>
      </div>
    </div>
    <div class="user-data-list__row">
      <div class="user-data-list__label">Datum narození</div>
      <div class="user-data-list__value">
        <span v-if="birthDate">{{ birthDate }}</span>
        <router-link v-else :to="{ name: 'MyProfile' }">vyplnit</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { USER_PARAMS } from '@/config';

export default {
  name: 'UserDataList',

  computed: {
    ...mapGetters(['userProfile']),

    params() {
      return this.userProfile.params.reduce((params, param) => {
        params[param.name] = param.value;
        return params;
      }, {});
    },

    email() {
      return this.userProfile.email;
    },

    firstName() {
      return this.userProfile.firstName;
    },

    lastName() {
      return this.userProfile.lastName;
    },

    sex() {
      const sex = this.params?.[USER_PARAMS.sex];
      return sex ? (sex === 'M' ? 'mužské' : 'ženské') : undefined;
    },

    birthDate() {
      return this.params?.[USER_PARAMS.birthDate];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';
.user-data-list {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    margin-bottom: rem(24);

    &:last-child {
      margin-bottom: 0;
    }

    > div {
      flex: 1;
    }
  }

  &__label,
  &__value {
    font-size: rem(16);
  }

  &__value {
    font-weight: 600;

    a {
      font-weight: normal;
    }
  }
}
</style>
