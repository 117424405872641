<template>
  <FormulateInput
    label="Příjmení"
    type="text"
    name="lastName"
    :value="value"
    :help="help"
    validation="bail|alphaExtendedSpecial|emptyOrMin:2|max:20,length"
    :validation-messages="{
      alphaExtendedSpecial: 'Povolené jsou pouze znaky abecedy',
      emptyOrMin: 'Příjmení musí obsahovat minimálně 2 znaky',
      max: 'Příjmení je omezeno na maximálně 20 znaků',
    }"
    error-behavior="submit"
    :disabled="disabled"
  />
</template>

<script>
export default {
  name: 'LastNameField',

  props: {
    value: {
      type: String,
    },
    help: {
      type: [String, Boolean],
    },
    disabled: {
      type: Boolean,
    },
  },
};
</script>
