<template>
  <FormulateForm v-model="formValues" @submit="onSubmit" :form-errors="formErrors" novalidate>
    <FormulateErrors/>
    <div class="page-form__conditions">
      <FormulateInput
          name="approvalTerms"
          type="checkbox"
          validation="accepted"
          :validation-messages="{
          accepted: 'Potvrzení souhlasu s podmínkami je povinné',
        }"
          error-behavior="submit"
      >
        <template #label="{ id }">
          <label :for="id">
            <span class="required">*</span> Souhlasím s:
            <a href="https://www.cncenter.cz/podminky-uzivani" target="_blank"
            >Podmínkami pro užívání služby informační
              společnosti</a> a seznámil/a jsem se
            <a href="https://www.cncenter.cz/informace-o-zpracovani-obecna" target="_blank"
            >Informací o zpracování osobních údajů</a>
          </label>
        </template>
      </FormulateInput>
      <FormulateInput name="approvalCommercial" type="checkbox" error-behavior="submit">
        <template #label="{ id }">
          <label :for="id">
            Souhlasím se:
            <a
                href=" https://www.cncenter.cz/souhlas-se-zasilanim-obchodnich-sdeleni"
                target="_blank"
            >Zpracováním osobních údajů pro zasílání obchodních sdělení.</a
            >
          </label>
        </template>
      </FormulateInput>
    </div>
    <FormulateInput type="submit" label="Dokončit" :disabled="isSubmiting"
    >
      <Spinner v-if="isSubmiting" :size="24" color="#ffffff"
      />
    </FormulateInput>
  </FormulateForm>
</template>

<script>
import BaseFormMixin from '@/mixins/BaseFormMixin';
import {AUTH_LOGIN_REQUEST, AUTH_LOGIN_TOKEN_REQUEST} from '@/store/actions/auth';

export default {
  name: 'ConditionsForm',

  mixins: [BaseFormMixin],

  data() {
    return {
      formValues: {},
    };
  },

  props: {
    requestInfo: {
      type: Object,
    },
  },

  methods: {
    onSubmit(data) {
      const {approvalCommercial} = data;
      const {type, token, email, password} = this.requestInfo;

      if (email) {
        this.handleSubmit(AUTH_LOGIN_REQUEST, {
          email,
          password,
          termsConfirmed: true,
          termsCommercialConfirmed: approvalCommercial,
          serviceId: this.serviceId,
        });
      }

      if (token) {
        this.handleSubmit(AUTH_LOGIN_TOKEN_REQUEST, {
          type,
          token,
          termsConfirmed: true,
          termsCommercialConfirmed: approvalCommercial,
          serviceId: this.serviceId,
        });
      }
    },
  },
};
</script>
