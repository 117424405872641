import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/modules/auth';
import user from '@/store/modules/user';
import service from '@/store/modules/service';
import session from '@/store/modules/session';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    user,
    service,
    session,
  },
});
