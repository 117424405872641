<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Card',
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.card {
  background-color: $background-color-light;
  box-shadow: $box-shadow-bottom;
}
</style>
