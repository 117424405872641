<template>
  <div>
    <PageWrapperCover v-if="!verification" :header="false" :footer="true" class="page-form--login">
      <h1 class="page-form__title">Přihlášení</h1>
      <div class="page-form__social-connect">
        <SocialConnect
          @submitSuccess="onSubmitSuccess"
          @confirmationConditions="onConfirmationConditions"
          @submitError="onSubmitError"
        />
      </div>
      <div class="page-form__divider">Nebo se přihlašte e-mailem</div>
      <LoginForm
        @submitSuccess="onSubmitSuccess"
        @emailVerification="onEmailVerification"
        @confirmationConditions="onConfirmationConditions"
        @submitError="onSubmitError"
      />
      <div class="page-form__incentive-link">
        <span
          >Nemáte účet?
          <router-link :to="{ name: 'Registration' }">Registrujte se</router-link></span
        >
      </div>
      <div class="page-form__trouble-link">
        <span
          >Pokud máte problémy s přihlášením napište na
          <a href="mailto:podpora@cncenter.cz">podpora@cncenter.cz</a></span
        >
      </div>
    </PageWrapperCover>

    <PageWrapperCover v-else :header="false" :footer="true" class="page-form--login-verification">
      <h1 class="page-form__title">Ověření e-mailu</h1>
      <div class="page-form__description">
        Před přihlášením je nutné ověřit vaší e-mailovou adresu. Přejete si zaslat nový aktivační
        link?
      </div>
      <ActivationEmailForm :email="email" />
      <div class="page-form__incentive-link">
        <a @click="backToLogin">Zpět na přihlášení</a>
      </div>
    </PageWrapperCover>
  </div>
</template>

<script>
import PageWrapperCover from '@/pages/wrappers/PageWrapperCover';
import LoginForm from '@/components/forms/LoginForm';
import SocialConnect from '@/components/ui/social-connect/SocialConnect';
import ActivationEmailForm from '@/components/forms/ActivationEmailForm';

export default {
  name: 'LoginPage',

  components: {
    PageWrapperCover,
    LoginForm,
    SocialConnect,
    ActivationEmailForm,
  },

  data() {
    return {
      verification: false,
      email: '',
    };
  },

  methods: {
    onSubmitSuccess() {
      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push(function () {
        window.gtmService.push({
          event: 'user_login_success',
        });
      });

      this.$router.push({ name: 'SetCookies' });
    },

    onConfirmationConditions(payload) {
      this.$router.push({ name: 'Registration', params: { requiredConditions: payload } });
    },

    onEmailVerification({ email }) {
      this.email = email;
      this.verification = true;
    },

    onSubmitError() {
      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push(function () {
        window.gtmService.push({
          event: 'user_login_error',
        });
      });
    },

    backToLogin() {
      this.email = '';
      this.verification = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.page-form {
  &--login-verification {
    .page-form__description {
      margin-bottom: rem(26);
    }
  }
}
</style>
