<template>
  <div class="web-and-apps-detail">
    <router-link :to="{ name: 'WebsitesAndApplications' }" class="web-and-apps-detail__back"
      >Zpět na výpis</router-link
    >

    <div v-if="isService && hasUserServiceParams" class="web-and-apps-detail__inner">
      <Card class="web-and-apps-detail__meta">
        <img class="web-and-apps-detail__logo" :src="service.logoUrl" alt="logo" />
        <div class="web-and-apps-detail__name">
          {{ isMobileApp ? service.name : service.hostname }}
        </div>
        <a
          v-if="service.homepageUrl"
          :href="service.homepageUrl"
          class="button web-and-apps-detail__link"
          data-track-click='{"event": "clickTo_content", "clickTo":"Navštívit webovou stránku"}'
          >Navštívit webovou stránku</a
        >
      </Card>

      <div class="web-and-apps-detail__main">
        <Card v-if="service.params && Object.keys(service.params).length > 0">
          <div class="web-and-apps-detail__header">
            <h2>Doplňující informace</h2>
          </div>

          <EditUserServiceForm :service="service" />
        </Card>

        <Card>
          <div class="web-and-apps-detail__header">
            <div>
              <h2>Osobní informace</h2>
              <p>
                Osobní informace se poskytují globálně a lze je upravit pouze v sekci Můj profil.
                Následně se propisují globálně.
              </p>
            </div>
            <router-link :to="{ name: 'MyProfile' }" class="button button--inline button--small"
              >Upravit údaje</router-link
            >
          </div>

          <UserDataList />
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { SERVICE_TYPES } from '@/config';
import { SERVICE_GET_LIST } from '@/store/actions/service';
import { USER_GET_SERVICE_PARAMS, USER_GET_SERVICE_REQUIRED_PARAMS } from '@/store/actions/user';
import { isEmpty } from '@/helpers';
import { mapGetters } from 'vuex';
import Card from '@/components/ui/Card';
import { serviceHandler } from '@/utils';

import UserDataList from '@/components/layouts/UserDataList';
import EditUserServiceForm from '@/components/forms/EditUserServiceForm';

export default {
  name: 'WebsiteApplicationsDetaiPage',

  components: {
    Card,
    UserDataList,
    EditUserServiceForm,
  },

  computed: {
    ...mapGetters([
      'serviceList',
      'userProfile',
      'userServiceParams',
      'hasUserServiceParams',
      'userRequiredServiceParams',
      'hasUserRequiredServiceParams',
    ]),

    isService() {
      return this.serviceList.some(({ id }) => id === this.$route.params.id);
    },

    service() {
      const services = serviceHandler(
        this.serviceList,
        this.userServiceParams,
        this.userRequiredServiceParams
      );
      return services.find((service) => service.id === this.$route.params.id);
    },

    isMobileApp() {
      return this.service.type === SERVICE_TYPES.mobile_app;
    },
  },

  watch: {
    serviceList() {
      if (!this.isService) {
        this.$router.push({ name: 'WebsitesAndApplications' });
      }
    },
  },

  async created() {
    if (isEmpty(this.serviceList)) {
      this.$store.dispatch(SERVICE_GET_LIST);
    }

    if (!this.hasUserServiceParams) {
      this.$store.dispatch(USER_GET_SERVICE_PARAMS);
    }

    if (!this.hasUserRequiredServiceParams) {
      this.$store.dispatch(USER_GET_SERVICE_REQUIRED_PARAMS);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.web-and-apps-detail {
  &__back {
    position: absolute;
    margin-top: rem(-30);
    color: $color-text-gray;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    align-items: center;
    line-height: 0;

    @include until($breakpoint-small) {
      margin-top: rem(-23);
    }

    &::before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.5 9.621'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%23999;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px%7D%3C/style%3E%3C/defs%3E%3Cg id='Arrow' transform='translate(-357.75 -440.189)'%3E%3Cpath id='Shape_3307' d='M255.755 7891h14' class='cls-1' transform='rotate(180 314.127 4168)'/%3E%3Cpath id='Shape_3308' d='M259.505 7887.25l-3.75 3.75 3.75 3.75' class='cls-1' transform='rotate(180 314.127 4168)'/%3E%3C/g%3E%3C/svg%3E");
      width: rem(14);
      height: rem(10);
      background-size: contain;
      transform: rotate(180deg);
      display: inline-block;
      background-repeat: no-repeat;
      margin-right: rem(10);
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__inner {
    display: flex;
    align-items: flex-start;

    @include until($breakpoint-medium) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__meta {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: rem(404);
    padding: rem(48) rem(36);

    @include until($breakpoint-small) {
      padding: rem(48) rem(20);
    }
  }

  &__logo {
    height: rem(46);
  }

  &__name {
    font-size: rem(16);
    font-weight: 600;
    margin-top: rem(28);
  }

  &__link {
    margin-top: rem(28);
  }

  &__main {
    flex: 1;
    margin-left: rem(28);

    @include until($breakpoint-medium) {
      width: 100%;
      margin-top: rem(16);
      margin-left: 0;
    }
  }

  &__main .card {
    position: relative;
    padding: rem(36) rem(48);
    margin-bottom: rem(16);

    &:last-child {
      margin-bottom: 0;
    }

    @include until($breakpoint-small) {
      padding: rem(36) rem(20);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: rem(36);

    @include until($breakpoint-small) {
      flex-direction: column;
      align-items: center;
      text-align: center;

      p {
        max-width: auto;
      }

      a {
        margin-top: rem(20);
      }
    }

    h2 {
      margin-bottom: rem(10);
    }

    p {
      max-width: rem(404);
      font-size: rem(14);
      color: $color-text-gray;
    }
  }

  /deep/ .avatar {
    @include until($breakpoint-small) {
      flex-direction: column;
      align-items: center;

      &__options {
        margin: rem(10) 0 0;
      }

      &__options > label {
        justify-content: center;
      }

      &__actions {
        flex-direction: column;
        margin: rem(16) 0 0;
      }

      &__remove {
        margin: rem(16) 0 0;
      }
    }
  }

  /deep/ .edit-user-service__form-fields {
    > div {
      @include from($breakpoint-large) {
        width: calc(50% - 14px);
      }

      @include fromUntil($breakpoint-medium, $breakpoint-small) {
        width: calc(50% - 14px);
      }
    }
  }
}
</style>
