/**
 * @description Returns the formatted time difference from the current time
 * @param {String} time
 * @returns {String}
 */
const getTimeDifferent = (time) => {
  const differenceTime = new Date(time);
  const currentTime = new Date();
  const different = Math.round((currentTime.getTime() - differenceTime.getTime()) / 1000 / 60);

  const hours = differenceTime.getHours();
  const minutes = differenceTime.getMinutes();
  const formattedHours = `${hours < 10 ? '0' + hours : hours}`;
  const formattedMinutes = `${minutes < 10 ? '0' + minutes : minutes}`;

  // If the difference is less than an hour, return the difference in minutes.
  if (different < 60) {
    return `před ${different} min`;
  }

  // Otherwise, return the date.
  const day = differenceTime.getDate();
  const month = differenceTime.getMonth();
  const year = differenceTime
    .getFullYear()
    .toString()
    .slice(2);

  return `${day}. ${month}. ${year} v ${formattedHours}:${formattedMinutes}`;
};

export default { getTimeDifferent };
