<template>
  <div class="page-set-cookies">
    <CircleProgressBar :size="160" :process="process" :max="max" />
    <h1>Vyčkejte...</h1>
  </div>
</template>

<script>
import { API } from '@/api';
import { mapGetters } from 'vuex';
import { makeRequest } from '@/utils';
import { CIRCUIT_HEALTZ_TIMEOUT, SERVICE_TYPES } from '@/config';
import { isUrl, addParamToUrl, isMobile } from '@/helpers';
import CircleProgressBar from '@/components/ui/CircleProgressBar';
import { AUTH_SET_SERVICE_ID, AUTH_SET_REDIRECT_URL } from '@/store/actions/auth';

const LSTORAGE_SERVICES = 'ssoser';
const LSTORAGE_SERVICES_LENGTH = 'ssolen';
const LSTORAGE_REDIRECT_URL = 'ssored';

const CIRCUIT_HEALTZ_URL = '/sso/circuit/healtz';
const CIRCUIT_SET_URL = '/sso/circuit/set';

const REDIRECT_URL_TOKEN_KEY = 'ssost';

export default {
  name: 'SetCookiesPage',

  components: { CircleProgressBar },

  data() {
    return {
      process: 0,
      max: 0,
    };
  },

  computed: {
    ...mapGetters(['authToken']),
  },

  beforeRouteEnter(to, from, next) {
    const redirectUrl = from.query?.redirectUrl || to.query?.redirectUrl;
    const serviceId = from.query?.sid || to.query?.sid;

    next((vm) => {
      if (serviceId && isUrl(redirectUrl)) {
        vm.$store.dispatch(AUTH_SET_SERVICE_ID, serviceId);
        vm.$store.dispatch(AUTH_SET_REDIRECT_URL, redirectUrl);
      }
    });
  },

  async mounted() {
    const lStorage = window.localStorage;
    const services = await this.getService();
    const [targetUrl, redirectsUrl, frontUrl] = [...this.splitServices(services)];
    const servicesLength = parseInt(lStorage.getItem(`${LSTORAGE_SERVICES_LENGTH}`), 10);
    const { serviceId, redirectUrl, authToken } = this.$store.getters;

    if (serviceId && redirectUrl) {
      const sid = await API.service.getServiceId({ id: serviceId });
      const serviceType = sid?.data?.attributes?.type;
      let rUrl = redirectUrl;

      if (
        serviceType === SERVICE_TYPES.mobile_app ||
        serviceType === SERVICE_TYPES.internal_service
      ) {
        rUrl = addParamToUrl(`${REDIRECT_URL_TOKEN_KEY}=${authToken}`, rUrl);
      }

      lStorage.setItem(`${LSTORAGE_REDIRECT_URL}`, rUrl);
    }

    this.max = servicesLength;

    if (targetUrl && targetUrl.length > 0) {
      this.process = servicesLength - frontUrl.length;
      lStorage.setItem(LSTORAGE_SERVICES, JSON.stringify(frontUrl));

      const { origin, pathname } = window.location;
      const request = `${targetUrl[0]}${CIRCUIT_SET_URL}/${
        this.authToken
      }?back=${origin}${pathname}&sites=${redirectsUrl.join(';')}&isMobile=${isMobile() ? 1 : 0}`;

      window.location = request;
    } else {
      this.process = servicesLength;
      lStorage.removeItem(LSTORAGE_SERVICES);
      lStorage.removeItem(`${LSTORAGE_SERVICES_LENGTH}`);
      const redirectUrl = lStorage[`${LSTORAGE_REDIRECT_URL}`];
      if (redirectUrl) {
        lStorage.removeItem(`${LSTORAGE_REDIRECT_URL}`);
        window.location = redirectUrl;
      } else {
        this.$router.push({ name: 'Home' });
      }
    }
  },

  methods: {
    async getService() {
      const lStorage = window.localStorage;
      const storage = lStorage[LSTORAGE_SERVICES];

      if (storage === undefined) {
        const services = await this.getServiceList();
        lStorage.setItem(`${LSTORAGE_SERVICES_LENGTH}`, services.length);
        return services;
      } else {
        return JSON.parse(storage);
      }
    },

    async getServiceList() {
      const request = await API.service.getServiceListSSO({ token: this.authToken });

      if (request.status === 200) {
        const services = request.data.map((service) => service.attributes.cookiePushUrl);
        const targetPromises = this.createServicePromises(services);
        const testedServices = await Promise.all(targetPromises).catch((err) => console.warn(err));
        const liveServices = testedServices.filter((target) => target !== null);
        return liveServices;
      }

      // TODO: pokud bude API mrtva, vypsat chybovy stav
    },

    /**
     * @param {Array} data
     * @returns {Array<Promise>}
     */
    createServicePromises(data) {
      return data.map((service) =>
        makeRequest
          .get(`${service}${CIRCUIT_HEALTZ_URL}`, {
            timeout: CIRCUIT_HEALTZ_TIMEOUT,
          })
          .then((res) => (res.data === 'ok' ? service : null))
          .catch(() => null)
      );
    },

    /**
     * @description Returns Array of 3 array - targetURl, RedirectsUrl and FrontUrl
     * @param {Array<String>} services
     * @returns {Array<Array<String>, Array<String>, Array<String>>}
     */
    splitServices(services) {
      return services.reduce((acc, curr, i) => {
        if (!Array.isArray(acc[0])) acc[0] = [];
        if (!Array.isArray(acc[1])) acc[1] = [];
        if (!Array.isArray(acc[2])) acc[2] = [];
        if (i < 1) {
          acc[0].push(curr);
        } else if (i < 5) {
          acc[1].push(curr);
        } else {
          acc[2].push(curr);
        }
        return acc;
      }, []);
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/abstracts/abstracts';

.page-set-cookies {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .progress-circle {
    margin-bottom: rem(28);
  }
}
</style>
