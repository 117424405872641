import { makeRequest } from '@/utils';
import {
  AUTH_LOGIN,
  AUTH_LOGIN_TOKEN,
  AUTH_REGISTER,
  AUTH_LOGOUT,
  AUTH_ACTIVATE,
  AUTH_RESET_PASSWORD,
  AUTH_SEND_RESET_PASSWORD_EMAIL,
  AUTH_SEND_ACTIVATION_EMAIL,
  AUTH_SEND_CANCEL_EMAIL,
  AUTH_CHECK,
  API_UNI_ERROR,
} from './constants';

export default {
  /**
   * @param {Object} payload
   * @param {String} payload.email
   * @param {String} payload.password
   * @param {String} [payload.device]
   * @param {String} [payload.serviceId]
   */
  login: async (payload) => {
    try {
      const response = await makeRequest.post(AUTH_LOGIN, payload);

      return {
        status: response.status,
        data: response.data,
      };
    } catch (err) {
      const status = err.response.status;
      const code = err.response.data?.errors[0]?.code;

      const response = {
        status,
        code,
      };

      switch (status) {
        case 400:
          response.message = 'Neplatné uživatelské údaje.';
          break;
        case 401:
          response.message = 'E-mail není ověřený.';
          break;
        default:
          response.message = API_UNI_ERROR;
      }

      return response;
    }
  },

  /**
   * @param {Object} payload
   * @param {Number} payload.type
   * @param {String} payload.token
   * @param {Boolean} [payload.termsConfirmed]
   * @param {Boolean} [payload.termsCommercialConfirmed]
   * @param {String} [payload.device]
   * @param {String} [payload.serviceId]
   */
  loginToken: async (payload) => {
    try {
      const response = await makeRequest.post(AUTH_LOGIN_TOKEN, payload);

      return {
        status: response.status,
        data: response.data,
      };
    } catch (err) {
      const status = err.response.status;
      const code = err.response.data?.errors[0]?.code;

      const response = {
        status,
        code,
      };

      switch (status) {
        case 400:
          response.message = 'Z profilu není možné získat e-mail.';
          break;
        case 401:
          response.message = 'Podmínky pro užívání služby nejsou potvrzeny.';
          break;
        default:
          response.message = API_UNI_ERROR;
      }

      return response;
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.email
   * @param {String} payload.password
   * @param {Boolean} [payload.termsConfirmed]
   * @param {Boolean} [payload.termsCommercialConfirmed]
   * @param {String} [payload.serviceId]
   */
  register: async (payload) => {
    try {
      const response = await makeRequest.post(AUTH_REGISTER, payload);
      return {
        status: response.status,
        data: response.data,
        message: 'Vaše registrace byla úspěšná.',
      };
    } catch (err) {
      const status = err.response.status;
      const code = err.response.data?.errors[0]?.code;

      const response = {
        status,
        code,
      };

      switch (status) {
        case 401:
          response.message = 'E-mail není ověřený.';
          break;
        case 409:
          response.message = 'Uživatel s tímto e-mailem již existuje.';
          break;
        default:
          response.message = API_UNI_ERROR;
      }

      return response;
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.token
   */
  logout: async ({ token }) => {
    try {
      const response = await makeRequest.get(AUTH_LOGOUT, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });

      return {
        status: response.status,
      };
    } catch (err) {
      return {
        status: err.response.status,
      };
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.token
   */
  activate: async ({ token }) => {
    try {
      const response = await makeRequest.post(AUTH_ACTIVATE, { token });

      return {
        status: response.status,
        message: 'Účet byl aktivován.',
        meta: response.data.meta,
      };
    } catch (err) {
      const status = err.response.status;

      const response = {
        status,
      };

      switch (status) {
        case 400:
          response.message = 'Token není platný.';
          break;
        default:
          response.message = API_UNI_ERROR;
      }

      return response;
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.token
   * @param {String} payload.password
   */
  resetPassword: async ({ token, password }) => {
    try {
      const response = await makeRequest.post(AUTH_RESET_PASSWORD, { token, password });
      return {
        status: response.status,
        message: 'Heslo bylo změněno.',
        meta: response.data.meta,
      };
    } catch (err) {
      const status = err.response.status;

      const response = {
        status,
      };

      switch (status) {
        case 400:
          response.message = 'Token není platný.';
          break;
        default:
          response.message = API_UNI_ERROR;
      }

      return response;
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.email
   * @param {String} [payload.serviceId]
   * @param {String} [payload.redirectUrl]
   */
  resetPasswordEmail: async ({ email, serviceId, redirectUrl }) => {
    try {
      const response = await makeRequest.post(AUTH_SEND_RESET_PASSWORD_EMAIL, {
        email,
        serviceId,
        redirectUrl,
      });
      return {
        status: response.status,
        message: 'Žádost o obnovu hesla byla odeslána na Váš e-mail.',
      };
    } catch (err) {
      const status = err.response.status;

      const response = {
        status,
      };

      switch (status) {
        case 400:
          response.message = 'Neplatný e-mail.';
          break;
        default:
          response.message = API_UNI_ERROR;
      }

      return response;
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.email
   * @param {String} [payload.serviceId]
   * @param {String} [payload.redirectUrl]
   */
  sendActivationEmail: async ({ email, serviceId, redirectUrl }) => {
    try {
      const response = await makeRequest.post(AUTH_SEND_ACTIVATION_EMAIL, {
        email,
        serviceId,
        redirectUrl,
      });
      return {
        status: response.status,
        message: 'Aktivační odkaz byl odeslán na Váš e-mail.',
      };
    } catch (err) {
      const status = err.response.status;

      const response = {
        status,
      };

      switch (status) {
        case 400:
          response.message = 'Neplatný e-mail.';
          break;
        default:
          response.message = API_UNI_ERROR;
      }

      return response;
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.token
   * @param {String} [payload.serviceId]
   * @param {String} [payload.redirectUrl]
   */
  sendCancelEmail: async ({ token, serviceId, redirectUrl }) => {
    try {
      const response = await makeRequest.post(
        AUTH_SEND_CANCEL_EMAIL,
        {
          serviceId,
          redirectUrl,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return {
        status: response.status,
        message: 'Odkaz pro zrušení účtu byl odeslán na Váš e-mail.',
      };
    } catch (err) {
      const status = err.response.status;

      const response = {
        status,
      };

      switch (status) {
        case 429:
          response.message =
            'Překročili jste limit pro zasílání e-mailů ke zrušení účtu. Zkuste to prosím později.';
          break;
        default:
          response.message = API_UNI_ERROR;
      }

      return response;
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.token
   */
  check: async ({ token }) => {
    try {
      const response = await makeRequest.get(AUTH_CHECK, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });

      return {
        status: response.status,
      };
    } catch (err) {
      return {
        status: err.response.status,
      };
    }
  },
};
