import Vue from 'vue';
import Router from 'vue-router';
import routes from '@/router/routes';
import authGuard from '@/router/authGuard';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes,
});

export default router;

// Check if the token is valid.
router.beforeEach(authGuard.checkTokenValidity);

// Title document change.
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Můj účet - jednotné přihlášení do světa online služeb CNC';
  next();
});
