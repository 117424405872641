<template>
  <FormulateForm v-model="formValues" @submit="onSubmit" :form-errors="formErrors" novalidate>
    <FormulateErrors />
    <FormulateInput
      label="E-mail"
      type="email"
      name="email"
      validation="^required|email"
      :validation-messages="{
        required: 'Zadejte prosím email',
        email: 'Vámi zadaný e-mail nemá správný formát',
      }"
      error-behavior="submit"
    />
    <FormulateInput
      label="Heslo"
      :type="isPassword ? 'password' : 'text'"
      name="password"
      validation="^required|min:8"
      :validation-messages="{
        required: 'Zadejte prosím heslo',
        min: 'Heslo musí obsahovat minimálně 8 znaků',
      }"
      error-behavior="submit"
    >
      <template #label="{ label, id }">
        <label :for="id">
          {{ label }}
          <span
            :class="['password-icon', { isvisible: !isPassword }]"
            @click="isPassword = !isPassword"
          />
        </label>
      </template>
    </FormulateInput>
    <div class="page-form__conditions">
      <FormulateInput
        name="approvalTerms"
        type="checkbox"
        validation="accepted"
        :validation-messages="{
          accepted: 'Potvrzení souhlasu s podmínkami je povinné',
        }"
        error-behavior="submit"
      >
        <template #label="{ id }">
          <label :for="id">
            <span class="required">*</span> Souhlasím s:
            <a href="https://www.cncenter.cz/podminky-uzivani" target="_blank"
            >Podmínkami pro užívání služby informační
              společnosti</a> a seznámil/a jsem se
            <a href="https://www.cncenter.cz/informace-o-zpracovani-obecna" target="_blank"
            >Informací o zpracování osobních údajů</a>
          </label>
        </template>
      </FormulateInput>
      <FormulateInput name="approvalCommercial" type="checkbox" error-behavior="submit">
        <template #label="{ id }">
          <label :for="id">
            Souhlasím se:
            <a
              href="https://www.cncenter.cz/souhlas-se-zasilanim-obchodnich-sdeleni"
              target="_blank"
              >Zpracováním osobních údajů pro zasílání obchodních sdělení.</a
            >
          </label>
        </template>
      </FormulateInput>
    </div>
    <FormulateInput type="submit" label="Registrovat se" :disabled="isSubmiting"
      ><Spinner v-if="isSubmiting" :size="24" color="#ffffff"
    /></FormulateInput>
  </FormulateForm>
</template>

<script>
import BaseFormMixin from '@/mixins/BaseFormMixin';
import { AUTH_REGISTER_REQUEST } from '@/store/actions/auth';

export default {
  name: 'RegistrationForm',

  mixins: [BaseFormMixin],

  data() {
    return {
      formValues: {},
    };
  },

  methods: {
    onSubmit(data) {
      const { email, password, approvalCommercial } = data;

      this.handleSubmit(AUTH_REGISTER_REQUEST, {
        email,
        password,
        termsConfirmed: true,
        termsCommercialConfirmed: approvalCommercial,
        serviceId: this.serviceId,
        redirectUrl: this.redirectUrl,
      });
    },
  },
};
</script>
