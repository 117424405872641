<template>
  <FormulateForm
    class="edit-user-form"
    v-model="formValues"
    :form-errors="formErrors"
    @submit="onSubmit"
    novalidate
  >
    <Card>
      <h2 class="edit-user-form__title">Osobní údaje</h2>

      <FormulateErrors />

      <div class="double-wide">
        <FirstNameField :value="firstName" />
        <LastNameField :value="lastName" />
      </div>

      <div class="double-wide">
        <NicknameField :value="nickname" />
        <SexField :value="sex" />
      </div>

      <div class="double-wide">
        <BirthDateFiled :value="birthDate" />
        <PhoneField :value="phone" />
      </div>

      <div class="single-wide">
        <FormulateInput
          v-model="setEverywhere"
          label="Použít na všech webech globálně"
          type="checkbox"
          name="setEverywhere"
        >
          <template #label="{ label, id }">
            <LabelInfo
              :id="id"
              :label="label"
              info="Zaškrtnutím použijete toto nastavení pro všechny weby."
            />
          </template>
        </FormulateInput>
      </div>
    </Card>
    <FormulateInput
      class="submit-button"
      :class="{ 'button--disabled': unchanged }"
      type="submit"
      label="Uložit změny"
      :disabled="isSubmiting"
      ><Spinner v-if="isSubmiting" :size="24" color="#ffffff"
    /></FormulateInput>
  </FormulateForm>
</template>

<script>
import { mapGetters } from 'vuex';
import DashboardFormMixin from '@/mixins/DashboardFormMixin';
import Card from '@/components/ui/Card';
import LabelInfo from '@/components/ui/LabelInfo';
import FirstNameField from '@/components/forms/fields/FirstNameField';
import LastNameField from '@/components/forms/fields/LastNameField';
import PhoneField from '@/components/forms/fields/PhoneField';
import BirthDateFiled from '@/components/forms/fields/BirthDateField';
import NicknameField from '@/components/forms/fields/NicknameField';
import SexField from '@/components/forms/fields/SexField';
import { USER_UPDATE } from '@/store/actions/user';
import { USER_PARAMS } from '@/config';
import { serializeFormValues } from '@/helpers';

export default {
  name: 'EditUserForm',

  mixins: [DashboardFormMixin],

  components: {
    Card,
    LabelInfo,
    FirstNameField,
    LastNameField,
    PhoneField,
    BirthDateFiled,
    NicknameField,
    SexField,
  },

  data() {
    return {
      setEverywhere: false,
    };
  },

  computed: {
    ...mapGetters(['userProfile']),

    params() {
      return this.userProfile.params.reduce((acc, curr) => {
        acc[curr.name] = curr.value;
        return acc;
      }, {});
    },

    firstName() {
      return this.userProfile.firstName || '';
    },

    lastName() {
      return this.userProfile.lastName || '';
    },

    nickname() {
      return this.params?.[USER_PARAMS.nickname] || '';
    },

    sex() {
      return this.params?.[USER_PARAMS.sex] || '';
    },

    birthDate() {
      return this.params?.[USER_PARAMS.birthDate] || '';
    },

    phone() {
      return this.params?.[USER_PARAMS.phone] || '';
    },
  },

  methods: {
    onSubmit() {
      const formValues = serializeFormValues(this.formValues);
      this.handleSubmit(USER_UPDATE, formValues);
      this.setEverywhere = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

[data-classification='box'] {
  flex-wrap: nowrap;
  align-self: flex-end;
  margin-bottom: rem(12);

  @include until($breakpoint-small) {
    margin-top: rem(10);
    margin-bottom: rem(0);
    align-self: normal;
  }

  /deep/ .formulate-input-wrapper {
    align-items: center;
  }
}

/deep/ label {
  display: flex !important;
  justify-content: space-between;
}

/deep/ input {
  max-height: rem(43);
}

/deep/ select {
  appearance: none;
}

/deep/ [type='submit'] {
  min-width: rem(185);
}
</style>
