import { SESSION_GET_LIST, SESSION_REMOVE } from '@/store/actions/session';
import { API } from '@/api';

const state = {
  list: [],
};

const getters = {
  sessionList: (state) => state.list,
};

const actions = {
  [SESSION_GET_LIST]: async ({ commit, getters }) => {
    const response = await API.session.getSessionList({ token: getters.authToken });

    if (response.status === 200) {
      const data = response.data
        .map((item) => ({
          ...item,
          attributes: { ...item.attributes, device: JSON.parse(item.attributes.device) },
        }))
        .sort((prev, next) => next.attributes.created - prev.attributes.created);

      commit(SESSION_GET_LIST, data);
    }

    return response;
  },

  [SESSION_REMOVE]: async ({ commit, getters }, payload) => {
    const response = await API.session.removeSession({
      token: getters.authToken,
      removedToken: payload,
    });

    if (response.status === 200) {
      const updatedList = [...getters.sessionList].filter((session) => session.id !== payload);
      commit(SESSION_REMOVE, updatedList);
    }

    return response;
  },
};

const mutations = {
  [SESSION_GET_LIST]: (state, payload) => {
    state.list = payload;
  },

  [SESSION_REMOVE]: (state, payload) => {
    state.list = payload;
  },
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
};
