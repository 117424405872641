<template>
  <div class="notifications">
    <div class="notifications__wrapper" @mouseenter="pauseTimeout" @mouseleave="resumeTimeout">
      <transition-group name="list" tag="div">
        <div
          v-for="item in list"
          :key="item.id"
          class="notifications__item list-item"
          :class="`notifications__item--${item.type ? item.type : 'info'}`"
        >
          <div class="notifications__type"></div>
          <div class="notifications__message" v-html="item.text"></div>
          <div class="notifications__close" @click="() => closeItem(item.id)"></div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { events } from './events';
import { Id, Timer } from './utils';

const DEFAULT_DURATION = 3500;

export default {
  name: 'Notifications',

  data() {
    return {
      list: [],
      isPaused: false,
    };
  },

  mounted() {
    events.$on('add', this.addItem);
    events.$on('close', this.closeItem);
  },

  methods: {
    pauseTimeout() {
      if (!this.isPaused) {
        this.isPaused = true;
        this.list.forEach((item) => item.timerControl.pause());
      }
    },

    resumeTimeout() {
      this.isPaused = false;
      this.list.forEach((item) => item.timerControl.resume());
    },

    addItem(event) {
      const id = Id();
      const item = { ...event, id };
      const duration = item.duration || DEFAULT_DURATION;
      item.timerControl = new Timer(() => this.closeItem(id), duration, item);
      this.list.unshift(item);
    },

    closeItem(id) {
      this.list = this.list.filter((item) => item.id !== id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.notifications {
  position: fixed;
  top: rem(12);
  right: rem(12);
  z-index: 999;

  &__wrapper {
    width: rem(300);
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(5);
    background: $background-color-notify-info;
    border-radius: rem(6);
    box-shadow: $box-shadow-bottom;

    &--positive {
      background: $background-color-notify-positive;
      .notifications__type {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24.001' viewBox='0 0 24 24.001' style='&%2310;'%3E%3Cg%3E%3Cpath fill='%23fff' d='M23497-4379a11.916 11.916 0 0 1-8.486-3.516A11.918 11.918 0 0 1 23485-4391a11.918 11.918 0 0 1 3.514-8.487A11.92 11.92 0 0 1 23497-4403a11.926 11.926 0 0 1 8.484 3.514A11.919 11.919 0 0 1 23509-4391a12.014 12.014 0 0 1-12 12zm-4.254-12.219a.262.262 0 0 0-.184.077l-1.486 1.493a.265.265 0 0 0-.08.186.265.265 0 0 0 .08.186l3.346 3.345a.267.267 0 0 0 .184.077.282.282 0 0 0 .193-.077l8.125-8.13a.259.259 0 0 0 .074-.186.259.259 0 0 0-.074-.186l-1.486-1.487a.25.25 0 0 0-.189-.083.246.246 0 0 0-.184.083l-6.266 6.272a.289.289 0 0 1-.193.077.267.267 0 0 1-.184-.077l-1.486-1.493a.274.274 0 0 0-.188-.074z' transform='translate(-225.002 -465.002) translate(-23260 4868)'/%3E%3C/g%3E%3C/svg%3E");
      }
    }

    &--negative {
      background: $background-color-notify-negative;
      .notifications__type {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.993' height='23.99' viewBox='0 0 23.993 23.99' style='&%2310;'%3E%3Cpath fill='%23fff' d='M12715.063 21354.992l-8.205-.012a2 2 0 0 1-1.409-.578l-5.848-5.832a1.961 1.961 0 0 1-.595-1.42v-8.2a1.991 1.991 0 0 1 .578-1.4l5.869-5.961a2 2 0 0 1 1.42-.59h8.2a1.975 1.975 0 0 1 1.42.59l5.922 5.949a1.987 1.987 0 0 1 .584 1.41v8.2a1.994 1.994 0 0 1-.6 1.42l-5.934 5.848a2 2 0 0 1-1.402.576zm-4.571-7.869a1.5 1.5 0 0 0-1.49 1.57 1.56 1.56 0 0 0 1.565 1.506h.026a1.487 1.487 0 0 0 1.072-.471 1.505 1.505 0 0 0 .418-1.1 1.56 1.56 0 0 0-1.565-1.506zm.048-11.334a1.214 1.214 0 0 0-1.211 1.217v7.262a1.211 1.211 0 0 0 2.423 0v-7.262a1.215 1.215 0 0 0-1.212-1.217z' transform='translate(-12699.002 -21331.002)'/%3E%3C/svg%3E");
      }
    }
  }

  &__message {
    flex: 1;
    padding: rem(12) rem(0) rem(14);
    color: $color-text-light;

    /deep/ a {
      font-weight: 600;
      color: $color-text-light;
    }
  }

  &__type {
    width: rem(70);
    height: rem(22);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' style='&%2310;'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M431 2041a12 12 0 1 0 12 12 12 12 0 0 0-12-12zm.25 5a1.5 1.5 0 1 1-1.5 1.5 1.5 1.5 0 0 1 1.5-1.5zm-1.75 13.5h4a1 1 0 0 0 0-2h-.75a.249.249 0 0 1-.25-.25v-4.75a2 2 0 0 0-2-2h-1a1 1 0 0 0 0 2h.75a.249.249 0 0 1 .25.25v4.5a.249.249 0 0 1-.25.25h-.75a1 1 0 0 0 0 2z' transform='translate(-419 -2041)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  &__close {
    position: relative;
    width: rem(40);
    height: rem(40);
    cursor: pointer;
    align-self: flex-start;

    &::before {
      content: '';
      position: absolute;
      top: rem(21);
      right: rem(14);
      width: rem(16);
      height: rem(2);
      background-color: white;
      border-radius: rem(2);
      transform: rotate(45deg);
    }

    &::after {
      content: '';
      position: absolute;
      top: rem(21);
      right: rem(14);
      width: rem(16);
      height: rem(2);
      background-color: white;
      border-radius: rem(2);
      transform: rotate(-45deg);
    }
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(20px);
}
</style>
