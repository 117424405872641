<template>
  <div class="auth-layout" :class="{ 'is-brand': service }">
    <HeaderDefault>
      <template v-slot:header-before>
        <div v-if="service" class="header__brand">
          <a :href="redirectUrl">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
              <g data-name="Group 1">
                <path data-name="Rectangle 1" fill="none" d="M0 0h21v21H0z" />
                <path
                  data-name="Path 1"
                  d="M19.458 9.375H3.78l4.289-4.287a.965.965 0 00-1.36-1.36L.781 9.657a.965.965 0 000 1.36l5.928 5.928a.965.965 0 001.36-1.36L3.78 11.299h15.678a.965.965 0 100-1.924z"
                />
              </g>
            </svg>
          </a>
          <img :src="service.attributes.logoUrl" />
        </div>
      </template>
    </HeaderDefault>
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  AUTH_SET_SERVICE_ID,
  AUTH_SET_REDIRECT_URL,
  AUTH_SET_IS_MOBILE_APP,
} from '@/store/actions/auth';
import { SERVICE_GET_LIST } from '@/store/actions/service';
import { SERVICE_TYPES } from '@/config';

import HeaderDefault from '@/components/layouts/header/HeaderDefault';

export default {
  name: 'AuthLayout',

  components: {
    HeaderDefault,
  },

  computed: {
    ...mapGetters(['serviceId', 'serviceList', 'redirectUrl']),

    service() {
      const service = this.serviceList.find((service) => service.id === this.serviceId);

      if (service) {
        this.$store.dispatch(
          AUTH_SET_IS_MOBILE_APP,
          service.attributes.type === SERVICE_TYPES.mobile_app
        );
      }
      return service;
    },
  },

  mounted() {
    const serviceId = this.$route.query?.sid;
    const redirectUrl = this.$route.query?.redirectUrl;

    if (serviceId && redirectUrl) {
      const { origin, pathname } = window.location;
      this.$store.dispatch(AUTH_SET_SERVICE_ID, serviceId);
      this.$store.dispatch(AUTH_SET_REDIRECT_URL, redirectUrl);
      window.history.replaceState({}, document.title, `${origin}${pathname}`);
    }

    this.$store.dispatch(SERVICE_GET_LIST);
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.auth-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: rem(70);
    box-shadow: $box-shadow-bottom;
    z-index: 2;

    @include until($breakpoint-small) {
      height: rem(60);
    }
  }

  /deep/ .header__brand {
    position: absolute;
    display: flex;
    left: rem(30);

    a {
      display: flex;
      align-items: center;
      margin-right: rem(32);
    }

    svg {
      width: rem(24);
      height: rem(24);
      transform: fill 0.15s;

      &:hover {
        fill: $color-text-dark-blue;
      }
    }

    img {
      height: rem(24);
    }
  }

  /deep/ .header__logo {
    img {
      height: rem(38);
      margin-bottom: rem(7);
    }
  }

  main {
    flex: 1;
    background-color: $background-color-gray;
  }

  &.is-brand {
    @include until($breakpoint-small) {
      header {
        display: flex;
        flex-direction: column;
        height: rem(104);
        box-shadow: none;

        /deep/ .header__logo {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: rem(44);
          background-color: $background-color-light-blue;

          img {
            display: block;
            height: rem(24);
            margin-top: rem(5);
          }
        }

        /deep/ .header__brand {
          position: relative;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: rem(60);
          left: 0;
          box-shadow: $box-shadow-bottom;

          a {
            position: absolute;
            left: rem(20);
          }
        }
      }
    }
  }
}

/deep/ .page-form {
  position: relative;
  max-width: rem(404);
  z-index: 1;

  @include until($breakpoint-small) {
    padding: 0;
  }

  &__social-connect {
    margin-bottom: rem(30);
  }

  &__divider {
    position: relative;
    margin: rem(30) 0;
    color: $color-text-gray;
    font-size: rem(12);
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: rem(2.4);

    &::before,
    &::after {
      content: '';
      position: absolute;
      height: rem(1);
      width: 6%;
      top: 50%;
      background-color: $color-text-gray;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  &__title {
    font-size: rem(24);
    margin-bottom: rem(30);
    line-height: 1.5;
    letter-spacing: rem(0.5);

    @include until($breakpoint-medium) {
      text-align: center;
    }
  }

  &__icon {
    padding-top: rem(12);
    margin: 0 auto rem(24);
  }

  &__content {
    [data-type='submit'] {
      margin-top: rem(30);
    }
  }

  &__conditions {
    margin-top: rem(20);
    line-height: rem(20);
    color: $color-text-gray;

    a {
      color: $color-text-gray;
      text-decoration: underline;
    }

    > div {
      margin-bottom: rem(20);
    }
    [data-type='checkbox'] {
      margin-top: rem(2);
    }

    [data-classification='group'] {
      > .formulate-input-wrapper {
        margin-left: rem(26);
      }

      .formulate-input-label--before {
        display: block;
        margin-bottom: rem(5);
      }

      .formulate-input-label--after {
        margin-right: rem(20);
      }

      .formulate-input-element {
        display: flex;
      }
    }
  }

  &__description {
    color: $color-text-gray;
    line-height: rem(24);

    a {
      font-weight: 600;
    }
  }

  &__description-highlight {
    font-weight: 600;
    color: $color-text-dark-blue;
  }

  &__incentive-link,
  &__trouble-link {
    margin-top: rem(48);
    font-size: rem(14);
    font-weight: 600;

    @include until($breakpoint-medium) {
      text-align: center;
    }

    a {
      color: $color-text-dark-blue;
    }
  }

  &__trouble-link {
    font-size: rem(12);
    color: $color-text-gray;
  }

  &__footer {
    padding: rem(34) 0 0;

    @include until($breakpoint-medium) {
      text-align: center;
    }
  }
}
</style>
