<template>
  <div v-if="!serverError">
    <div class="dashboard-layout" v-if="isUserProfile">
      <Header />

      <main>
        <div class="page-content">
          <router-view />
        </div>
      </main>

      <Footer />

      <notifications />
    </div>
  </div>

  <ServerErrorPage v-else />
</template>

<script>
import { mapGetters } from 'vuex';
import { USER_GET_REQUEST } from '@/store/actions/user';
import Header from '@/components/layouts/header/Header';
import Footer from '@/components/layouts/Footer';
import ServerErrorPage from '@/pages/dashboard/ServerErrorPage';
import { Axios } from '@/utils';

export default {
  name: 'DashboardLayout',

  components: {
    Header,
    Footer,
    ServerErrorPage,
  },

  data() {
    return {
      serverError: false,
    };
  },

  computed: {
    ...mapGetters(['isUserProfile']),
  },

  created() {
    Axios.interceptors.response.use(
      (res) => {
        return res;
      },
      (err) => {
        if (err.response.status <= 500) {
          this.serverError = true;
        }
        throw err;
      }
    );

    if (!this.isUserProfile) {
      this.$store.dispatch(USER_GET_REQUEST);
    }

    this.$store.watch(
      (state, getters) => getters.isAuthenticated,
      (isAuthenticated) => {
        if (!isAuthenticated) {
          this.$router.push({ name: 'Login' }).catch(() => {});
        }
      }
    );
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.dashboard-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $background-color-gray;
}

main {
  flex: 1;
  padding: rem(48) rem(30) 0;

  @include until($breakpoint-small) {
    width: 100%;
    padding: rem(36) rem(20) 0;
  }
}

.page-content {
  width: 100%;
  max-width: $page-content;
  margin: 0 auto;
}

/deep/ .page-title {
  font-size: rem(24);
  line-height: 1.3;
}
</style>
