<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isAuthenticated', 'isUserProfile', 'userProfile']),
  },

  created() {
    window.didomiOnReady = window.didomiOnReady || [];
    window.didomiOnReady.push(function () {
      window.gtmService = new window.GTMService();
    });

    // Exception for SetCookies page
    if (this.$route.name !== null) {
      this.trackApp();

      if (!this.isAuthenticated) {
        this.trackAnonymUser();
        this.trackPage();
      }
    }
  },

  updated() {
    // Exception for SetCookies page
    if (this.$route.name !== 'SetCookies') {
      this.trackApp();

      if (!this.isAuthenticated) {
        this.trackAnonymUser();
        this.trackPage();
        return;
      }

      this.trackUser();
    }
  },

  watch: {
    isUserProfile() {
      this.trackUser();
    },
  },

  methods: {
    trackApp() {
      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push(function () {
        window.gtmService.push({
          app: {
            service: {
              entity: 'cnc',
              name: 'sso',
              version: '1.0.0',
              environment: 'responsive',
            },
          },
        });

        window.gtmService.push({
          page: { type: 'sso' },
        });
      });
    },

    trackPage() {
      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push(function () {
        window.gtmService.push({ event: 'page_body' });
        window.gtmService.push({ event: 'page_ready' });
      });
    },

    trackUser() {
      if (this.isUserProfile) {
        window.didomiOnReady = window.didomiOnReady || [];
        window.didomiOnReady.push(function () {
          window.gtmService.push({
            user: {
              loginStatus: 'loggedIn',
              uid: this.userProfile.userId,
              internal: Boolean(this.userProfile.email.includes('cncenter.cz')),
            },
          });
        });

        this.trackPage();
      }
    },

    trackAnonymUser() {
      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push(function () {
        window.gtmService.push({
          user: {
            loginStatus: 'anonymous',
          },
        });
      });
    },
  },
};
</script>
