import {
  USER_GET_SUCCESS,
  USER_GET_REQUEST,
  USER_UPDATE,
  USER_CHANGE_AVATAR,
  USER_REMOVE_AVATAR,
  USER_GET_LOGIN_TYPES,
  USER_GET_SERVICE_PARAMS,
  USER_CHANGE_SERVICE_PARAMS,
  USER_CHANGE_SERVICE_AVATAR,
  USER_REMOVE_SERVICE_AVATAR,
  USER_GET_SERVICE_REQUIRED_PARAMS,
  USER_SET_SERVICE_PARAMS,
} from '@/store/actions/user';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import { API } from '@/api';
import { USER_PARAMS } from '@/config';
import { mergeDeep, isEmpty } from '@/helpers';

const state = {
  profile: {},
  loginTypes: [],
  serviceParams: [],
  hasServiceParams: false,
  requiredServiceParams: [],
  hasRequiredServiceParams: false,
};

const getters = {
  isUserProfile: (state) => !isEmpty(state.profile),
  userProfile: (state) => state.profile,
  userLoginTypes: (state) => state.loginTypes,
  userServiceParams: (state) => state.serviceParams,
  hasUserServiceParams: (state) => state.hasServiceParams,
  userRequiredServiceParams: (state) => state.requiredServiceParams,
  hasUserRequiredServiceParams: (state) => state.hasRequiredServiceParams,
};

const actions = {
  [USER_GET_REQUEST]: async ({ commit, getters }) => {
    if (!getters.isAuthenticated) return;

    const response = await API.user.get({ token: getters.authToken });

    if (response.status === 200) {
      commit(USER_GET_SUCCESS, {
        ...{ userId: response.data.data.id },
        ...response.data.data.attributes,
      });
    }

    return response;
  },

  [USER_UPDATE]: async ({ commit, getters }, payload) => {
    if (!getters.isAuthenticated) return;

    const response = await API.user.update({ token: getters.authToken, payload });

    if (response.status === 200) {
      commit(USER_UPDATE, response.data.data.attributes);
    }

    return response;
  },

  [USER_CHANGE_AVATAR]: async ({ commit, getters }, payload) => {
    if (!getters.isAuthenticated) return;

    const response = await API.user.changeAvatar({ token: getters.authToken, payload });

    if (response.status === 200) {
      commit(USER_CHANGE_AVATAR, response.data.data.attributes);
    }

    return response;
  },

  [USER_REMOVE_AVATAR]: async ({ commit, getters }) => {
    if (!getters.isAuthenticated) return;

    // Do not wait for a response from the API, the change will take effect immediately.
    commit(USER_REMOVE_AVATAR);

    const response = await API.user.removeAvatar({ token: getters.authToken });
    return response;
  },

  [USER_GET_LOGIN_TYPES]: async ({ commit, getters }) => {
    if (!getters.isAuthenticated) return;

    const response = await API.user.loginTypes({ token: getters.authToken });

    if (response.status === 200) {
      commit(USER_GET_LOGIN_TYPES, response.data);
    }

    return response;
  },

  [USER_GET_SERVICE_PARAMS]: async ({ commit, getters }) => {
    if (!getters.isAuthenticated) return;

    const response = await API.user.getAllServiceParams({ token: getters.authToken });

    if (response.status === 200) {
      const data = response.data.map((service) => service.attributes);
      commit(USER_GET_SERVICE_PARAMS, data);
    }

    return response;
  },

  [USER_CHANGE_SERVICE_AVATAR]: async ({ commit, getters }, payload) => {
    if (!getters.isAuthenticated) return;

    const response = await API.user.changeServiceAvatar({ token: getters.authToken, payload });

    if (response.status === 200) {
      commit(USER_CHANGE_SERVICE_PARAMS, {
        serviceId: payload.serviceId,
        params: {
          [USER_PARAMS.avatar]: response.data.data.attributes.url,
        },
      });
    }

    return response;
  },

  [USER_REMOVE_SERVICE_AVATAR]: async ({ commit, getters }, payload) => {
    if (!getters.isAuthenticated) return;

    // Do not wait for a response from the API, the change will take effect immediately.
    commit(USER_CHANGE_SERVICE_PARAMS, {
      serviceId: payload.serviceId,
      params: {
        [USER_PARAMS.avatar]: null,
      },
    });

    const response = await API.user.removeServiceAvatar({
      token: getters.authToken,
      serviceId: payload.serviceId,
    });

    return response;
  },

  [USER_GET_SERVICE_REQUIRED_PARAMS]: async ({ commit }) => {
    const response = await API.user.getRequiredServiceParams();

    if (response.status === 200) {
      const requiredParams = response.data.map((service) => service.attributes);
      commit(USER_GET_SERVICE_REQUIRED_PARAMS, requiredParams);
    }

    return response;
  },

  [USER_SET_SERVICE_PARAMS]: async ({ commit, getters }, payload) => {
    if (!getters.isAuthenticated) return;

    const response = await API.user.setServiceParams({ token: getters.authToken, payload });

    if (response.status === 200) {
      commit(USER_CHANGE_SERVICE_PARAMS, {
        serviceId: payload.serviceId,
        params: payload.values,
      });
    }

    return response;
  },
};

const mutations = {
  [USER_GET_SUCCESS]: (state, profile) => {
    state.profile = profile;
  },

  [USER_UPDATE]: (state, payload) => {
    state.profile = { ...state.profile, ...payload };
  },

  [USER_CHANGE_AVATAR]: (state, payload) => {
    const avatarUrl = payload.url;
    state.profile = { ...state.profile, ...{ avatarUrl } };
  },

  [USER_REMOVE_AVATAR]: (state) => {
    const avatarUrl = '';
    state.profile = { ...state.profile, ...{ avatarUrl } };
  },

  [USER_GET_LOGIN_TYPES]: (state, payload) => {
    state.loginTypes = payload;
  },

  [USER_GET_SERVICE_PARAMS]: (state, payload) => {
    state.serviceParams = payload;
    state.hasServiceParams = true;
  },

  [USER_CHANGE_SERVICE_PARAMS]: (state, payload) => {
    const clonedParams = state.serviceParams ? [...state.serviceParams] : [];
    // Check if service params exist...
    const index = clonedParams.findIndex(({ serviceId }) => serviceId === payload.serviceId);

    if (index !== -1) {
      // If it exists, merge it...
      clonedParams[index] = mergeDeep(clonedParams[index], payload);

      const isParamsEmpty = !Object.values(clonedParams[index].params).some(
        (param) => !isEmpty(param)
      );

      // If the params are empty, delete the service ...
      if (isParamsEmpty) clonedParams.splice(index, 1);
    } else {
      // Otherwise, create a new service...
      clonedParams.push(payload);
    }

    state.serviceParams = clonedParams;
  },

  [USER_GET_SERVICE_REQUIRED_PARAMS]: (state, payload) => {
    state.requiredServiceParams = payload;
    state.hasRequiredServiceParams = true;
  },

  [AUTH_LOGOUT]: (state) => {
    state.profile = {};
  },
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
};
