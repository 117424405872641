<template>
  <div class="cancel-layout">
    <div v-if="success === null" class="cancel-layout__content">
      <CircleLoadingBar :size="160" />
    </div>

    <div v-if="success === true" class="cancel-layout__content">
      <div class="cancel-layout__icon">
        <img :src="require('@/assets/svg/sad-smile.svg')" alt="Chyba" />
      </div>
      <h1 class="cancel-layout__title">Váš účet byl zrušen</h1>
      <div class="cancel-layout__description">
        Pro návrat na přihlášení
        <router-link :to="{ name: 'Login' }">klikněte zde</router-link>
      </div>
    </div>

    <div v-if="success !== null && !success" class="cancel-layout__content">
      <div class="cancel-layout__icon">
        <img :src="require('@/assets/svg/sad-smile.svg')" alt="Chyba" />
      </div>
      <h1 class="cancel-layout__title">Účet se nepodařilo zrušit</h1>
      <div class="cancel-layout__description">
        Zkuste to prosím znovu. Pro návrat na přihlášení
        <router-link :to="{ name: 'Login' }">klikněte zde</router-link>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import { API } from '@/api';

import CircleLoadingBar from '@/components/ui/CircleLoadingBar';
import Footer from '@/components/layouts/Footer';

export default {
  name: 'CancelProfilePage',

  components: { Footer, CircleLoadingBar },

  data() {
    return {
      success: null,
    };
  },

  async mounted() {
    const response = await API.user.cancelProfile({ token: this.$route.params.id });

    if (response.status === 200) {
      this.success = true;
    } else {
      this.success = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.cancel-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 70px);

  @include until($breakpoint-small) {
    height: calc(100vh - 60px);
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 rem(22);
  }

  &__icon {
    margin-bottom: rem(32);
  }

  &__title {
    font-size: rem(48);
    line-height: rem(30);
    margin-bottom: rem(32);

    @include until($breakpoint-small) {
      font-size: rem(24);
      line-height: rem(30);
      margin-bottom: rem(24);
    }
  }

  &__description {
    color: $color-text-gray;
    font-size: rem(16);
    font-weight: 600;

    @include until($breakpoint-small) {
      font-size: rem(14);
    }
  }
}
</style>
