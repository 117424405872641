import axios from 'axios';

export const instance = axios.create({
  headers: {
    'Content-type': 'application/vnd.api+json',
  },
});

export default {
  /**
   * @param {String} url
   * @param {Object} [config]
   * @return {promise}
   */
  get: (url, config = {}) => {
    return instance.get(url, config);
  },

  /**
   * @param {String} url
   * @param {Object} [data]
   * @param {Object} [config]
   * @return {promise}
   */
  post: (url, data = {}, config = {}) => {
    return instance.post(url, data, config);
  },

  /**
   * @param {String} url
   * @param {Object} [config]
   * @return {promise}
   */
  delete: (url, config = {}) => {
    return instance.delete(url, config);
  },
};
