<template>
  <Dropdown class="user-menu" @dropdown:toggle="trackMenu">
    <div slot="label" class="user-menu__label navbar__link">
      <Avatar :url="userProfile.avatarUrl" />
    </div>

    <div slot="content" class="user-menu__content">
      <div class="user-menu__content-header">
        <Avatar :url="userProfile.avatarUrl" />
        <Username />
      </div>
      <router-link
        :to="{ name: 'MyProfile' }"
        class="user-menu__link"
        data-track-click='{"event": "clickTo_navigation", "menu": { "type": "menu", "section": "Můj profil" }}'
        >Upravit profil</router-link
      >
      <a class="user-menu__link" @click="logout">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.201 12.533">
          <g transform="translate(-1274.4 -117.326)">
            <path d="M351.755 487h8.4" transform="translate(923.245 -363.402)" />
            <path d="M363.755 487.255l2-2-2-2" transform="translate(917.646 -361.652)" />
            <path
              d="M354.311 484.556a5.6 5.6 0 10.1-5.09"
              transform="translate(922.053 -358.504)"
            />
          </g>
        </svg>
        Odhlasit se</a
      >
      <div class="user-menu__content-footer">
        Přihlášený přes <img :src="require('@/assets/svg/brand-logo.svg')" alt="Brand logo" />
      </div>
    </div>
  </Dropdown>
</template>

<script>
import { mapGetters } from 'vuex';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import Dropdown from '@/components/ui/Dropdown';
import Avatar from '@/components/ui/Avatar';
import Username from '@/components/ui/UserName';

export default {
  name: 'UserMenu',

  components: {
    Dropdown,
    Avatar,
    Username,
  },

  computed: {
    ...mapGetters(['userProfile']),
  },

  methods: {
    logout() {
      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push(function () {
        window.gtmService.push({
          event: 'user_logout_start',
        });
      });

      this.$store.dispatch(AUTH_LOGOUT, true);

      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push(function () {
        window.gtmService.push({
          event: 'user_logout_success',
        });
      });
    },

    trackMenu(isOpen) {
      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push(function () {
        window.gtmService.push({
          event: 'clickTo_navigation',
          menu: {
            type: 'menu',
          },
          link: {
            text: isOpen ? 'open' : 'close',
          },
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';
.user-menu {
  height: 100%;

  @include until($breakpoint-small) {
    position: static;
  }

  .avatar {
    width: rem(34);
    height: rem(34);

    @include until($breakpoint-small) {
      width: rem(32);
      height: rem(32);
    }
  }

  &__label {
    @include until($breakpoint-small) {
      min-width: auto;
    }

    /deep/ .avatar {
      border: rem(2) solid $background-color-light-blue;
    }

    span {
      flex: 1;
      margin-left: rem(10);
      font-weight: 600;
      color: $color-text-dark-blue;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include until($breakpoint-small) {
        display: none;
      }
    }
  }

  .active .user-menu__label {
    background-color: $background-color-light-blue;
  }

  &__content-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: rem(31) rem(10);
    border-bottom: rem(1) solid $border-color-light-blue;

    /deep/ .avatar {
      width: rem(49);
      height: rem(49);
      margin-right: rem(21);

      @include until($breakpoint-small) {
        width: rem(58);
        height: rem(58);
      }
    }

    span {
      font-size: rem(16);
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 60%;
    }
  }

  &__content-footer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    padding: rem(83) rem(10) rem(24);
    color: $color-text-light-blue;
    font-weight: 600;

    @include until($breakpoint-small) {
      padding: rem(63) rem(10) rem(24);
    }

    img {
      height: rem(21.5);
      margin: 0 0 rem(1) rem(8);
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $background-color-light;
    border: rem(1) solid $border-color-light-blue;

    &::after {
      content: '';
      position: absolute;
      top: rem(-8);
      right: rem(20);
      width: rem(16);
      height: rem(16);
      background-color: $background-color-light;
      transform: rotate(45deg);
      border-left: rem(1) solid $border-color-light-blue;
      border-top: rem(1) solid $border-color-light-blue;

      @include until($breakpoint-small) {
        display: none;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: rem(10);
    color: $color-text-dark-blue;
    font-size: rem(16);
    font-weight: 600;
    border-bottom: rem(1) solid $border-color-light-blue;
    text-decoration: none;

    @include until($breakpoint-small) {
      padding: rem(16) rem(32);
    }

    &:hover {
      color: $background-color-dark-blue;
      background-color: $background-color-light-blue;

      svg {
        stroke: $background-color-dark-blue;
      }
    }

    svg {
      width: rem(16);
      height: rem(16);
      margin-right: rem(8);
      fill: none;
      stroke: $color-text-dark-blue;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: rem(1.4);
    }
  }
}

/deep/ .dropdown__content {
  min-width: rem(296);
  top: rem(90);
  right: rem(20);

  @include until($breakpoint-small) {
    position: absolute;
    width: 100%;
    top: rem(70);
    bottom: 0;
    left: 0;
  }
}
</style>
