export default {
  rules: {
    alphaExtended: ({ value }) => {
      const val = value.trim();
      return val === '' || /^[a-zA-Zá-žÁ-Ž]+$/g.test(val);
    },

    alphaExtendedSpecial: ({ value }) => {
      const val = value.trim();
      return val === '' || /^[a-zA-Zá-žÁ-Ž-0-9-.]+( [a-zA-Zá-žÁ-Ž-0-9-.]+)*$/g.test(val);
    },

    phoneCode: ({ value }) => {
      const val = value.trim();
      return val === '' || /^\+[0-9]{1,3}$/g.test(val);
    },

    phoneNumber: ({ value }) => {
      const val = value.trim();
      return val === '' || /^[0-9]{5,10}$/g.test(val.trim());
    },

    emptyOrMin: ({ value }, length) => {
      const val = value.trim();
      return val === '' || val.trim().length >= length;
    },
  },
};
