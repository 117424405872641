<template>
  <div class="pagination">
    <router-link
      class="pagination__item pagination__item-arrow pagination__first"
      v-if="hasFirst"
      :to="{ query: { page: prevPage() } }"
    ></router-link>
    <span class="pagination__dots" v-if="hasFirst">...</span>
    <div v-for="page in pages" :key="page">
      <router-link
        class="pagination__item"
        :class="{ 'pagination__item--current': currentPage === page }"
        :to="{ query: { page: page } }"
        >{{ page }}</router-link
      >
    </div>
    <span class="pagination__dots" v-if="hasLast">...</span>
    <router-link
      class="pagination__item pagination__item-arrow pagination__last"
      v-if="hasLast"
      :to="{ query: { page: nextPage() } }"
    ></router-link>
  </div>
</template>

<script>
import { BREAKPOINT_MOBILE } from '@/config';

const PAGE_RANGE = 3;

export default {
  name: 'Pagination',

  data() {
    return {
      pageRange: PAGE_RANGE,
    };
  },

  props: {
    total: {
      type: Number,
    },

    perPage: {
      type: Number,
    },

    currentPage: {
      type: Number,
    },
  },

  computed: {
    pages() {
      const pages = [];
      for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i);
      }
      return pages;
    },

    totalPages() {
      return Math.ceil(this.total / this.perPage);
    },

    rangeStart() {
      const start = this.currentPage - this.pageRange;
      return start > 0 ? start : 1;
    },

    rangeEnd() {
      const end = this.currentPage + this.pageRange;
      return end < this.totalPages ? end : this.totalPages;
    },

    hasFirst() {
      return this.rangeStart !== 1;
    },

    hasLast() {
      return this.rangeEnd < this.totalPages;
    },
  },

  mounted() {
    this.setPageRange();
    window.addEventListener('resize', this.setPageRange);
  },

  methods: {
    nextPage() {
      return this.currentPage + 1;
    },

    prevPage() {
      return this.currentPage - 1;
    },

    setPageRange() {
      if (window.innerWidth < BREAKPOINT_MOBILE) {
        this.pageRange = 1;
      } else {
        this.pageRange = PAGE_RANGE;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(48);
    height: rem(48);
    font-size: rem(16);
    font-weight: 600;
    color: $color-text-dark;
    text-decoration: none;

    &:hover {
      background-color: $background-color-light-blue;
    }
  }

  &__item--current {
    color: $color-text-light;
    background-color: $background-color-dark-blue !important;
    pointer-events: none;
  }

  &__item-arrow {
    background-image: url('~@/assets/svg/arrow-pagination.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 35%;
  }

  &__first {
    transform: rotate(180deg);
  }

  &__dots {
    padding-top: rem(10);
  }
}
</style>
