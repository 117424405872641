const isDev = process.env.NODE_ENV === 'development';
const isSocialConnectDev =
  isDev || (!isDev && process.env.VUE_APP_SOCIAL_CONNECT === 'development');

// Base
export const REDIRECT_DELAY = 5000;
export const COOKIE_OPTIONS = { expires: 14, path: '/' }; // expire in 14 days
export const COOKIE_MOBILE_EXPIRES = 365; // 365 days

// User
export const USER_PARAMS = {
  nickname: ':NICKNAME',
  avatar: ':AVATAR',
  motto: ':MOTTO',
  phone: ':PHONE',
  sex: ':SEX',
  birthDate: ':BIRTH_DATE',
  address: ':ADDRESS',
  underAge: ':UNDER_AGE_OF_16',
  parentEmail: ':PARENT_EMAIL',
};

// Social Connect
export const FACEBOOK_APP_ID = isSocialConnectDev ? '465445334880665' : '448550856212738';
export const GOOGLE_CLIENT_ID = isSocialConnectDev
  ? '724554886791-je9fimt4ff0qifimv3m8je8dmbu5pl8m'
  : '724554886791-bj95me88ocahbd6ok3b1cmhu5l5pp7uc';
export const APPLE_CLIENT_ID = 'cz.cncenter.sso';

// Login types
export const LOGIN_TYPES = {
  password: 1,
  facebook: 2,
  google: 3,
  apple: 4,
};

// Devices
export const SERVICE_TYPES = {
  website: 1,
  mobile_app: 2,
  internal_service: 3,
};

// Cookies
export const CIRCUIT_HEALTZ_TIMEOUT = 1000;

// Breakpoints
export const BREAKPOINT_MOBILE = 768;
