export default (list = [], params = [], requiredParams = []) => {
  return list.map(({ id, attributes }) => {
    return {
      id,
      ...attributes,
      params: requiredParams
        .filter(({ serviceId }) => serviceId === id)
        .reduce((acc, curr) => {
          let hasParent = false;

          if (attributes.parentServiceId) {
            hasParent = requiredParams
              .filter(({ serviceId }) => serviceId === attributes.parentServiceId)
              .some(({ param }) => param === curr.param);
          }

          const paramValue = params.find(({ serviceId }) => serviceId === id)?.params?.[curr.param];
          const parentValue = params.find(
            ({ serviceId }) => serviceId === attributes.parentServiceId
          )?.params?.[curr.param];

          acc.push({
            name: curr.param,
            value: parentValue || paramValue || null,
            editable: !hasParent,
          });

          return acc;
        }, []),
    };
  });
};
