<template>
  <div>
    <div class="my-profile__cancel">
      <h3>Zrušení účtu</h3>
      <p>
        Spolu se zrušením učtu anonymizujeme vámi vložené příspěvky, smažeme nepovinné osobní údaje.
      </p>
      <button class="button--inline button--small button--bordered" @click="cancleProfile">
        Zrušit účet
      </button>
    </div>

    <Popup :visibility="isPrompt" @popupClose="closePrompt">
      <div v-if="cancelConfirmed" class="my-profile__prompt">
        <div class="my-profile__prompt-icon">
          <img :src="require('@/assets/svg/success.svg')" alt="Úspěch'" />
        </div>
        <div class="my-profile__prompt-title">
          <h1>Potvrzovací e-mail byl zaslán</h1>
        </div>
        <div class="my-profile__prompt-text mb-0">
          Na vaši e-mailovou adresu byl zaslán potvrzovací e-mail, ve kterém je třeba zrušení účtu
          potvrdit.
        </div>
      </div>

      <div v-else class="my-profile__prompt">
        <div class="my-profile__prompt-icon">
          <img :src="require('@/assets/svg/alert.svg')" alt="Upozornění" />
        </div>
        <div class="my-profile__prompt-title">
          <h1>Opravdu chcete účet zrušit?</h1>
        </div>
        <div class="my-profile__prompt-text">
          Po kliknutí na tlačítko "zrušit účet" bude na vaši e-mailovou adresu zaslán potvrzovací
          e-mail, ve kterém je třeba zrušení účtu potvrdit.
        </div>
        <button @click="cancleConfirmation">Zrušit účet</button>
        <a class="my-profile__prompt-close-link" @click="closePrompt">Zavřít</a>
      </div>
    </Popup>
  </div>
</template>

<script>
import BaseFormMixin from '@/mixins/BaseFormMixin';
import { mapGetters } from 'vuex';
import { API } from '@/api';
import Popup from '@/components/ui/Popup';

export default {
  name: 'CancleProfileForm',

  components: {
    Popup,
  },

  mixins: [BaseFormMixin],

  data() {
    return {
      isPrompt: false,
      cancelConfirmed: false,
    };
  },

  computed: {
    ...mapGetters(['authToken']),
  },

  methods: {
    cancleProfile() {
      this.isPrompt = true;
    },

    async cancleConfirmation() {
      const response = await API.auth.sendCancelEmail({
        token: this.authToken,
        serviceId: this.serviceId,
        redirectUrl: this.redirectUrl,
      });

      if (response.status === 200) {
        this.cancelConfirmed = true;
      } else {
        this.isPrompt = false;
        this.$notify({
          type: 'negative',
          text: response.message,
        });
      }
    },

    closePrompt() {
      this.isPrompt = false;
      this.cancelConfirmed = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';
.my-profile {
  &__cancel {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: rem(48) 0 rem(16);

    h3 {
      font-family: 'Open Sans';
      font-size: rem(16);
      font-weight: 600;
      line-height: rem(30);
    }

    p {
      color: $color-text-gray;
      margin-bottom: rem(18);
    }
  }

  &__prompt {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__prompt-icon {
    margin-bottom: rem(26);

    img {
      height: rem(76);
    }
  }

  &__prompt-text {
    margin-bottom: rem(32);
    font-size: rem(14);
    line-height: rem(24);
    color: $color-text-gray;
    text-align: center;

    &.mb-0 {
      margin-bottom: 0;
    }
  }

  &__prompt-close-link {
    margin-top: rem(32);
  }
}

/deep/ .popup[disabled] {
  .popup__close-icon {
    display: none;
  }
}
</style>
