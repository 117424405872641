<template>
  <div>
    <PageWrapper v-if="!success" :header="false" :footer="true" class="page-form--reset-password">
      <h1 class="page-form__title">Resetování hesla</h1>
      <div class="page-form__description">
        Heslo bylo restartováno. Nyní si zvolte nové heslo a pro jistotu ho opakujte.
      </div>
      <ResetPasswordForm @submitSuccess="onSubmitSuccess" />
    </PageWrapper>

    <PageWrapper v-else :header="false" class="page-form--reset-password-success">
      <div class="page-form__icon">
        <img :src="require('@/assets/svg/success.svg')" alt="Nastaveno" />
      </div>
      <h1 class="page-form__title">Nové heslo bylo nastaveno</h1>
      <div class="page-form__description">
        Budete přesměrováni na přihlášení, pokud automaticky neproběhne
        <router-link :to="{ name: 'Login' }">klikněte zde</router-link>
      </div>
    </PageWrapper>
  </div>
</template>

<script>
import PageWrapper from '@/pages/wrappers/PageWrapper';
import ResetPasswordForm from '@/components/forms/ResetPasswordForm';
import { REDIRECT_DELAY } from '@/config';

export default {
  name: 'ResetPasswordPage',

  components: { PageWrapper, ResetPasswordForm },

  data() {
    return {
      success: false,
    };
  },

  methods: {
    onSubmitSuccess() {
      this.success = true;

      setTimeout(() => {
        this.$router.push({ name: 'Login' });
      }, REDIRECT_DELAY);
      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push(function () {
        window.gtmService.push({
          event: 'user_renewPassword_success',
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/abstracts/abstracts';

[v-cloak] {
  display: none;
}

.page-form--reset-password {
  .page-form {
    &__description {
      padding-bottom: rem(24);
    }

    &__footer {
      padding-top: rem(50);
    }
  }
}

.page-form--reset-password-success {
  .page-form {
    &__icon {
      max-width: rem(200);
    }

    &__description {
      padding-bottom: rem(24);
    }
  }
}
</style>
