import { makeRequest } from '@/utils';
import { SESSION_LIST, SESSION_REMOVE, API_UNI_ERROR } from './constants';

export default {
  /**
   * @param {Object} payload
   * @param {String} payload.token
   */
  getSessionList: async ({ token }) => {
    try {
      const response = await makeRequest.get(SESSION_LIST, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });

      return {
        status: response.status,
        data: response.data.data,
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: API_UNI_ERROR,
      };
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.token
   * @param {String} payload.removedToken
   */
  removeSession: async ({ token, removedToken }) => {
    try {
      const response = await makeRequest.delete(SESSION_REMOVE, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        data: {
          token: removedToken,
        },
      });

      return {
        status: response.status,
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: API_UNI_ERROR,
      };
    }
  },
};
