/**
 * @param {String} paramString String example foo=bar
 * @param {String} target StringUrl
 * @returns {String} Modified StringUrl
 */
export default (paramString, target = window.location.href) => {
  let url = target;
  url += (url.split('?')[1] ? '&' : '?') + paramString;
  return url;
};
