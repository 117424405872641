<template>
  <div class="auth-layout__main">
    <div class="auth-layout__cover"></div>
    <div class="auth-layout__content">
      <div class="auth-layout__wrapper">
        <div class="auth-layout__inner">
          <div class="page-form">
            <div class="page-form__header" v-if="header">
              <div class="page-form__logo"></div>
            </div>

            <div class="page-form__content">
              <slot />
            </div>

            <div class="page-form__footer" v-if="footer">
              <Copyright />
            </div>
          </div>
        </div>

        <PromoServiceList />
      </div>
    </div>
  </div>
</template>

<script>
import Copyright from '@/components/layouts/Copyright';
import PromoServiceList from '@/components/layouts/PromoServiceList';

export default {
  name: 'PageWrapper',

  components: { Copyright, PromoServiceList },

  props: {
    header: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.auth-layout.is-brand {
  @include until($breakpoint-small) {
    .auth-layout__main,
    .auth-layout__content,
    .auth-layout__wrapper {
      min-height: calc(100vh - 104px);
    }
  }
}

.auth-layout {
  &__main {
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 70px);
    overflow: hidden;

    @include until($breakpoint-medium) {
      height: 100%;
    }

    @include until($breakpoint-small) {
      padding: 0;
      min-height: calc(100vh - 60px);
    }
  }

  &__cover,
  &__content {
    width: 50%;
    height: 100%;
  }

  &__cover {
    min-height: calc(100vh - 70px);
    background-image: url('~@/assets/images/background-cover.jpg');
    background-size: cover;

    @include until($breakpoint-medium) {
      display: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include until($breakpoint-medium) {
      width: 100%;
    }

    @include until($breakpoint-small) {
      min-height: calc(100vh - 60px);
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: rem(60) 0 rem(40) 0;
    overflow-y: auto;

    @include until($breakpoint-medium) {
      padding: 0;
      padding: 0;
      min-height: calc(100vh - 70px);
      justify-content: center;
    }

    @include until($breakpoint-small) {
      min-height: calc(100vh - 60px);
    }
  }

  &__inner {
    @include until($breakpoint-medium) {
      flex: 1;
      display: flex;
      align-items: center;
      padding: rem(36) rem(22) rem(40) rem(22);
    }
  }

  .promo-service-list {
    width: 100%;
    max-width: 28.85714rem;

    @include until($breakpoint-medium) {
      max-width: none;
    }
  }
}
</style>
