<template>
  <div class="copyright">
    © 2001 - {{ year }} Copyright CZECH NEWS CENTER a.s. a dodavatelé obsahu •
    <a
      href="https://www.cncenter.cz/clanek/2658/autorska-prava-k-publikovanym-materialum"
      target="_blank"
      >Autorská práva k publikovaným materiálům</a
    >
    •
    <a href="https://www.cncenter.cz/podminky-uzivani" target="_blank"
      >Podmínky pro užívání služby informační společnosti</a
    >
    •
    <a href="https://www.cncenter.cz/informace-o-zpracovani" target="_blank"
      >Informace o zpracování osobních údajů</a
    >
    •
    <a href="https://www.cncenter.cz/cookies" target="_blank">Cookies</a>
  </div>
</template>

<script>
export default {
  name: 'Copyright',

  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.copyright {
  font-size: rem(12);
  line-height: rem(18);
  color: $color-text-gray;

  a {
    color: $color-text-gray;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
