<template>
  <div>
    <PageWrapper
      v-if="!success && !limit"
      :header="false"
      :footer="true"
      class="page-form--forgotten-password"
    >
      <h1 class="page-form__title">Zapomněli jste heslo?</h1>
      <div class="page-form__description">
        Nebojte, stačí když nám do políčka níže, vyplníte e-mail, přes který jste se registrovali a
        my vám pošleme instrukce pro resetování hesla.
      </div>
      <ForgottenPasswordForm
        @submitSuccess="onSubmitSuccess"
        @submitLimitExceeded="onSubmitLimitExceeded"
      />
      <div class="page-form__incentive-link">
        <router-link :to="{ name: 'Login' }">Zpět na přihlášení</router-link>
      </div>
    </PageWrapper>

    <PageWrapper
      v-else-if="success && !limit"
      :header="false"
      :footer="true"
      class="page-form--forgotten-password-send"
    >
      <div class="page-form__icon">
        <img :src="require('@/assets/svg/send.svg')" alt="Odesláno" />
      </div>
      <h1 class="page-form__title">Odkaz pro resetování hesla byl odeslán</h1>
      <div class="page-form__description">
        Na e-mail <span class="page-form__description-highlight">{{ email }}</span> jsme poslali
        e-mail pro resetování hesla. Pokud Vám nedorazí zkontrolujte spam, nebo si jej pošlete
        znovu.
      </div>
      <div class="page-form__incentive-link">
        <span @click="reset" class="link">Zpět na zadání emailu</span>
        <router-link :to="{ name: 'Login' }">Zpět na přihlášení</router-link>
      </div>
    </PageWrapper>

    <PageWrapper v-else :header="false" :footer="true" class="page-form--forgotten-password-limit">
      <div class="page-form__icon">
        <img :src="require('@/assets/svg/alert.svg')" alt="Výstraha" />
      </div>
      <h1 class="page-form__title">Překročení limitu</h1>
      <div class="page-form__description">
        Překročil jste limit 3x zasláním odkazu na e-mail k resetování hesla.
      </div>
      <div class="page-form__description">
        Kontaktujte prosím podporu na e-mailu
        <a href="mailto:podpora@cncenter.cz" class="link">podpora@cncenter.cz</a>
      </div>
      <div class="page-form__incentive-link">
        <router-link :to="{ name: 'Login' }">Zpět na přihlášení</router-link>
      </div>
    </PageWrapper>
  </div>
</template>

<script>
import PageWrapper from '@/pages/wrappers/PageWrapper';
import ForgottenPasswordForm from '@/components/forms/ForgottenPasswordForm';

export default {
  name: 'ForgottenPasswordPage',

  components: { PageWrapper, ForgottenPasswordForm },

  data() {
    return {
      email: '',
      success: false,
      limit: false,
    };
  },

  methods: {
    reset() {
      this.success = 0;
      this.email = '';
    },

    onSubmitSuccess({ email }) {
      this.success = 1;
      this.email = email;
    },

    onSubmitLimitExceeded() {
      this.limit = true;
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/abstracts/abstracts';

.page-form--forgotten-password {
  .page-form {
    &__description {
      padding-bottom: rem(24);
    }
  }
}

.page-form--forgotten-password-send {
  .page-form {
    &__icon {
      max-width: rem(125);
    }

    &__description {
      padding-bottom: rem(24);
    }

    &__incentive-link {
      display: flex;
      justify-content: space-between;
      margin: rem(16) 0 0 0;
    }
  }
}

.page-form--forgotten-password-limit {
  .page-form {
    &__icon {
      max-width: rem(99);
    }

    &__description {
      padding-bottom: rem(30);

      &:nth-last-child(2) {
        padding-bottom: 0 !important;
      }
    }
  }
}
</style>
