<template>
  <header>
    <slot name="header-before"></slot>
    <div class="header__logo">
      <router-link :to="{ name: 'Login' }">
        <img :src="require('@/assets/svg/brand-logo.svg')" alt="Brand logo" />
      </router-link>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderDefault',
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: rem(70);
  box-shadow: $box-shadow-bottom;
  z-index: 2;
  background-color: $background-color-light;

  @include until($breakpoint-small) {
    height: rem(60);
  }
}

.header__logo {
  img {
    height: rem(38);
    margin-bottom: rem(7);
  }
}
</style>
