<template>
  <label :for="context.id" class="tooltip">
    <div v-if="isOpen" class="tooltip__content">
      <div class="tooltip__inner" v-html="context.help" />
    </div>
    <svg class="tooltip__icon" xmlns="http://www.w3.org/2000/svg" @click="openTooltip">
      <path
        fill-rule="evenodd"
        d="M9 0a9 9 0 109 9 9 9 0 00-9-9zm.188 3.75a1.125 1.125 0 11-1.125 1.125A1.124 1.124 0 019.188 3.75zM7.875 13.875h3a.75.75 0 000-1.5h-.562a.186.186 0 01-.188-.187V8.625a1.5 1.5 0 00-1.5-1.5h-.75a.75.75 0 100 1.5h.563a.187.187 0 01.188.188v3.375a.186.186 0 01-.188.187h-.562a.75.75 0 000 1.5z"
      />
    </svg>
  </label>
</template>

<script>
export default {
  name: 'Tooltip',

  props: {
    context: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    openTooltip() {
      if (!this.isOpen) {
        this.isOpen = true;
        setTimeout(() => document.addEventListener('click', this.closeTooltip), 1);
      } else {
        this.closeTooltip();
      }
    },

    closeTooltip() {
      if (event && event.target.classList.contains('tooltip__inner')) return;

      this.isOpen = false;
      document.removeEventListener('click', this.closeTooltip);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.tooltip {
  display: inline-block;
  line-height: 0;

  &__icon {
    width: rem(18);
    height: rem(18);
    cursor: pointer;
    user-select: none;
  }

  &__content {
    position: absolute;
    max-width: rem(200);
    font-size: rem(12);
    font-weight: normal;
    color: $color-text-light;
    background-color: $background-color-black;
    transform: translate3d(calc(-50% + 9px), calc(-100% - 13px), 0);

    &::before {
      content: '';
      position: absolute;
      width: rem(10);
      height: rem(10);
      bottom: rem(-5);
      left: calc(50% - 5px);
      background-color: $background-color-black;
      transform: rotate(45deg);
      z-index: -1;
    }
  }

  &__inner {
    padding: rem(5) rem(12);
    text-transform: none;
    letter-spacing: 0;
    line-height: normal;

    /deep/ a {
      color: $color-text-light;
    }
  }
}
</style>