export const USER_GET_REQUEST = 'USER_GET_REQUEST';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_CHANGE_AVATAR = 'USER_CHANGE_AVATAR';
export const USER_REMOVE_AVATAR = 'USER_REMOVE_AVATAR';
export const USER_GET_LOGIN_TYPES = 'USER_GET_LOGIN_TYPES';
export const USER_GET_SERVICE_PARAMS = 'USER_GET_SERVICE_PARAMS';
export const USER_CHANGE_SERVICE_PARAMS = 'USER_CHANGE_SERVICE_PARAMS';
export const USER_CHANGE_SERVICE_AVATAR = 'USER_CHANGE_SERVICE_AVATAR';
export const USER_REMOVE_SERVICE_AVATAR = 'USER_REMOVE_SERVICE_AVATAR';
export const USER_GET_SERVICE_REQUIRED_PARAMS = 'USER_GET_SERVICE_REQUIRED_PARAMS';
export const USER_SET_SERVICE_PARAMS = 'USER_SET_SERVICE_PARAMS';
