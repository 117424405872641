import { AUTH_SET_SERVICE_ID, AUTH_SET_REDIRECT_URL } from '@/store/actions/auth';
import Spinner from '@/components/ui/Spinner';
import { mapGetters } from 'vuex';

export default {
  components: { Spinner },

  data() {
    return {
      response: null,
      formErrors: [],
      isSuccess: false,
      isSubmiting: false,
      isPassword: true,
    };
  },

  computed: {
    ...mapGetters(['serviceId', 'redirectUrl']),
  },

  methods: {
    async handleSubmit(action, payload) {
      this.isSubmiting = true;
      this.formErrors = [];

      if (typeof action === 'string') {
        const response = await this.$store.dispatch(action, payload);
        this.responseHandler(response, payload);
      }

      if (typeof action === 'function') {
        const response = await action(payload);
        this.responseHandler(response, payload);
      }

      this.isSubmiting = false;
    },

    async responseHandler(response, payload) {
      const serviceId = response?.meta?.serviceId;
      const redirectUrl = response?.meta?.redirectUrl;

      if (serviceId && redirectUrl) {
        this.$store.dispatch(AUTH_SET_SERVICE_ID, serviceId);
        this.$store.dispatch(AUTH_SET_REDIRECT_URL, redirectUrl);
      }

      if (response.status === 200) {
        this.isSuccess = true;
        this.$emit('submitSuccess', payload);
        return;
      }

      if (response.status === 401 && response.code === '-100') {
        this.$emit('emailVerification', payload);
        return;
      }

      if (response.status === 401 && response.code === '-104') {
        this.$emit('confirmationConditions', payload);
        return;
      }

      if (response.status === 429) {
        this.$emit('submitLimitExceeded');
        return;
      }

      this.$emit('submitError', payload);
      this.formErrors.push(response.message);
    },
  },
};
