<template>
  <FormulateForm v-model="formValues" @submit="onSubmit" :form-errors="formErrors">
    <FormulateInput
      label="E-mail"
      type="email"
      name="email"
      :value="email"
      validation="^required|email"
      :validation-messages="{
        required: 'Zadejte prosím e-mail',
        email: 'Vámi zadaný e-mail nemá správný formát',
      }"
      error-behavior="submit"
    />

    <FormulateInput
      type="submit"
      :label="isSuccess ? 'Odkaz odeslán' : 'Odeslat nový odkaz'"
      :disabled="isSubmiting"
      :class="{ 'button--success': isSuccess }"
      ><Spinner v-if="isSubmiting" :size="24" color="#ffffff"
    /></FormulateInput>
  </FormulateForm>
</template>

<script>
import { API } from '@/api';
import BaseFormMixin from '@/mixins/BaseFormMixin';

export default {
  name: 'ActivationEmailForm',

  mixins: [BaseFormMixin],

  data() {
    return {
      formValues: {},
    };
  },

  props: {
    email: {
      type: String,
      default: '',
    },
  },

  methods: {
    onSubmit() {
      const { email } = this.formValues;
      this.handleSubmit(API.auth.sendActivationEmail, {
        email,
        serviceId: this.serviceId,
        redirectUrl: this.redirectUrl,
      });
    },
  },
};
</script>
