import { SERVICE_GET_LIST } from '@/store/actions/service';
import { API } from '@/api';

const state = {
  list: [],
};

const getters = {
  serviceList: (state) => state.list,
};

const actions = {
  [SERVICE_GET_LIST]: async ({ commit }) => {
    const response = await API.service.getServiceList();

    if (response.status === 200) {
      commit(SERVICE_GET_LIST, response.data);
    }

    return response;
  },
};

const mutations = {
  [SERVICE_GET_LIST]: (state, payload) => {
    state.list = payload;
  },
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
};
