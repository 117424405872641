<template>
  <div class="loading-circle">
    <svg :width="size" :height="size" viewPort="0 0 100 100">
      <circle :cx="center" :cy="center" :r="radius" :stroke-width="strokeWidth"></circle>
      <circle
        class="progress"
        :cx="center"
        :cy="center"
        :r="radius"
        :stroke-width="strokeWidth"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="circumference"
      ></circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'CircleLoadingBar',

  props: {
    size: {
      type: Number,
      required: true,
    },
    strokeWidth: {
      type: Number,
      default: 8,
    },
  },

  computed: {
    center() {
      return this.size / 2;
    },

    radius() {
      return this.size / 2 - this.strokeWidth / 2;
    },

    circumference() {
      return Math.PI * (this.radius * 2);
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/abstracts/abstracts';

.loading-circle {
  text-align: center;

  svg {
    fill: transparent;
    transform: rotate(-90deg);
    animation: 2s linear infinite svg-animation;
  }

  svg circle {
    stroke: $background-color-light-blue;
  }

  svg .progress {
    animation: 1.4s ease-in-out infinite both circle-animation;
    display: block;
    stroke: $background-color-dark-blue;
    stroke-linecap: round;
    transform-origin: 50% 50%;
  }

  @keyframes svg-animation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  @keyframes circle-animation {
    0%,
    25% {
      stroke-dashoffset: 460;
      transform: rotate(0);
    }

    50%,
    75% {
      stroke-dashoffset: 75;
      transform: rotate(45deg);
    }

    100% {
      stroke-dashoffset: 460;
      transform: rotate(360deg);
    }
  }
}
</style>
