<template>
  <FormulateForm
    name="change-password-form"
    class="edit-user-form"
    v-model="formValues"
    @submit="onSubmit"
    :form-errors="formErrors"
    novalidate
  >
    <Card>
      <h2 class="edit-user-form__title">Změna hesla</h2>
      <FormulateErrors />
      <div class="single-wide">
        <FormulateInput
          label="Staré heslo"
          type="password"
          name="current_password"
          value=""
          validation="^required"
          :validation-messages="{
            required: 'Zadejte prosím heslo',
          }"
          error-behavior="submit"
        />
      </div>

      <div class="double-wide">
        <FormulateInput
          label="Nové heslo"
          :type="isPassword ? 'password' : 'text'"
          name="password"
          value=""
          validation="^required|min:8"
          :validation-messages="{
            required: 'Zadejte prosím heslo',
            min: 'Heslo musí obsahovat minimálně 8 znaků',
          }"
          error-behavior="submit"
        >
          <template #label="{ label, id }">
            <label :for="id">
              {{ label }}
              <span
                :class="['password-icon', { isvisible: !isPassword }]"
                @click="isPassword = !isPassword"
              />
            </label>
          </template>
        </FormulateInput>
        <FormulateInput
          label="Nové heslo znovu"
          type="password"
          name="password_confirm"
          value=""
          validation="^required|confirm"
          validation-name="Confirmation"
          :validation-messages="{
            required: 'Zadejte prosím heslo',
            confirm: 'Zadané heslo se neshoduje',
          }"
          error-behavior="submit"
        />
      </div>
    </Card>
    <FormulateInput
      class="submit-button"
      :class="{ 'button--disabled': unchanged }"
      type="submit"
      label="Změnit heslo"
      :disabled="isSubmiting"
      ><Spinner v-if="isSubmiting" :size="24" color="#ffffff"
    /></FormulateInput>
  </FormulateForm>
</template>

<script>
import { mapGetters } from 'vuex';
import DashboardFormMixin from '@/mixins/DashboardFormMixin';
import Card from '@/components/ui/Card';
import { API } from '@/api';

export default {
  name: 'ChangePasswordForm',

  mixins: [DashboardFormMixin],

  components: {
    Card,
  },

  data() {
    return {
      isPassword: true,
    };
  },

  computed: {
    ...mapGetters(['userProfile', 'authToken']),
  },

  methods: {
    async onSubmit() {
      const { current_password, password } = this.formValues;

      const response = await this.handleSubmit(API.user.changePassword, {
        token: this.authToken,
        payload: {
          currentPassword: current_password,
          newPassword: password,
        },
      });

      if (response.status === 200) {
        this.$formulate.reset('change-password-form');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

/deep/ [type='submit'] {
  min-width: rem(185);
}
</style>
