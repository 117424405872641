<template>
  <FormulateInput
    label="Přezdívka"
    type="text"
    name="nickname"
    :value="value"
    :help="help"
    validation="bail|alphaExtendedSpecial|emptyOrMin:3|max:30,length"
    :validation-messages="{
      alphaExtendedSpecial: 'Povolené jsou pouze znaky abecedy',
      emptyOrMin: 'Přezdívka musí obsahovat minimálně 3 znaky',
      max: 'Přezdíva nesmí být delší než 30 znaků',
    }"
    error-behavior="submit"
    :disabled="disabled"
  />
</template>

<script>
export default {
  name: 'NicknameField',

  props: {
    value: {
      type: String,
    },
    help: {
      type: [String, Boolean],
    },
    disabled: {
      type: Boolean,
    },
  },
};
</script>
