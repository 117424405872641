<template>
  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" :style="{ maxWidth: `${size}px` }">
    <circle cx="50" cy="50" r="44" :stroke="color" />
  </svg>
</template>

<script>
export default {
  name: 'Spinner',

  props: {
    size: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: '#000000',
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  animation: 2s linear infinite svg-animation;
  width: 100%;
}

// SVG animation.
@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

// Circle styles.
circle {
  animation: 1.4s ease-in-out infinite both circle-animation;
  display: block;
  fill: transparent;
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 280;
  stroke-width: 12px;
  transform-origin: 50% 50%;
}

// Circle animation.
@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}
</style>
