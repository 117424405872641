import { render, staticRenderFns } from "./PageWrapperCover.vue?vue&type=template&id=12761f56&scoped=true&"
import script from "./PageWrapperCover.vue?vue&type=script&lang=js&"
export * from "./PageWrapperCover.vue?vue&type=script&lang=js&"
import style0 from "./PageWrapperCover.vue?vue&type=style&index=0&id=12761f56&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../.yarn/__virtual__/vue-loader-virtual-2be745a210/7/opt/data/cache/account-service/yarn_cache/vue-loader-npm-15.10.1-3a0b6c19d2-753a6b6da2.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12761f56",
  null
  
)

export default component.exports