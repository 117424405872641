<template>
  <div v-if="services.length > 0" class="promo-service-list" :class="{ hidden: !imagesAreLoaded }">
    <div>Přes Můj účet se také přihlásíte na</div>
    <div class="promo-service-list__brands">
      <div v-for="{ id, attributes } in services" :key="id" class="promo-service-list__item">
        <img :src="attributes.logoUrl" :alt="attributes.name" @load="imageLoaded++" />
      </div>
      <div class="promo-service-list__item promo-service-list__item--count">
        +{{ serviceList.length }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

const PROMO_SERVICES = ['blesk', 'reflex', 'auto'];

export default {
  name: 'PromoServiceList',

  data() {
    return {
      imageLoaded: 0,
    };
  },

  computed: {
    ...mapGetters(['serviceList']),

    services() {
      return this.serviceList.filter((service) => PROMO_SERVICES.includes(service.attributes.name));
    },

    imagesAreLoaded() {
      return this.services.length > 0 && this.imageLoaded === this.services.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.promo-service-list {
  display: flex;
  flex-direction: column;
  margin-top: rem(60);
  font-weight: 600;
  min-height: rem(61);

  @include until($breakpoint-medium) {
    width: 100%;
    align-items: center;
    padding: rem(36) 0;
    margin-top: rem(30);
    background-color: $background-color-light-blue;
  }

  &__brands {
    display: flex;
    margin-top: rem(10);
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: rem(7) rem(10);
    margin-right: rem(10);
    background-color: $background-color-light;
    border-radius: rem(4);
    box-shadow: $box-shadow-alt;

    img {
      height: rem(16);
      width: auto;
    }

    &--count {
      line-height: 0;
    }
  }
}

.hidden {
  visibility: hidden;
}
</style>
