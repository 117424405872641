<template>
  <span>{{ username }}</span>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty } from '@/helpers';

export default {
  name: 'UserName',

  computed: {
    ...mapGetters(['userProfile']),

    username() {
      const { firstName, lastName, email } = this.userProfile;
      return isEmpty(firstName) || isEmpty(lastName) ? email : `${firstName} ${lastName}`;
    },
  },
};
</script>
