<template>
  <FormulateForm v-model="formValues" @submit="onSubmit" :form-errors="formErrors" novalidate>
    <FormulateErrors />
    <FormulateInput
      label="E-mail"
      type="email"
      name="email"
      validation="^required|email"
      :validation-messages="{
        required: 'Zadejte prosím e-mail',
        email: 'Vámi zadaný e-mail nemá správný formát',
      }"
      error-behavior="submit"
    />
    <FormulateInput
      label="Heslo"
      :type="isPassword ? 'password' : 'text'"
      name="password"
      validation="required"
      :validation-messages="{
        required: 'Zadejte prosím heslo',
      }"
      error-behavior="submit"
    >
      <template #label="{ label, id }">
        <label :for="id">
          {{ label }}
          <span class="forgotten-password">
            <router-link :to="{ name: 'ForgottenPassword' }" tabindex="-1"
              >Zapomněli jste heslo?</router-link
            >
          </span>
          <span
            :class="['password-icon', { isvisible: !isPassword }]"
            @click="isPassword = !isPassword"
          />
        </label>
      </template>
    </FormulateInput>

    <FormulateInput type="submit" label="Přihlásit se" :disabled="isSubmiting"
      ><Spinner v-if="isSubmiting" :size="24" color="#ffffff"
    /></FormulateInput>
  </FormulateForm>
</template>

<script>
import BaseFormMixin from '@/mixins/BaseFormMixin';
import { AUTH_LOGIN_REQUEST } from '@/store/actions/auth';

export default {
  name: 'LoginForm',

  mixins: [BaseFormMixin],

  data() {
    return {
      formValues: {},
    };
  },

  methods: {
    onSubmit() {
      const { email, password } = this.formValues;

      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push(function () {
        window.gtmService.push({
          event: 'user_login_attempt',
        });
      });

      this.handleSubmit(AUTH_LOGIN_REQUEST, {
        email,
        password,
        serviceId: this.serviceId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.forgotten-password a {
  color: $color-text-dark;
  letter-spacing: 0;
  text-transform: none;
}
</style>
