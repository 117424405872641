export const AUTH_TOKEN_CNAME = 'ssost';
export const AUTH_DOMAIN_CNAME = 'ssodn';
export const AUTH_TOKEN_SET = 'AUTH_TOKEN_SET';

export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_TOKEN_REQUEST = 'AUTH_LOGIN_TOKEN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';

export const AUTH_REGISTER_REQUEST = 'AUTH_REGISTER_REQUEST';
export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS';
export const AUTH_REGISTER_ERROR = 'AUTH_REGISTER_ERROR';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const AUTH_SET_REDIRECT_URL = 'AUTH_SET_REDIRECT_URL';
export const AUTH_SET_SERVICE_ID = 'AUTH_SET_SERVICE_ID';
export const AUTH_SET_IS_MOBILE_APP = 'AUTH_SET_IS_MOBILE_APP';
