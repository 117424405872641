import {
  AUTH_TOKEN_CNAME,
  AUTH_DOMAIN_CNAME,
  AUTH_TOKEN_SET,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_TOKEN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_REGISTER_REQUEST,
  AUTH_REGISTER_SUCCESS,
  AUTH_REGISTER_ERROR,
  AUTH_LOGOUT,
  AUTH_SET_REDIRECT_URL,
  AUTH_SET_SERVICE_ID,
  AUTH_SET_IS_MOBILE_APP,
} from '@/store/actions/auth';
import { USER_GET_SUCCESS } from '@/store/actions/user';
import Cookies from 'js-cookie';
import { API } from '@/api';
import { COOKIE_OPTIONS, COOKIE_MOBILE_EXPIRES } from '@/config';
import { isMobile } from '@/helpers';

const state = {
  token: Cookies.get(AUTH_TOKEN_CNAME) || '',
  status: '',
  redirectUrl: null,
  serviceId: null,
  isMobileApp: null
};

const getters = {
  authToken: (state) => state.token,
  authStatus: (state) => state.status,
  isAuthenticated: (state) => !!state.token,
  redirectUrl: (state) => state.redirectUrl,
  serviceId: (state) => state.serviceId,
  isMobileApp: (state) => state.isMobileApp,
};

const loginResponseHandler = (response, commit) => {
  if (response.status === 200) {
    const token = response.data.meta.token;
    const profile = response.data.data.attributes;

    const cookieOptions = isMobile()
      ? { ...COOKIE_OPTIONS, expires: COOKIE_MOBILE_EXPIRES }
      : COOKIE_OPTIONS;

    Cookies.set(AUTH_TOKEN_CNAME, token, cookieOptions);
    commit(AUTH_LOGIN_SUCCESS, token);
    commit(USER_GET_SUCCESS, profile);
  } else {
    commit(AUTH_LOGIN_ERROR);
  }
};

const actions = {
  [AUTH_LOGIN_REQUEST]: async ({ commit }, payload) => {
    commit(AUTH_LOGIN_REQUEST);
    const response = await API.auth.login(payload);

    loginResponseHandler(response, commit);

    return response;
  },

  [AUTH_LOGIN_TOKEN_REQUEST]: async ({ commit }, payload) => {
    commit(AUTH_LOGIN_REQUEST);
    const response = await API.auth.loginToken(payload);

    loginResponseHandler(response, commit);

    return response;
  },

  [AUTH_REGISTER_REQUEST]: async ({ commit }, payload) => {
    commit(AUTH_REGISTER_REQUEST);
    const response = await API.auth.register(payload);

    if (response.status === 200) {
      commit(AUTH_REGISTER_SUCCESS);
    } else {
      commit(AUTH_REGISTER_ERROR);
    }

    return response;
  },

  [AUTH_LOGOUT]: async ({ commit, getters }, userAction = false) => {
    if (userAction) {
      API.auth.logout({ token: getters.authToken });
    }

    const cookieDomain = Cookies.get(AUTH_DOMAIN_CNAME);
    Cookies.remove(AUTH_TOKEN_CNAME, COOKIE_OPTIONS);

    if (cookieDomain) {
      const domain = atob(cookieDomain);
      Cookies.remove(AUTH_TOKEN_CNAME, { domain });
      Cookies.remove(AUTH_DOMAIN_CNAME, { domain });
    }
    commit(AUTH_LOGOUT);
  },

  [AUTH_SET_REDIRECT_URL]: ({ commit, getters }, payload) => {
    if (getters.redirectUrl !== null) return;
    commit(AUTH_SET_REDIRECT_URL, payload);
  },

  [AUTH_SET_SERVICE_ID]: ({ commit, getters }, payload) => {
    if (getters.serviceId !== null) return;
    commit(AUTH_SET_SERVICE_ID, payload);
  },

  [AUTH_SET_IS_MOBILE_APP]: ({ commit, getters }, payload) => {
    if (getters.isMobileApp !== null) return;
    commit(AUTH_SET_IS_MOBILE_APP, payload);
  },
};

const mutations = {
  [AUTH_LOGIN_REQUEST]: (state) => {
    state.status = 'login';
  },

  [AUTH_LOGIN_SUCCESS]: (state, token) => {
    state.status = 'login success';
    state.token = token;
  },

  [AUTH_LOGIN_ERROR]: (state) => {
    state.status = 'login error';
  },

  [AUTH_REGISTER_REQUEST]: (state) => {
    state.status = 'registration';
  },

  [AUTH_REGISTER_SUCCESS]: (state) => {
    state.status = 'registration success';
  },

  [AUTH_REGISTER_ERROR]: (state) => {
    state.status = 'registration error';
  },

  [AUTH_LOGOUT]: (state) => {
    state.token = '';
    state.status = '';
  },

  [AUTH_TOKEN_SET]: (state, token) => {
    state.token = token;
  },

  [AUTH_SET_REDIRECT_URL]: (state, payload) => {
    state.redirectUrl = payload;
  },

  [AUTH_SET_SERVICE_ID]: (state, payload) => {
    state.serviceId = payload;
  },

  [AUTH_SET_IS_MOBILE_APP]: (state, payload) => {
    state.isMobileApp = payload;
  },
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
};
