<template>
  <div class="main-menu">
    <div
      class="main-menu__label navbar__link"
      @click="toggle"
      :data-track-click="menuTrackAttribut"
    >
      <div class="main-menu__icon" :class="{ 'is-visible': visibility }">
        <span></span>
      </div>
      <span class="main-menu__desc">Menu</span>
    </div>

    <transition-group name="collapse">
      <div v-if="visibility" class="main-menu__content" key="content">
        <div class="main-menu__item">
          <router-link
            :to="{ name: 'WebsitesAndApplications' }"
            class="main-menu__link"
            data-track-click='{"event": "clickTo_navigation", "menu": { "type": "menu", "section": "Weby a aplikace" }}'
          >
            <div class="main-menu__link-image">
              <img :src="require('@/assets/svg/website-applications.svg')" alt="Weby a aplikace" />
            </div>
            <div class="main-menu__link-text">Weby a aplikace</div>
          </router-link>
        </div>

        <div class="main-menu__item">
          <router-link
            :to="{ name: 'AuthorizedDevices' }"
            class="main-menu__link"
            data-track-click='{"event": "clickTo_navigation", "menu": { "type": "menu", "section": "Autorizovaná přihlášení" }}'
          >
            <div class="main-menu__link-image">
              <img
                :src="require('@/assets/svg/authorized-devices.svg')"
                alt=" Autorizovaná zařízení"
              />
            </div>
            <div class="main-menu__link-text">Autorizovaná přihlášení</div>
          </router-link>
        </div>

        <div class="main-menu__item">
          <router-link
            :to="{ name: 'MyProfile' }"
            class="main-menu__link"
            data-track-click='{"event": "clickTo_navigation", "menu": { "type": "menu", "section": "Můj profil" }}'
          >
            <div class="main-menu__link-image">
              <img :src="require('@/assets/svg/my-profile.svg')" alt="Můj profil" />
            </div>
            <div class="main-menu__link-text">Můj profil</div>
          </router-link>
        </div>

        <!-- <div class="main-menu__item">
          <router-link :to="{ name: 'SecurityAndActivity' }" class="main-menu__link">
            <div class="main-menu__link-image">
              <img
                :src="require('@/assets/svg/security-activity.svg')"
                alt="Bezpečnost a aktivita"
              />
            </div>
            <div class="main-menu__link-text">Bezpečnost a aktivita</div>
          </router-link>
        </div> -->

        <!-- <div class="main-menu__item">
          <router-link :to="{ name: 'PrivacyProtection' }" class="main-menu__link">
            <div class="main-menu__link-image">
              <img
                :src="require('@/assets/svg/privacy-protection.svg')"
                alt="Ochrana soukromí a personalizace"
              />
            </div>
            <div class="main-menu__link-text">Ochrana soukromí a personalizace</div>
          </router-link>
        </div> -->

        <!-- <div class="main-menu__item">
          <router-link :to="{ name: 'PaymentAndDeliveryDetails' }" class="main-menu__link">
            <div class="main-menu__link-image">
              <img
                :src="require('@/assets/svg/payment-delivery-details.svg')"
                alt="Platební a doručovací údaje"
              />
            </div>
            <div class="main-menu__link-text">Platební a doručovací údaje</div>
          </router-link>
        </div> -->
      </div>
    </transition-group>
  </div>
</template>

<script>
const CLASS_CONTENT = 'main-menu__content';

export default {
  name: 'MainMenu',

  data() {
    return {
      visibility: false,
    };
  },

  computed: {
    menuTrackAttribut() {
      return `{"event": "clickTo_navigation", "menu": { "type": "menu" }, "link": { "text": ${
        this.visibility ? '"open"' : '"close"'
      } }}`;
    },
  },

  methods: {
    toggle() {
      if (!this.visibility) {
        this.visibility = true;
        this.$emit('openMenu', true);
        setTimeout(() => document.addEventListener('click', this.hide), 1);
      } else {
        this.hide();
      }
    },

    hide(event) {
      if (event && event.target.classList.contains(CLASS_CONTENT)) return;

      this.visibility = false;
      this.$emit('openMenu', false);
      document.removeEventListener('click', this.hide);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.main-menu {
  height: 100%;

  &__label {
    color: $color-text-light;
  }

  &__label--mobile {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: rem(70);
    padding: rem(20) rem(10) rem(20) rem(25);
    border-bottom: rem(1) solid $background-color-light-blue;
    z-index: 10;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @include from($breakpoint-small) {
      display: none;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    width: 100vw;
    padding: rem(40) 6%;
    background-color: $background-color-light;
    box-shadow: $box-shadow-bottom;
    border-top: rem(1) solid $background-color-light-blue;
    z-index: 1;
    overflow: auto;

    @include until($breakpoint-medium) {
      padding: rem(40) rem(20);
    }

    @include until($breakpoint-small) {
      flex-wrap: nowrap;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      top: rem(70);
      padding: 0 0 rem(10);
    }
  }

  &__item {
    position: relative;
    width: 33.3333%;
    padding: rem(15) rem(10);

    @include until($breakpoint-medium) {
      width: 50%;
    }

    @include until($breakpoint-small) {
      width: 100%;
      padding: 0;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    padding: rem(10) rem(15);
    font-size: rem(16);
    font-weight: 600;
    letter-spacing: rem(0.32);
    color: $color-text-dark;
    background-color: $background-color-light;
    text-decoration: none;

    &:hover {
      .main-menu__link-text::after {
        opacity: 1;
        transform: translate(0, -35%);
      }
    }

    @include until($breakpoint-small) {
      padding: rem(24) rem(20) rem(24) rem(53);
      border-bottom: rem(1) solid $background-color-light-blue;
    }
  }

  &__link-image {
    display: inline-block;
    width: rem(60);
    height: rem(55);
    margin-right: rem(20);

    @include until($breakpoint-small) {
      display: flex;
      justify-content: center;
      width: rem(48);
      height: rem(41);
    }

    img {
      height: 100%;
      width: 100%;
    }
  }

  &__link-text {
    position: relative;
    display: inline-block;

    &::after {
      content: '';
      background-image: url('~@/assets/svg/arrow-regular.svg');
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      right: rem(-30);
      width: rem(20);
      height: rem(10);
      transform: translate(-7px, -35%);
      opacity: 0;
      transition: transform 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);

      @include until($breakpoint-small) {
        display: none;
      }
    }
  }

  &__icon {
    width: rem(20);

    &:after,
    &:before,
    span {
      background-color: $color-text-dark-blue;
      content: '';
      display: block;
      height: rem(2);
      margin: rem(6) 0;
      transition: all 0.25s ease-in-out;
    }

    &.is-visible:before {
      transform: translateY(rem(8)) rotate(135deg);
    }

    &.is-visible:after {
      transform: translateY(rem(-8)) rotate(-135deg);
    }

    &.is-visible span {
      transform: scale(0);
    }
  }

  &__desc {
    color: $color-text-gray;
    font-size: rem(12);
    font-weight: 600;
    letter-spacing: rem(2.4);
    text-transform: uppercase;
    margin-left: rem(16);
    user-select: none;

    &--mobile {
      font-size: rem(16);
    }

    @include until($breakpoint-small) {
      &:not(.main-menu__desc--mobile) {
        display: none;
      }
    }
  }
}

@include until($breakpoint-small) {
  .collapse-enter-active,
  .collapse-leave-active {
    transition: transform 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .collapse-enter,
  .collapse-leave-to {
    transform: translateX(-100%);
  }
}
</style>
