<template>
  <div class="homepage">
    <div class="homepage__intro">
      <Avatar :url="userProfile.avatarUrl" />
      <h1 class="page-title">
        Vítejte,
        <Username />
      </h1>
      <p>
        Spravujte své údaje, nastavení ochrany soukromí a zabezpečení, aby vám Účet CNC sloužil
        podle vašich představ.
      </p>
    </div>

    <div class="homepage__cards">
      <div class="homepage__card">
        <Card>
          <router-link
            :to="{ name: 'WebsitesAndApplications' }"
            class="card__link"
            data-track-click='{"event": "clickTo_navigation", "menu": { "type": "header", "section": "Weby a aplikace" }}'
          >
            <div class="card__link-image">
              <img :src="require('@/assets/svg/website-applications.svg')" alt="Weby a aplikace" />
            </div>
            <div class="card__link-desc">
              <div class="card__link-title">Weby a aplikace</div>
              <div class="card__link-info">
                <div>
                  Aktivních webů <b>{{ activeWebsites }}</b>
                </div>
                <div>
                  Aktivních aplikací <b>{{ activeApplications }}</b>
                </div>
              </div>
            </div>
          </router-link>
        </Card>
      </div>
      <div class="homepage__card">
        <Card>
          <router-link
            :to="{ name: 'AuthorizedDevices' }"
            class="card__link"
            data-track-click='{"event": "clickTo_navigation", "menu": { "type": "header", "section": "Autorizovaná přihlášení" }}'
          >
            <div class="card__link-image">
              <img
                :src="require('@/assets/svg/authorized-devices.svg')"
                alt="Autorizovaná zařízení"
              />
            </div>
            <div class="card__link-desc">
              <div class="card__link-title">Autorizovaná přihlášení</div>
              <div class="card__link-info">
                <div>
                  Počet přihlášení <b>{{ activeDevices }}</b>
                </div>
              </div>
            </div>
          </router-link>
        </Card>
      </div>
      <div class="homepage__card">
        <Card>
          <router-link
            :to="{ name: 'MyProfile' }"
            class="card__link"
            data-track-click='{"event": "clickTo_navigation", "menu": { "type": "header", "section": "Můj profil" }}'
          >
            <div class="card__link-image">
              <img :src="require('@/assets/svg/my-profile.svg')" alt="Můj profil" />
            </div>
            <div class="card__link-desc">
              <div class="card__link-title">Můj profil</div>
            </div>
          </router-link>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty } from '@/helpers';
import Avatar from '@/components/ui/Avatar';
import Card from '@/components/ui/Card';
import { getTime } from '@/utils';
import { SERVICE_GET_LIST } from '@/store/actions/service';
import { SESSION_GET_LIST } from '@/store/actions/session';
import { SERVICE_TYPES } from '@/config';
import Username from '@/components/ui/UserName';

export default {
  name: 'HomePage',

  components: {
    Avatar,
    Username,
    Card,
  },

  computed: {
    ...mapGetters(['userProfile', 'serviceList', 'sessionList']),

    lastLogin() {
      return getTime.getTimeDifferent(this.userProfile.lastLoginDateTime);
    },

    activeWebsites() {
      return isEmpty(this.serviceList)
        ? 0
        : this.serviceList.filter((service) => service.attributes.type == SERVICE_TYPES.website)
            .length;
    },

    activeApplications() {
      return isEmpty(this.serviceList)
        ? 0
        : this.serviceList.filter((service) => service.attributes.type == SERVICE_TYPES.mobile_app)
            .length;
    },

    activeDevices() {
      return this.sessionList.length;
    },
  },

  async created() {
    if (isEmpty(this.serviceList)) {
      this.$store.dispatch(SERVICE_GET_LIST);
    }

    if (isEmpty(this.sessionList)) {
      this.$store.dispatch(SESSION_GET_LIST);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.homepage {
  &__intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: rem(404);
    margin: 0 auto;
    text-align: center;

    .avatar {
      width: rem(100);
      height: rem(100);
    }

    h1 {
      margin: rem(25) 0 rem(15);
      word-break: break-word;
    }

    p {
      color: $color-text-gray;
      line-height: 1.7;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    margin: rem(48) rem(-14) 0;

    @include until($breakpoint-small) {
      margin: rem(36) rem(-14) 0;
    }
  }

  &__card {
    width: 33.3333%;
    padding: rem(14);

    @include until($breakpoint-medium) {
      width: 50%;
    }

    @include until($breakpoint-small) {
      width: 100%;
      padding: rem(6) rem(14);
    }
  }

  .card {
    height: 100%;

    &__link {
      display: flex;
      align-items: flex-start;
      height: 100%;
      padding: rem(28);
      text-decoration: none;

      &:hover {
        .card__link-title::after {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    &__link-desc {
      flex: 1;
    }

    &__link-image {
      display: inline-block;
      width: rem(60);
      height: rem(55);
      margin-right: rem(20);

      img {
        height: 100%;
        width: 100%;
      }
    }

    &__link-title {
      position: relative;
      display: inline-block;
      font-size: rem(16);
      font-weight: 600;
      color: $color-text-dark;
      margin-right: rem(30);

      &::after {
        content: '';
        background-image: url('~@/assets/svg/arrow-regular.svg');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: rem(8);
        right: rem(-30);
        width: rem(20);
        height: rem(10);
        transform: translateX(-8px);
        opacity: 0;
        transition: transform 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }

    &__link-info {
      margin-top: rem(12);
      color: $color-text-gray;
    }
  }
}
</style>
