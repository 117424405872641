<template>
  <footer>
    <p>
      Pokud máte problémy s přihlášením napište na
      <a href="mailto:podpora@cncenter.cz" class="link">podpora@cncenter.cz</a>
    </p>
    <p>
      Máte dotaz ohledně vašich osobních údajů? Napište nám na
      <a href="mailto:osobni.udaje@cncenter.cz" class="link">osobni.udaje@cncenter.cz</a>
    </p>
    <Copyright />
  </footer>
</template>

<script>
import Copyright from '@/components/layouts/Copyright';

export default {
  name: 'Footer',

  components: {
    Copyright,
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

footer {
  padding: rem(30);
  text-align: center;

  p {
    padding-bottom: rem(22);
    font-size: rem(12);
    line-height: rem(18);
    color: $color-text-gray;
  }
}
</style>
