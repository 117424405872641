import Spinner from '@/components/ui/Spinner';

export default {
  components: { Spinner },

  data() {
    return {
      formValues: {},
      formErrors: [],
      stateValues: {},
      isSuccess: false,
      isSubmiting: false,
      unchanged: false,
    };
  },

  mounted() {
    this.syncStates();
  },

  watch: {
    formValues() {
      this.checkChanges();
    },
    stateValues() {
      this.checkChanges();
    },
  },

  methods: {
    async handleSubmit(action, payload) {
      this.isSubmiting = true;
      this.formErrors = [];

      let response;

      if (typeof action === 'string') {
        response = await this.$store.dispatch(action, payload);
      }

      if (typeof action === 'function') {
        response = await action(payload);
      }

      this.isSubmiting = false;
      this.stateValues = this.formValues;

      let type;

      if (response.status === 200) {
        type = 'positive';
      } else {
        type = 'negative';
      }

      this.$notify({
        type,
        text: response.message,
      });

      this.syncStates();

      return response;
    },

    syncStates() {
      this.stateValues = JSON.parse(JSON.stringify(this.formValues));
    },

    checkChanges() {
      this.unchanged = JSON.stringify(this.formValues) === JSON.stringify(this.stateValues);
    },
  },
};
