var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormulateForm',{staticClass:"edit-user-form",attrs:{"name":"change-password-form","form-errors":_vm.formErrors,"novalidate":""},on:{"submit":_vm.onSubmit},model:{value:(_vm.formValues),callback:function ($$v) {_vm.formValues=$$v},expression:"formValues"}},[_c('Card',[_c('h2',{staticClass:"edit-user-form__title"},[_vm._v("Změna hesla")]),_c('FormulateErrors'),_c('div',{staticClass:"single-wide"},[_c('FormulateInput',{attrs:{"label":"Staré heslo","type":"password","name":"current_password","value":"","validation":"^required","validation-messages":{
          required: 'Zadejte prosím heslo',
        },"error-behavior":"submit"}})],1),_c('div',{staticClass:"double-wide"},[_c('FormulateInput',{attrs:{"label":"Nové heslo","type":_vm.isPassword ? 'password' : 'text',"name":"password","value":"","validation":"^required|min:8","validation-messages":{
          required: 'Zadejte prosím heslo',
          min: 'Heslo musí obsahovat minimálně 8 znaků',
        },"error-behavior":"submit"},scopedSlots:_vm._u([{key:"label",fn:function({ label, id }){return [_c('label',{attrs:{"for":id}},[_vm._v(" "+_vm._s(label)+" "),_c('span',{class:['password-icon', { isvisible: !_vm.isPassword }],on:{"click":function($event){_vm.isPassword = !_vm.isPassword}}})])]}}])}),_c('FormulateInput',{attrs:{"label":"Nové heslo znovu","type":"password","name":"password_confirm","value":"","validation":"^required|confirm","validation-name":"Confirmation","validation-messages":{
          required: 'Zadejte prosím heslo',
          confirm: 'Zadané heslo se neshoduje',
        },"error-behavior":"submit"}})],1)],1),_c('FormulateInput',{staticClass:"submit-button",class:{ 'button--disabled': _vm.unchanged },attrs:{"type":"submit","label":"Změnit heslo","disabled":_vm.isSubmiting}},[(_vm.isSubmiting)?_c('Spinner',{attrs:{"size":24,"color":"#ffffff"}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }