<template>
  <div class="avatar">
    <img v-if="isAvatar" :src="url" loading="lazy" />
    <img v-else :src="require('@/assets/svg/avatar.svg')" />
  </div>
</template>

<script>
export default {
  name: 'Avatar',

  props: {
    url: {
      type: String,
      default: '',
    },
  },

  computed: {
    isAvatar() {
      return (this.url && this.url.length > 0) || false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.avatar {
  overflow: hidden;
  border-radius: 90%;

  img {
    width: 100%;
  }
}
</style>
