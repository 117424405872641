<template>
  <div class="popup" v-show="visibility">
    <div class="popup__content">
      <div class="popup__close-icon close" @click="closePopup"></div>
      <slot />
    </div>

    <Backdrop :visibility="visibility" @onBackdropClick="closePopup" />
  </div>
</template>

<script>
import Backdrop from '@/components/ui/Backdrop';

export default {
  name: 'Popup',

  components: {
    Backdrop,
  },

  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    closePopup() {
      this.$emit('popupClose');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.popup {
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 110;
  overflow: auto;

  &__close-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $background-color-light-blue;
  }

  &__content {
    position: relative;
    max-width: rem(404);
    padding: rem(60) rem(40);
    background-color: $background-color-light;
    z-index: 110;
    margin: auto;

    h1 {
      font-size: rem(24);
      margin-bottom: rem(30);
      text-align: center;
      line-height: 1.5;
      letter-spacing: rem(0.5);
    }
  }
}
</style>
