/**
 * @description Sequential ID generator
 * @returns {Number}
 */
export const Id = ((i) => () => i++)(0);

/**
 * @description Creates a setTimeout controller.
 */
export class Timer {
  /**
   * @param {Function} callback
   * @param {Number} duration
   * @param {Object} item
   */
  constructor(callback, duration, item) {
    this._callback = callback;
    this._remaining = duration;
    this._item = item;

    this.resume();
  }

  pause() {
    clearTimeout(this._item.timer);
    this._remaining -= Date.now() - this._start;
  }

  resume() {
    this._start = Date.now();
    clearTimeout(this._item.timer);
    this._item.timer = setTimeout(this._callback, this._remaining);
  }
}
