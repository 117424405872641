<template>
  <div class="server-error-layout">
    <HeaderDefault />

    <main>
      <div class="server-error-layout__content">
        <div class="server-error-layout__icon">
          <img :src="require('@/assets/svg/earth.svg')" alt="Server error" />
        </div>
        <h1 class="server-error-layout__title">Služba není dostupná</h1>
        <p class="server-error-layout__description">Zkuste to prosím později.</p>
      </div>
    </main>

    <Footer />
  </div>
</template>

<script>
import HeaderDefault from '@/components/layouts/header/HeaderDefault';
import Footer from '@/components/layouts/Footer';

export default {
  components: {
    HeaderDefault,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.server-error-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $background-color-gray;

  main {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 0 rem(30) 0;

    @include until($breakpoint-small) {
      width: 100%;
      padding: rem(36) rem(20) 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 rem(22);
  }

  &__icon {
    margin-bottom: rem(32);
  }

  &__title {
    font-size: rem(48);
    line-height: rem(30);
    margin-bottom: rem(32);

    @include until($breakpoint-small) {
      font-size: rem(24);
      line-height: rem(30);
      margin-bottom: rem(24);
    }
  }

  &__description {
    color: $color-text-gray;
    font-size: rem(16);
    font-weight: 600;

    @include until($breakpoint-small) {
      font-size: rem(14);
    }
  }
}
</style>
