<template>
  <div class="social-connect">
    <div v-if="error" class="social-connect__error formulate-form-error">
      {{ error }}
    </div>

    <div class="social-connect__item">
      <SocialConnectFacebook
        @submitSuccess="onSubmitSuccess"
        @confirmationConditions="onConfirmationConditions"
        @connectError="onConnectError"
      />
    </div>
    <div class="social-connect__item">
      <SocialConnectGoogle
        @submitSuccess="onSubmitSuccess"
        @confirmationConditions="onConfirmationConditions"
        @connectError="onConnectError"
      />
    </div>
    <div class="social-connect__item">
      <SocialConnectApple
        @submitSuccess="onSubmitSuccess"
        @confirmationConditions="onConfirmationConditions"
        @connectError="onConnectError"
      />
    </div>
  </div>
</template>

<script>
import SocialConnectFacebook from '@/components/ui/social-connect/SocialConnectFacebook';
import SocialConnectGoogle from '@/components/ui/social-connect/SocialConnectGoogle';
import SocialConnectApple from '@/components/ui/social-connect/SocialConnectApple';

const ERROR_TIMEOUT = 4000;

export default {
  components: { SocialConnectFacebook, SocialConnectGoogle, SocialConnectApple },

  data() {
    return {
      error: '',
    };
  },

  methods: {
    onSubmitSuccess() {
      this.$emit('submitSuccess');
    },

    onConfirmationConditions(payload) {
      this.$emit('confirmationConditions', payload);
    },

    onConnectError(message) {
      this.error = message;
      this.$emit('submitError');
      setTimeout(() => (this.error = null), ERROR_TIMEOUT);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.social-connect {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__error {
    width: 100%;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 31%;
    height: rem(44);
    background-color: $background-color-light-blue;
    cursor: pointer;

    > div {
      line-height: 0;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;

      /deep/ &:hover svg {
        fill: $background-color-black;
      }
    }
  }

  /deep/ svg {
    fill: $background-color-middle-blue;
    transition: fill 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);

    &.apple-icon {
      margin-bottom: rem(5);
    }
  }

  /deep/ circle {
    stroke: $background-color-middle-blue;
  }
}
</style>
