import auth from '@/api/auth';
import user from '@/api/user';
import service from '@/api/service';
import session from '@/api/session';

export const API = {
  auth,
  user,
  service,
  session,
};
