<template>
  <div @click="onLogin">
    <svg
      v-if="!isSubmiting"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 18.458 24"
      class="apple-icon"
    >
      <path
        d="M18.264 1149.581a5.047 5.047 0 012.03-4.049.524.524 0 00.073-.753 5.391 5.391 0 00-3.756-1.779c-1.768-.167-3.452 1.046-4.352 1.046S9.895 1143 8.43 1143a5.593 5.593 0 00-4.718 2.887c-2.009 3.494-.513 8.62 1.391 11.487.952 1.381 2.092 2.94 3.6 2.887s1.988-.931 3.735-.931 2.239.931 3.766.9 2.584-1.412 3.547-2.793a12.856 12.856 0 001.433-2.793.525.525 0 00-.241-.638 4.975 4.975 0 01-2.679-4.425z"
        transform="translate(-2.757 -1136.263)"
      />
      <path
        d="M15.159 1140.337a5.468 5.468 0 001.276-3.233.525.525 0 00-.628-.534 5.572 5.572 0 00-2.94 1.747 5.316 5.316 0 00-1.308 3.139.522.522 0 00.575.534 4.705 4.705 0 003.025-1.653z"
        transform="translate(-2.351 -1136.56)"
      />
    </svg>
    <Spinner v-if="isSubmiting" :size="24" />
  </div>
</template>

<script>
import BaseFormMixin from '@/mixins/BaseFormMixin';
import { AUTH_LOGIN_TOKEN_REQUEST } from '@/store/actions/auth';
import { APPLE_CLIENT_ID, LOGIN_TYPES } from '@/config';

const SCRIPT_URL =
  'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/cs_CZ/appleid.auth.js';

export default {
  name: 'SocialConnectApple',

  mixins: [BaseFormMixin],

  async mounted() {
    if (!window.AppleID) this.initScript();
  },

  methods: {
    initScript() {
      return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = SCRIPT_URL;
        document.head.appendChild(script);
        script.onload = resolve;
      });
    },

    async onLogin() {
      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push(function () {
        window.gtmService.push({
          event: 'user_login_attempt',
        });
      });

      this.isSubmiting = true;

      const { protocol, host } = window.location;

      window.AppleID.auth.init({
        clientId: APPLE_CLIENT_ID,
        scope: 'name email',
        redirectURI: `${protocol}//${host}/`,
        usePopup: true,
      });

      window.AppleID.auth.signIn();

      document.addEventListener('AppleIDSignInOnSuccess', (data) => {
        const { authorization, user } = data.detail;
        const firstName = user?.name?.firstName;
        const lastName = user?.name?.lastName;
        let token = authorization.id_token;

        if (firstName && lastName) token += `%%${firstName}%%${lastName}`;

        this.handleSubmit(AUTH_LOGIN_TOKEN_REQUEST, {
          type: LOGIN_TYPES.apple,
          token: token,
          serviceId: this.serviceId,
        });
      });

      document.addEventListener('AppleIDSignInOnFailure', () => {
        this.isSubmiting = false;
        this.$emit('connectError', 'Propojení se nezdařilo. Zkuste to prosím znovu.');
      });
    },
  },
};
</script>
