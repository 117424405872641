import { makeRequest } from '@/utils';
import { SERVICE_LIST, SERVICE_LIST_SSO, SERVICE_GET_ID, API_UNI_ERROR } from './constants';

export default {
  /**
   * @param {Object} payload
   * @param {String} payload.token
   */
  getServiceList: async () => {
    try {
      const response = await makeRequest.get(SERVICE_LIST);

      return {
        status: response.status,
        data: response.data.data,
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: API_UNI_ERROR,
      };
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.token
   */
  getServiceListSSO: async ({ token }) => {
    try {
      const response = await makeRequest.get(SERVICE_LIST_SSO, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });

      return {
        status: response.status,
        data: response.data.data,
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: API_UNI_ERROR,
      };
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.id
   */
  getServiceId: async ({ id }) => {
    try {
      const response = await makeRequest.get(`${SERVICE_GET_ID}/${id}`);

      return {
        status: response.status,
        data: response.data.data,
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: API_UNI_ERROR,
      };
    }
  },
};
