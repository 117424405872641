<template>
  <div class="auth-devices">
    <h1 class="page-title">Autorizovaná přihlášení</h1>
    <div class="auth-devices__list">
      <Card v-for="{ id, attributes } in sessionList" :key="id" class="auth-devices__item">
        <div class="auth-devices__row auth-devices__row--first">
          <div class="auth-devices__name">
            {{ attributes.device.os }} {{ attributes.device.product }}
          </div>
          <div class="auth-devices__last-login">{{ lastLogin(attributes.created * 1000) }}</div>
        </div>

        <div class="auth-devices__row">
          <a class="auth-devices__logout" v-if="id !== authToken" @click="() => removeSession(id)"
            >Odhlásit přihlášení</a
          >
          <span class="auth-devices__current-device" v-else>Aktuální přihlášení</span>
          <div
            class="auth-devices__browser"
            :data-type="attributes.device.name"
            :title="attributes.device.name"
          ></div>
        </div>
      </Card>
    </div>
    <div v-if="sessionListWithoutCurrent.length > 0" class="auth-devices__logout-all">
      <button class="button button--inline" @click="removeAllSessions">
        Odhlásit všechna přihlášení
      </button>
    </div>

    <Popup :visibility="isPrompt" @popupClose="closePrompt">
      <div class="auth-devices__prompt">
        <div class="auth-devices__prompt-icon">
          <img :src="require('@/assets/svg/alert.svg')" alt="Upozornění" />
        </div>
        <div class="auth-devices__prompt-title">
          <h1 v-if="removedId">Opravdu chcete zařízení odhlásit?</h1>
          <h1 v-else>Opravdu se chcete odhlásit ze všech zařízení?</h1>
        </div>
        <div class="auth-devices__prompt-devices">
          <div v-if="removedId" class="auth-devices__prompt-device">
            {{ getDeviceNameById(removedId) }}
          </div>
          <div
            v-else
            class="auth-devices__prompt-device"
            v-for="{ id } in sessionListWithoutCurrent"
            :key="id"
          >
            {{ getDeviceNameById(id) }}
          </div>
        </div>
        <button class="auth-devices__prompt-button" @click="confirmedRemoval">
          {{ removedId ? 'Odhlásit zařízení' : 'Odhlásit všechny zařízení' }}
        </button>
        <a class="auth-devices__prompt-close-link" @click="closePrompt">Zavřít</a>
      </div>
    </Popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SESSION_GET_LIST, SESSION_REMOVE } from '@/store/actions/session';
import Card from '@/components/ui/Card';
import { getTime } from '@/utils';
import Popup from '@/components/ui/Popup';

export default {
  name: 'AuthorizedDevicesPage',

  components: {
    Card,
    Popup,
  },

  data() {
    return {
      removedId: null,
      isPrompt: false,
    };
  },

  computed: {
    ...mapGetters(['sessionList', 'authToken']),

    sessionListWithoutCurrent() {
      return this.sessionList.filter((session) => session.id !== this.authToken);
    },
  },

  created() {
    this.$store.dispatch(SESSION_GET_LIST);
  },

  methods: {
    lastLogin(created) {
      return getTime.getTimeDifferent(created);
    },

    removeSession(id) {
      this.removedId = id;
      this.isPrompt = true;
    },

    removeAllSessions() {
      this.isPrompt = true;
    },

    confirmedRemoval() {
      if (this.removedId) {
        this.$store.dispatch(SESSION_REMOVE, this.removedId);
      } else {
        this.sessionListWithoutCurrent.forEach((session) =>
          this.$store.dispatch(SESSION_REMOVE, session.id)
        );
      }

      this.closePrompt();
    },

    closePrompt() {
      this.removedId = null;
      this.isPrompt = false;
    },

    getDeviceNameById(id) {
      return this.sessionList.filter((session) => session.id === id)[0].attributes.device.os;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.auth-devices {
  position: relative;

  h1 {
    text-align: center;
    margin-bottom: rem(36);
  }

  &__list {
    max-width: rem(836);
    margin: 0 auto;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: rem(28) rem(48) rem(36);
    margin-bottom: rem(16);

    &:last-child {
      margin-bottom: 0;
    }

    @include until($breakpoint-small) {
      padding: rem(28) rem(20) rem(36);
    }
  }

  &__last-login {
    align-self: flex-end;
    display: inline-block;
    padding: rem(5) rem(16);
    font-weight: 600;
    color: $color-text-middle-blue;
    background-color: $background-color-light-blue;
    border-radius: rem(20);
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &--first {
      flex-wrap: wrap;
      margin-bottom: rem(20);
    }
  }

  &__name {
    font-size: rem(16);
    font-weight: 600;
  }

  &__browser {
    width: rem(36);
    height: rem(36);

    &[data-type='Chrome'] {
      background-image: url('~@/assets/svg/browsers/chrome.svg');
    }

    &[data-type='Safari'] {
      background-image: url('~@/assets/svg/browsers/safari.svg');
    }
  }

  &__current-device {
    align-self: flex-end;
    display: inline-block;
    padding: rem(5) rem(16);
    font-weight: 600;
    color: $color-text-light;
    background-color: $background-color-green;
    border-radius: rem(20);
  }

  &__logout {
    color: $color-text-dark-blue;
    font-weight: 600;
  }

  &__logout-all {
    display: flex;
    justify-content: center;
    margin-top: rem(36);

    @include until($breakpoint-small) {
      .button {
        width: 100%;
        padding: 0;
      }
    }
  }

  &__prompt {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__prompt-icon {
    margin-bottom: rem(26);

    img {
      height: rem(76);
    }
  }

  &__prompt-devices {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: rem(36);
  }

  &__prompt-device {
    padding: rem(12) 0;
    margin-bottom: rem(10);
    width: 100%;
    max-width: rem(244);
    font-size: rem(16);
    font-weight: 600;
    text-align: center;
    background-color: $background-color-gray;
    border-radius: rem(14);
  }

  &__prompt-close-link {
    margin-top: rem(32);
  }
}
</style>
