<template>
  <div class="auth-layout__main">
    <div class="auth-layout__content">
      <div class="page-form">
        <div class="page-form__header" v-if="header">
          <div class="page-form__logo"></div>
        </div>

        <div class="page-form__content">
          <slot />
        </div>

        <div class="page-form__footer" v-if="footer">
          <Copyright />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Copyright from '@/components/layouts/Copyright';

export default {
  name: 'PageWrapper',

  components: { Copyright },

  props: {
    header: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';
.auth-layout {
  &__main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 70px);

    @include until($breakpoint-medium) {
      height: 100%;
      min-height: calc(100vh - 60px);
    }

    @include until($breakpoint-small) {
      background-color: $background-color-light;
    }
  }

  &__content {
    @include until($breakpoint-small) {
      padding: rem(42) rem(22);
    }
  }

  .page-form {
    padding: rem(60) rem(42);
    background-color: $background-color-light;
    box-shadow: $box-shadow;

    @include until($breakpoint-small) {
      box-shadow: none;
      padding: 0;
    }

    &__title {
      text-align: center;
    }

    &__description {
      text-align: center;
    }

    &__incentive-link {
      text-align: center;
    }

    &__footer {
      text-align: center;
    }
  }
}
</style>
