<template>
  <div class="my-profile">
    <div class="my-profile__avatar">
      <Avatar :url="userProfile.avatarUrl" />
      <h1 class="page-title">
        <Username />
      </h1>
      <a class="my-profile__avatar-add button button--inline button--small" @click="openAvatarEdit"
        >Změnit fotku</a
      >
      <a class="my-profile__avatar-remove" @click="removeAvatar">Smazat fotku</a>
    </div>

    <UploadAvatar
      :editing="isAvatarEditing"
      :submiting="isAvatarSubmiting"
      @upload-avatar-success="uploadAvatar"
      @upload-avatar-close="closeAvatarEdit"
    />

    <div class="my-profile__form">
      <EditUserForm />
    </div>

    <div v-if="hasUserPassword" class="my-profile__form">
      <ChangePasswordForm />
    </div>

    <CancelProfileForm />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty } from '@/helpers';
import UploadAvatar from '@/components/ui/UploadAvatar';
import EditUserForm from '@/components/forms/EditUserForm';
import ChangePasswordForm from '@/components/forms/ChangePasswordForm';
import Avatar from '@/components/ui/Avatar';
import Username from '@/components/ui/UserName';
import {
  USER_GET_REQUEST,
  USER_REMOVE_AVATAR,
  USER_GET_LOGIN_TYPES,
  USER_CHANGE_AVATAR,
} from '@/store/actions/user';
import { LOGIN_TYPES } from '@/config';
import CancelProfileForm from '@/components/forms/CancelProfileForm.vue';

export default {
  name: 'MyProfilePage',

  components: {
    EditUserForm,
    ChangePasswordForm,
    Avatar,
    Username,
    UploadAvatar,
    CancelProfileForm,
  },

  data() {
    return {
      isAvatarEditing: false,
      isAvatarSubmiting: false,
    };
  },

  computed: {
    ...mapGetters(['userProfile', 'userLoginTypes']),

    hasUserPassword() {
      return this.userLoginTypes.includes(LOGIN_TYPES.password);
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name !== null) {
        vm.$store.dispatch(USER_GET_REQUEST);
      }
    });
  },

  created() {
    this.$store.dispatch(USER_GET_LOGIN_TYPES);
  },

  methods: {
    openAvatarEdit() {
      this.isAvatarEditing = true;
    },

    closeAvatarEdit() {
      this.isAvatarEditing = false;
    },

    async uploadAvatar(image) {
      this.isAvatarSubmiting = true;
      const response = await this.$store.dispatch(USER_CHANGE_AVATAR, { base64: image });
      this.isAvatarSubmiting = false;
      this.closeAvatarEdit();

      if (response.status !== 200) {
        this.$notify({
          type: 'negative',
          text: response.message,
        });
      }
    },

    async removeAvatar() {
      if (isEmpty(this.userProfile.avatarUrl)) return;

      const response = await this.$store.dispatch(USER_REMOVE_AVATAR);

      if (response.status !== 200) {
        this.$notify({
          type: 'negative',
          text: response.message,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.my-profile {
  .avatar {
    width: rem(100);
    height: rem(100);
  }

  &__avatar {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      margin-top: rem(24);
      word-break: break-word;
    }
  }

  &__avatar-add {
    margin-top: rem(24);
  }

  &__avatar-remove {
    color: $color-text-light-blue;
    margin-top: rem(16);
    font-weight: 600;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  /deep/ .card {
    padding: rem(36) rem(48);

    > div {
      margin-bottom: rem(12);
    }

    > div:last-child {
      margin-bottom: rem(0);
    }

    @include until($breakpoint-small) {
      padding: rem(36) rem(20);
    }
  }

  &__form {
    max-width: rem(836);
    margin: rem(48) auto 0;

    /deep/ h2 {
      font-size: rem(24);
      margin-bottom: rem(32);

      @include until($breakpoint-small) {
        text-align: center;
      }
    }
  }

  /deep/ .submit-button {
    margin-top: rem(32);

    @include from($breakpoint-small) {
      display: flex;
      justify-content: center;
      margin-top: rem(32);
    }
  }

  /deep/ {
    .formulate-input-wrapper {
      margin-bottom: 0;
    }
  }
}
</style>
