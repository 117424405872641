import DashboardLayout from '@/layouts/DashboardLayout';
import AuthLayout from '@/layouts/AuthLayout';
import DefaultLayout from '@/layouts/DefaultLayout';
import HomePage from '@/pages/dashboard/HomePage';
import LoginPage from '@/pages/LoginPage';
import RegistrationPage from '@/pages/RegistrationPage';
import ActivationPage from '@/pages/ActivationPage';
import ResetPasswordPage from '@/pages/ResetPasswordPage';
import ForgottenPasswordPage from '@/pages/ForgottenPasswordPage';
import SetCookiesPage from '@/pages/SetCookiesPage';
import CancelProfilePage from '@/pages/CancelProfilePage';
import WebsiteApplicationsPage from '@/pages/dashboard/WebsiteApplicationsPage';
import WebsiteApplicationsDetaiPage from '@/pages/dashboard/WebsiteApplicationsDetaiPage';
import AuthorizedDevicesPage from '@/pages/dashboard/AuthorizedDevicesPage';
import MyProfilePage from '@/pages/dashboard/MyProfilePage';
// import SecurityActivityPage from '@/pages/dashboard/SecurityActivityPage';
// import PrivacyProtectionPage from '@/pages/dashboard/PrivacyProtectionPage';
// import PaymentDeliveryDetailsPage from '@/pages/dashboard/PaymentDeliveryDetailsPage';
import authGuard from '@/router/authGuard';

const AUTHENTICATED_ROUTE = [
  {
    path: '/',
    component: DashboardLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: HomePage,
      },
      {
        path: '/weby-a-aplikace',
        name: 'WebsitesAndApplications',
        component: WebsiteApplicationsPage,
        children: [
          {
            path: '?page=:page',
            component: WebsiteApplicationsPage,
          },
        ],
        meta: {
          title: 'Můj účet - Weby a aplikace',
        },
      },
      {
        path:
          '/weby-a-aplikace/:id([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})',
        name: 'WebsiteApplicationsDetail',
        component: WebsiteApplicationsDetaiPage,
        meta: {
          title: 'Můj účet - Weby a aplikace',
        },
      },
      {
        path: '/autorizovana-prihlaseni',
        name: 'AuthorizedDevices',
        component: AuthorizedDevicesPage,
        meta: {
          title: 'Můj účet - Autorizovaná přihlášeni',
        },
      },
      {
        path: '/muj-profil',
        name: 'MyProfile',
        component: MyProfilePage,
        meta: {
          title: 'Můj účet - Profil',
        },
      },
      // {
      //   path: '/bezpecnost-a-aktivita',
      //   name: 'SecurityAndActivity',
      //   component: SecurityActivityPage,
      // },
      // {
      //   path: '/ochrana-soukromi-a-personalizace',
      //   name: 'PrivacyProtection',
      //   component: PrivacyProtectionPage,
      // },
      // {
      //   path: '/platebni-a-dorucovaci-udaje',
      //   name: 'PaymentAndDeliveryDetails',
      //   component: PaymentDeliveryDetailsPage,
      // },
    ],
    beforeEnter: authGuard.ifAuthenticated,
  },
  {
    path: '/prihlasovani',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'SetCookies',
        component: SetCookiesPage,
      },
    ],
    beforeEnter: authGuard.ifAuthenticated,
  },
];

const NOT_AUTHENTICATED_ROUTE = [
  {
    path: '/',
    component: AuthLayout,
    children: [
      {
        path: '/prihlaseni',
        name: 'Login',
        component: LoginPage,
        meta: {
          title: 'Můj účet - přihlášení',
        },
      },
      {
        path: '/registrace',
        name: 'Registration',
        component: RegistrationPage,
        meta: {
          title: 'Můj účet - registrace',
        },
      },
    ],
    beforeEnter: authGuard.ifNotAuthenticated,
  },
];

const AVAILABLE_ROUTE = [
  {
    path: '/',
    component: AuthLayout,
    children: [
      {
        path: '/aktivace/:token',
        name: 'Activation',
        component: ActivationPage,
        meta: {
          title: 'Můj účet - aktivace',
        },
      },
      {
        path: '/obnova-hesla/:token',
        name: 'ResetPassword',
        component: ResetPasswordPage,
        meta: {
          title: 'Můj účet - obnova hesla',
        },
      },
      {
        path: '/zapomenute-heslo',
        name: 'ForgottenPassword',
        component: ForgottenPasswordPage,
        meta: {
          title: 'Můj účet - zapomenuté heslo',
        },
      },
      {
        path: '/zruseni-uctu/:id',
        name: 'CancelProfile',
        component: CancelProfilePage,
        meta: {
          title: 'Můj účet - zrušení účtu',
        },
      },
    ],
  },
];

export default [
  ...AUTHENTICATED_ROUTE,
  ...NOT_AUTHENTICATED_ROUTE,
  ...AVAILABLE_ROUTE,
  {
    path: '*',
    redirect: { name: 'Home' },
  },
];
