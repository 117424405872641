<template>
  <FormulateInput
    label="Motto"
    type="text"
    name="motto"
    :value="value"
    :help="help"
    validation="max:255,length"
    :validation-messages="{
      max: 'Motto nesmí být delší než 255 znaků',
    }"
    error-behavior="submit"
    :disabled="disabled"
  />
</template>

<script>
export default {
  name: 'MottoField',

  props: {
    value: {
      type: String,
    },
    help: {
      type: [String, Boolean],
    },
    disabled: {
      type: Boolean,
    },
  },
};
</script>
