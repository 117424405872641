import store from '@/store';
import Cookies from 'js-cookie';
import { AUTH_TOKEN_CNAME, AUTH_TOKEN_SET } from '@/store/actions/auth';
import { USER_GET_REQUEST } from '@/store/actions/user';
import { isUrl } from '@/helpers';
import { API } from '@/api';

export default {
  ifNotAuthenticated: (to, from, next) => {
    if (!store.getters.isAuthenticated) {
      next();
      return;
    }
    if (to.name === 'Login' && isUrl(to.query.redirectUrl)) {
      next({ name: 'SetCookies', query: to.query });
      return;
    }
    next({ name: 'Home' });
  },

  ifAuthenticated: (to, from, next) => {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next({ name: 'Login', query: to.query });
  },

  checkTokenValidity: async (to, from, next) => {
    const cookieToken = Cookies.get(AUTH_TOKEN_CNAME);
    const storeToken = store.getters.authToken;

    if (storeToken) {
      if (storeToken === cookieToken) {
        // If the tokens are the same, verify that the token is valid.
        await API.auth.check({ token: storeToken });
      } else {
        // If they are not the same, reset the store token.
        store.commit(AUTH_TOKEN_SET, cookieToken);
        store.dispatch(USER_GET_REQUEST);
      }
    }

    next();
  },
};
