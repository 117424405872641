<template>
  <transition name="fade">
    <div class="backdrop" v-if="visibility" @click="onClick"></div>
  </transition>
</template>

<script>
export default {
  name: 'Backdrop',

  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    visibility() {
      const doc = document.querySelector('html');
      this.visibility ? (doc.style.overflow = 'hidden') : (doc.style.overflow = '');
    },
  },

  methods: {
    onClick() {
      this.$emit('onBackdropClick');
    },
  },
};
</script>

<style lang="scss" scoped>
.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}
.fade-enter,
.fade-leave-to {
  transition: opacity 0s;
  opacity: 0;
}
</style>
