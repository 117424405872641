<template>
  <div class="progress-circle">
    <svg :width="size" :height="size" viewPort="0 0 100 100" v-if="max">
      <circle :cx="center" :cy="center" :r="radius" :stroke-width="strokeWidth"></circle>
      <text :x="center" :y="-center" :style="{ fontSize: textSize + 'px' }">{{ percentage }}%</text>
      <circle
        class="progress"
        :cx="center"
        :cy="center"
        :r="radius"
        :stroke-width="strokeWidth"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="circumference - (circumference / max) * process"
      ></circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'CircleProgressBar',

  props: {
    size: {
      type: Number,
      required: true,
    },
    process: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    strokeWidth: {
      type: Number,
      default: 8,
    },
    textSize: {
      type: Number,
      default: 30,
    },
  },

  computed: {
    center() {
      return this.size / 2;
    },

    radius() {
      return this.size / 2 - this.strokeWidth / 2;
    },

    circumference() {
      return Math.PI * (this.radius * 2);
    },

    percentage() {
      return Math.round(
        (((this.circumference / this.max) * this.process) / this.circumference) * 100
      );
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/abstracts/abstracts';

.progress-circle {
  svg {
    fill: transparent;
    transform: rotate(-90deg);
  }

  svg circle {
    stroke: $background-color-light-blue;
  }

  svg .progress {
    stroke: $background-color-dark-blue;
    stroke-linecap: round;
  }

  svg text {
    font-weight: 600;
    fill: $background-color-dark-blue;
    dominant-baseline: middle;
    text-anchor: middle;
    transform: rotate(90deg);
  }
}
</style>
