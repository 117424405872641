<template>
  <croppa
    v-model="image"
    :placeholder="placeholder"
    :placeholder-font-size="14"
    :placeholder-color="color"
    :prevent-white-space="true"
    :width="width"
    :height="height"
    :accept="'.jpg, .jpeg, .png, .svg'"
    :file-size-limit="size"
    :show-remove-button="false"
    @file-type-mismatch="onFileTypeMismatch"
    @file-size-exceed="onFileSizeExceed"
    :canvas-color="backgroundColor"
  ></croppa>
</template>

<script>
const DEFAULT_PLACEHOLDER = 'Kliknutím vyberte obrázek';
const FILETYPE_MISMATCH = 'Formát není podporován';
const FILESIZE_EXCEED = 'Soubor je příliš velký';

export default {
  name: 'ImageCropper',

  data() {
    return {
      image: null,
      placeholder: DEFAULT_PLACEHOLDER,
    };
  },

  props: {
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    size: {
      type: Number,
      default: 1048576,
    },
    color: {
      type: String,
      default: '#000000',
    },
    backgroundColor: {
      type: String,
      default: 'transparent',
    },
  },

  methods: {
    getImageData() {
      return this.image.generateDataUrl();
    },

    onFileTypeMismatch() {
      this.placeholder = FILETYPE_MISMATCH;
    },

    onFileSizeExceed() {
      this.placeholder = FILESIZE_EXCEED;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

canvas {
  user-select: none;
}
</style>
