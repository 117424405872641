import { makeRequest } from '@/utils';
import {
  USER_GET,
  USER_UPDATE,
  USER_CHANGE_PASSWORD,
  USER_CHANGE_AVATAR,
  USER_REMOVE_AVATAR,
  USER_LOGIN_TYPES,
  USER_SERVICE_PARAM_GET_ALL,
  USER_SERVICE_PARAM_CHANGE_AVATAR,
  USER_SERVICE_PARAM_REMOVE_AVATAR,
  USER_SERVICE_PARAM_GET_REQUIRED_PARAMS,
  API_UNI_ERROR,
  USER_SERVICE_PARAM_SET_PARAMS,
  USER_CANCEL_PROFILE,
} from './constants';

/**
 * @param {Object} payload
 * @param {String} payload.token
 */
export default {
  get: async ({ token }) => {
    try {
      const response = await makeRequest.get(USER_GET, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });

      return {
        status: response.status,
        data: response.data,
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: API_UNI_ERROR,
      };
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.token
   * @param {Object} payload.payload
   * @param {String} [payload.payload.firstName]
   * @param {String} [payload.payload.lastName]
   * @param {String} [payload.payload.nickname]
   */
  update: async ({ token, payload }) => {
    try {
      const response = await makeRequest.post(USER_UPDATE, payload, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      return {
        status: response.status,
        data: response.data,
        message: 'Změny byly uloženy.',
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: API_UNI_ERROR,
      };
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.token
   * @param {Object} payload.payload
   * @param {String} payload.payload.currentPassword
   * @param {String} payload.payload.newPassword
   */
  changePassword: async ({ token, payload }) => {
    try {
      const response = await makeRequest.post(USER_CHANGE_PASSWORD, payload, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      return {
        status: response.status,
        message: 'Heslo bylo změněno.',
      };
    } catch (err) {
      const status = err.response.status;

      const response = {
        status,
      };

      switch (status) {
        case 400:
          if (err?.response?.data?.errors[0]?.code === '-102') {
            response.message = 'Zadali jste nesprávné heslo.';
          } else {
            response.message = API_UNI_ERROR;
          }
          break;
        default:
          response.message = API_UNI_ERROR;
      }

      return response;
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.token
   * @param {String} payload.payload
   */
  changeAvatar: async ({ token, payload }) => {
    try {
      const response = await makeRequest.post(USER_CHANGE_AVATAR, payload, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      return {
        status: response.status,
        data: response.data,
        message: 'Avatar byl upraven.',
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: API_UNI_ERROR,
      };
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.token
   */
  removeAvatar: async ({ token }) => {
    try {
      const response = await makeRequest.delete(USER_REMOVE_AVATAR, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      return {
        status: response.status,
        message: 'Avatar byl odstraněn.',
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: API_UNI_ERROR,
      };
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.token
   */
  loginTypes: async ({ token }) => {
    try {
      const response = await makeRequest.get(USER_LOGIN_TYPES, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      return {
        status: response.status,
        data: response.data.meta,
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: API_UNI_ERROR,
      };
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.token
   */
  getAllServiceParams: async ({ token }) => {
    try {
      const response = await makeRequest.get(USER_SERVICE_PARAM_GET_ALL, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      return {
        status: response.status,
        data: response.data.data,
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: API_UNI_ERROR,
      };
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.token
   * @param {Object} payload.payload
   * @param {String} payload.payload.serviceId
   * @param {String} payload.payload.base64
   */
  changeServiceAvatar: async ({ token, payload }) => {
    try {
      const response = await makeRequest.post(USER_SERVICE_PARAM_CHANGE_AVATAR, payload, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      return {
        status: response.status,
        data: response.data,
        message: 'Avatar byl upraven.',
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: API_UNI_ERROR,
      };
    }
  },

  removeServiceAvatar: async ({ token, serviceId }) => {
    try {
      const response = await makeRequest.delete(
        `${USER_SERVICE_PARAM_REMOVE_AVATAR}/${serviceId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return {
        status: response.status,
        message: 'Avatar byl odstraněn.',
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: API_UNI_ERROR,
      };
    }
  },

  getRequiredServiceParams: async () => {
    try {
      const response = await makeRequest.get(USER_SERVICE_PARAM_GET_REQUIRED_PARAMS);
      return {
        status: response.status,
        data: response.data.data,
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: API_UNI_ERROR,
      };
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.token
   * @param {Object} payload.payload
   * @param {String} payload.payload.serviceId
   * @param {Object} payload.payload.values
   */
  setServiceParams: async ({ token, payload }) => {
    try {
      const response = await makeRequest.post(USER_SERVICE_PARAM_SET_PARAMS, payload, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      return {
        status: response.status,
        message: 'Změny byly uloženy.',
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: API_UNI_ERROR,
      };
    }
  },

  /**
   * @param {Object} payload
   * @param {String} payload.token
   */
  cancelProfile: async ({ token }) => {
    try {
      const response = await makeRequest.post(USER_CANCEL_PROFILE, { token });
      return {
        status: response.status,
        message: 'Účet byl zrušen.',
      };
    } catch (err) {
      return {
        status: err.response.status,
        message: API_UNI_ERROR,
      };
    }
  },
};
