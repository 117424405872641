import Vue from 'vue';
import App from '@/App';
import router from '@/router';
import store from '@/store';
import VueFormulate from '@braid/vue-formulate';
import Croppa from 'vue-croppa';
import Notifications from '@/plugins/Notifications';
import { Axios, validationRules } from '@/utils';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import Tooltip from '@/components/ui/Tooltip';

import '@/styles/main.scss';

Vue.config.productionTip = false;

Vue.component('Tooltip', Tooltip)

Vue.use(VueFormulate, {
  ...validationRules,
  slotComponents: {
    help: 'Tooltip'
  }, slotProps: {
    help: ['tooltip']
  }
});
Vue.use(Croppa);
Vue.use(Notifications);

Axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 403) {
      store.dispatch(AUTH_LOGOUT);
    }
    throw err;
  }
);

// Application initialization
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
