<template>
  <div class="upload-avatar-form">
    <Popup :visibility="editing" @popupClose="closeAvatarEdit">
      <ImageCropper
        v-if="editing"
        class="upload-avatar-form__cropper"
        ref="imageCropper"
        color="#718ab8"
        backgroundColor="#eaeff8"
      />

      <FormulateInput type="submit" label="Uložit" :disabled="submiting" @click="uploadAvatar"
        ><Spinner v-if="submiting" :size="24" color="#ffffff"
      /></FormulateInput>
    </Popup>
  </div>
</template>

<script>
import Popup from '@/components/ui/Popup';
import ImageCropper from '@/components/ui/ImageCropper';
import Spinner from '@/components/ui/Spinner';

export default {
  name: 'UploadAvatar',

  components: {
    Popup,
    ImageCropper,
    Spinner,
  },

  props: {
    editing: {
      type: Boolean,
      default: false,
    },

    submiting: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    closeAvatarEdit() {
      if (!this.submiting) {
        this.$emit('upload-avatar-close');
      }
    },

    uploadAvatar() {
      const image = this.$refs.imageCropper.getImageData();
      if (image) {
        this.$emit('upload-avatar-success', image);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.upload-avatar-form {
  &__cropper {
    margin-bottom: rem(28);
  }

  /deep/ canvas {
    border-radius: 90%;
  }

  /deep/ .formulate-input-wrapper {
    margin-bottom: 0;
  }
}
</style>
