<template>
  <div class="edit-user-service">
    <div v-if="avatar" class="edit-user-service__avatar avatar">
      <Avatar :url="avatar.value" />
      <div class="avatar__options">
        <label>Avatar <Tooltip v-if="!avatar.editable" :context="{ help: helpMessage }"/></label>
        <div class="avatar__actions">
          <span :class="{ 'button--disabled': !avatar.editable }">
            <a class="avatar__open button button--inline button--small" @click="openAvatarEdit"
              >Změnit fotku</a
            >
          </span>
          <a
            v-if="avatar.editable && !avatar.isInherited"
            class="avatar__remove"
            @click="removeAvatar"
            >Smazat fotku</a
          >
        </div>
      </div>

      <UploadAvatar
        :editing="isAvatarEditing"
        :submiting="isAvatarSubmiting"
        @upload-avatar-success="uploadAvatar"
        @upload-avatar-close="closeAvatarEdit"
      />
    </div>

    <FormulateForm v-model="formValues" :form-errors="formErrors" @submit="onSubmit" novalidate>
      <div class="edit-user-service__form-fields" :key="componentKey">
        <div v-if="nickname">
          <NicknameField
            :value="nickname.value"
            :disabled="!nickname.editable"
            :help="!nickname.editable && helpMessage"
          />
        </div>

        <div v-if="phone">
          <PhoneField
            :value="phone.value"
            :disabled="!phone.editable"
            :help="!phone.editable && helpMessage"
          ></PhoneField>
        </div>

        <div v-if="motto">
          <MottoField
            :value="motto.value"
            :disabled="!motto.editable"
            :help="!motto.editable && helpMessage"
          />
        </div>
      </div>

      <FormulateInput
        class="submit-button"
        :class="{ 'button--disabled': unchanged }"
        type="submit"
        label="Uložit změny"
        :disabled="isSubmiting"
        ><Spinner v-if="isSubmiting" :size="24" color="#ffffff"
      /></FormulateInput>
    </FormulateForm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DashboardFormMixin from '@/mixins/DashboardFormMixin';
import { USER_PARAMS } from '@/config';
import {
  USER_SET_SERVICE_PARAMS,
  USER_CHANGE_SERVICE_AVATAR,
  USER_REMOVE_SERVICE_AVATAR,
} from '@/store/actions/user';
import Avatar from '@/components/ui/Avatar';
import UploadAvatar from '@/components/ui/UploadAvatar';
import NicknameField from '@/components/forms/fields/NicknameField';
import PhoneField from '@/components/forms/fields/PhoneField';
import MottoField from '@/components/forms/fields/MottoField';
import Tooltip from '@/components/ui/Tooltip';
import { isEmpty, serializeFormValues } from '@/helpers';

export default {
  name: 'EditUserServiceForm',

  mixins: [DashboardFormMixin],

  components: {
    Avatar,
    UploadAvatar,
    NicknameField,
    PhoneField,
    MottoField,
    Tooltip,
  },

  props: {
    service: {
      type: Object,
    },
  },

  data() {
    return {
      componentKey: 0,
      isAvatarEditing: false,
      isAvatarSubmiting: false,
    };
  },

  computed: {
    ...mapGetters(['userProfile']),

    userParams() {
      return this.userProfile.params.reduce((params, param) => {
        params[param.name] = param.value;
        return params;
      }, {});
    },

    avatar() {
      const avatarParam = this.getParam(USER_PARAMS.avatar);
      avatarParam.isInherited = false;

      if (avatarParam && avatarParam.value === '') {
        avatarParam.value = this.userProfile.avatarUrl;
        avatarParam.isInherited = true;
      }

      return avatarParam;
    },

    nickname() {
      return this.getParam(USER_PARAMS.nickname);
    },

    phone() {
      return this.getParam(USER_PARAMS.phone);
    },

    motto() {
      return this.getParam(USER_PARAMS.motto);
    },

    parentServiceUrl() {
      const { href } = this.$router.resolve({
        path: this.service.parentServiceId,
      });

      return href;
    },

    helpMessage() {
      return `Nelze upravit, tato informace se dědí. <a href=${this.parentServiceUrl}>Změnit</a>`;
    },
  },

  methods: {
    getParam(slug) {
      const param = this.service.params.find((param) => param.name === slug);
      if (param && param.value === null) {
        param.value = this.userParams[slug] || '';
      }
      return param;
    },

    forceRerender() {
      this.componentKey += 1;
    },

    async onSubmit() {
      const formValues = serializeFormValues(this.formValues);
      const { nickname, phone, motto } = formValues;

      let values = {};

      if (this.nickname?.editable) {
        values[USER_PARAMS.nickname] = isEmpty(nickname) ? null : nickname;
      }

      if (this.phone?.editable) {
        values[USER_PARAMS.phone] = isEmpty(phone) ? null : phone;
      }

      if (this.motto?.editable) {
        values[USER_PARAMS.motto] = isEmpty(motto) ? null : motto;
      }

      await this.handleSubmit(USER_SET_SERVICE_PARAMS, {
        serviceId: this.service.id,
        values,
      });

      this.forceRerender();
    },

    openAvatarEdit() {
      this.isAvatarEditing = true;
    },

    closeAvatarEdit() {
      this.isAvatarEditing = false;
    },

    async uploadAvatar(image) {
      this.isAvatarSubmiting = true;
      const response = await this.$store.dispatch(USER_CHANGE_SERVICE_AVATAR, {
        serviceId: this.service.id,
        base64: image,
      });
      this.isAvatarSubmiting = false;
      this.closeAvatarEdit();

      if (response.status !== 200) {
        this.$notify({
          type: 'negative',
          text: response.message,
        });
      }
    },

    async removeAvatar() {
      const response = await this.$store.dispatch(USER_REMOVE_SERVICE_AVATAR, {
        serviceId: this.service.id,
      });

      if (response.status !== 200) {
        this.$notify({
          type: 'negative',
          text: response.message,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.edit-user-service {
  &__avatar {
    margin-bottom: rem(28);
  }

  /deep/ &__form-fields {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    margin-bottom: rem(21);

    > div {
      width: 100%;
      margin-bottom: rem(15);
    }
  }

  form .submit-button {
    display: flex;
    justify-content: center;
  }
}

.avatar {
  display: flex;
  align-items: flex-start;

  /deep/ .avatar {
    width: rem(100);
    height: rem(100);
  }

  &__options {
    margin: rem(12) 0 0 rem(20);

    > label {
      display: flex;
      font-size: rem(12);
      font-weight: bold;
      margin-bottom: rem(8);
      text-transform: uppercase;
      color: $color-text-gray;
      letter-spacing: rem(2.4);

      /deep/ {
        .tooltip {
          margin-left: rem(7);

          svg {
            fill: $color-text-light-blue;
          }
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__remove {
    margin-left: rem(20);
    color: $color-text-light-blue;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

/deep/ [type='submit'] {
  min-width: rem(186);
}
</style>
