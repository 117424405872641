<template>
  <div @click="onLogin">
    <svg
      v-if="!isSubmiting"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 23.165"
    >
      <path
        d="M294.486 56.054a.514.514 0 00.681-.058 7.185 7.185 0 0110.324-.125.513.513 0 00.71 0l2.921-2.64a.469.469 0 000-.684 12.314 12.314 0 00-17.837.386.471.471 0 00-.116.378.48.48 0 00.216.335z"
        transform="translate(-288.392 -48.912)"
      />
      <path
        d="M293.742 62.91a.472.472 0 00.14-.511 6.407 6.407 0 010-4.3.473.473 0 00-.16-.53l-3.272-2.515a.515.515 0 00-.421-.093.5.5 0 00-.34.257 11.121 11.121 0 00.15 10.262.5.5 0 00.356.252.514.514 0 00.425-.118z"
        transform="translate(-288.509 -48.646)"
      />
      <path
        d="M311.963 58.636a.492.492 0 00-.5-.482h-10a.492.492 0 00-.5.482v3.854a.492.492 0 00.5.482h4.837a6.536 6.536 0 01-1.141 1.609.47.47 0 000 .646l2.671 2.891a.514.514 0 00.731 0 11.364 11.364 0 003.4-7.458z"
        transform="translate(-287.962 -48.506)"
      />
      <path
        d="M303.9 66.04a.512.512 0 00-.579-.116 7.12 7.12 0 01-8.1-1.571.514.514 0 00-.7 0l-3 2.582a.469.469 0 000 .684 12.337 12.337 0 0015.006 2.168.483.483 0 00.239-.339.471.471 0 00-.119-.393z"
        transform="translate(-288.382 -48.24)"
      />
    </svg>
    <Spinner v-if="isSubmiting" :size="24" />
  </div>
</template>

<script>
import BaseFormMixin from '@/mixins/BaseFormMixin';
import { AUTH_LOGIN_TOKEN_REQUEST } from '@/store/actions/auth';
import { GOOGLE_CLIENT_ID, LOGIN_TYPES } from '@/config';

const SCRIPT_URL = 'https://apis.google.com/js/platform.js';
const ERROR_MSG = 'Propojení se nezdařilo. Zkuste to prosím znovu';

export default {
  name: 'SocialConnectGoole',

  mixins: [BaseFormMixin],

  data() {
    return {
      auth2: null,
    };
  },

  async mounted() {
    if (!window.gapi) {
      await this.initScript();
    }

    window.gapi.load('auth2', () => {
      this.auth2 = window.gapi.auth2.init({
        ux_mode: 'popup',
      });
    });
  },

  methods: {
    initScript() {
      return new Promise((resolve) => {
        const meta = document.createElement('meta');
        meta.name = 'google-signin-client_id';
        meta.content = `${GOOGLE_CLIENT_ID}.apps.googleusercontent.com`;
        document.head.appendChild(meta);

        const script = document.createElement('script');
        script.src = `${SCRIPT_URL}`;
        document.head.appendChild(script);
        script.onload = resolve;
      });
    },

    onLogin() {
      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push(function () {
        window.gtmService.push({
          event: 'user_login_attempt',
        });
      });

      this.isSubmiting = true;
      this.loginHandler();
    },

    async loginHandler() {
      try {
        const signIn = await this.auth2.signIn();
        const token = signIn.getAuthResponse(true).access_token;

        this.onSubmit(token);
      } catch (error) {
        this.$emit('connectError', ERROR_MSG);
      } finally {
        this.isSubmiting = false;
      }
    },

    onSubmit(token) {
      this.handleSubmit(AUTH_LOGIN_TOKEN_REQUEST, {
        type: LOGIN_TYPES.google,
        token,
        serviceId: this.serviceId,
      });
    },
  },
};
</script>
