<template>
  <header>
    <div class="navbar" :class="{ top: openMenuHandler }">
      <div class="navbar__item">
        <MainMenu @openMenu="openMenuHandler" />
      </div>

      <div class="navbar__item">
        <router-link
          :to="{ name: 'Home' }"
          class="navbar__link navbar__link--brand"
          data-track-click='{"event": "clickTo_navigation", "link": { "type": "logo" }}'
        >
          <img :src="require('@/assets/svg/brand-logo.svg')" alt="Brand logo" />
        </router-link>
      </div>

      <div class="navbar__item">
        <UserMenu />
      </div>
    </div>

    <Backdrop :visibility="isMenuOpen" />
  </header>
</template>

<script>
import MainMenu from '@/components/layouts/header/MainMenu';
import UserMenu from '@/components/layouts/header/UserMenu';
import Backdrop from '@/components/ui/Backdrop';

export default {
  name: 'Header',

  components: {
    MainMenu,
    UserMenu,
    Backdrop,
  },

  data() {
    return {
      isMenuOpen: false,
    };
  },

  methods: {
    openMenuHandler(isOpen) {
      this.isMenuOpen = isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/abstracts/abstracts';

.navbar {
  display: flex;
  justify-content: space-between;
  height: rem(70);
  background-color: $background-color-light;
  box-shadow: $box-shadow;

  &.top {
    position: relative;
    z-index: 101;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 100%;

    &:first-child,
    &:last-child {
      flex: 1;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }

  /deep/ &__link {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    user-select: none;

    &--brand {
      img {
        width: rem(130);
      }
    }

    @include from($breakpoint-small) {
      padding: 0 rem(24);
    }

    @include until($breakpoint-small) {
      min-width: 70px;
      justify-content: center;
    }
  }
}
</style>
